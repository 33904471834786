import { GTData } from "@lu/muscat-analytics-library";
import { ContentsState, ProgressState } from "@lu/request";

import { AggregateJob } from "../../../../../server/repositories/aggregate-job/model";
import { Job } from "../../../../../server/repositories/job/model";

export type State = {
	aggregateJob: ContentsState<AggregateJob>;
	gtDatas: ContentsState<GTData[]>;
	job: ContentsState<Job>;
	progressState: ProgressState;
};

export const initState: State = {
	aggregateJob: { state: "loading" },
	gtDatas: { state: "loading" },
	job: { state: "loading" },
	progressState: "none",
};
