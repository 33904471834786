import { EnqueteStatus } from "@bleu/core";
import { ButtonsBlock, ContentsStateViewer } from "@bleu/utility-components";
import { Quota } from "@lu/muscat-library/dist/models/quota";
import { ContentsState } from "@lu/request";
import React, { FunctionComponent, useMemo } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { JobWithEnquete } from "../../../../../../server/repositories/job/model";
import { webEndpoint } from "../../../../../../server/router/web/endpoint";
import { endpoint, jobsEndpoint } from "../../../../routes/endpoints";
import { EditButton } from "../../../parts/buttons";
import Page from "../../../parts/Page";

import { JobDetailsBody } from "./Body";

type Props = {
	job: ContentsState<JobWithEnquete>;
	jobId?: string;
	quotas: ContentsState<Quota[]>;
	onUpdateStatus: (status: EnqueteStatus) => void;
};

export const JobDetailsPage: FunctionComponent<Props> = React.memo(({ job, jobId, quotas, onUpdateStatus }) => {
	const [t] = useTranslation();

	const pageTitle = useMemo(
		() => (job.state === "loaded" ? job.value.title : undefined) ?? t("pageTitle.jobDetails"),
		[job]
	);

	const paths = useMemo(
		() => [
			{ label: t("pageTitle.jobs"), to: endpoint.jobs },
			{
				label: pageTitle,
				to: `${endpoint.jobs}/${jobId}`,
			},
		],
		[jobId, pageTitle]
	);

	return (
		<Page className="job-details-page">
			<Page.Header paths={paths} title={pageTitle}>
				<ButtonsBlock align="right" wide>
					<Dropdown>
						<Dropdown.Toggle disabled={job.state !== "loaded"}>
							<i className="bi-eye-fill" />
							<span>{t("jobDetailsPage.preview")}</span>
						</Dropdown.Toggle>
						<Dropdown.Menu>
							{job.state === "loaded" && (
								<>
									<Dropdown.Item as={Link} target="_blank" to={`${webEndpoint.preview}/${job.value.enqueteId}`}>
										<i className="bi-box-arrow-up-right" />
										<span>{t("jobDetailsPage.preview")}</span>
									</Dropdown.Item>
									<Dropdown.Item as={Link} target="_blank" to={`${webEndpoint.debug}/${job.value.enqueteId}`}>
										<i className="bi-box-arrow-up-right" />
										<span>{t("jobDetailsPage.debug")}</span>
									</Dropdown.Item>
								</>
							)}
						</Dropdown.Menu>
					</Dropdown>
					<EditButton as={Link} to={`${endpoint.jobs}/${jobId}/${jobsEndpoint.edit}`}>
						{t("jobDetailsPage.edit")}
					</EditButton>
				</ButtonsBlock>
			</Page.Header>
			<Page.Body>
				{job.state === "loaded" && quotas.state === "loaded" && (
					<JobDetailsBody job={job.value} quotas={quotas.value} onUpdateStatus={onUpdateStatus} />
				)}
				<ContentsStateViewer values={{ job }} />
			</Page.Body>
		</Page>
	);
});
