import { makeError } from "@bleu/core";
import { ContentsState } from "@lu/request";
import React, { FunctionComponent, useCallback, useEffect, useMemo, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";

import { AccountWithoutPassword } from "../../../../server/repositories/account/model";
import { apiEndpoint } from "../../../../server/router/api/endpoint";
import { AuthResponse, LoginResponse } from "../../../../server/types/auth";
import { LoginPage, LoginParams } from "../../components/pages/login";
import { post } from "../../lib/request";
import { endpoint } from "../../routes/endpoints";

import { reducer } from "./reducer";
import { initState } from "./state";

type Props = {
	onLogin: (account: ContentsState<AccountWithoutPassword>) => void;
};

export const LoginContainer: FunctionComponent<Props> = React.memo(({ onLogin }) => {
	const [t] = useTranslation();

	const location = useLocation();

	const navigate = useNavigate();

	const [state, dispatch] = useReducer(reducer, initState);

	useEffect(() => {
		document.title = `${t("pageTitle.login")} - ${t("pageTitle.siteName")}`;
	}, []);

	const redirect = useMemo(
		() =>
			location.state != null && typeof location.state === "object"
				? (location.state as { [key in string]: string }).redirect
				: undefined,
		[location.state]
	);

	useEffect(() => {
		post<AuthResponse>(apiEndpoint.authentication)
			.then((res) => {
				if (res.data.account != null) {
					onLogin({ state: "loaded", value: res.data.account });
					navigate(redirect ?? endpoint.top);
				} else {
					onLogin({ state: "failed", error: { message: t("erroeMessage.alreadyLogout") } });
				}
			})
			.catch((error) => {
				console.error(error);
				onLogin({ state: "failed", error: makeError(error) });
			});
	}, [redirect, onLogin]);

	const onChange = useCallback(
		(name: keyof LoginParams, value: string) => dispatch({ type: "update", payload: { name, value } }),
		[]
	);
	const onSubmit = React.useCallback(() => {
		dispatch({ type: "changeLoading", payload: true });
		post<LoginResponse>(apiEndpoint.login, { email: state.email, password: state.password })
			.then((res) => {
				onLogin({ state: "loaded", value: res.data.account });
				navigate(res.data.redirectUrl ?? redirect ?? endpoint.top);
			})
			.catch((error) => {
				onLogin({ state: "failed", error: makeError(error) });
				dispatch({ type: "error", payload: makeError(error) });
			});
	}, [redirect, state.email, state.password]);

	return (
		<LoginPage
			email={state.email}
			password={state.password}
			error={state.error}
			onChange={onChange}
			onSubmit={onSubmit}
		/>
	);
});
