import { ContentsState, Error, ProgressState } from "@lu/request";

import { AccountWithoutPassword } from "../../../../../server/repositories/account/model";
import { GetJobListResponse, SearchJobRequest } from "../../../../../server/types/request/job";
import { SearchParams } from "../../../components/parts/SearchField";

export type State = {
	accounts: ContentsState<AccountWithoutPassword[]>;
	error?: Error;
	jobs: ContentsState<GetJobListResponse>;
	progressState: ProgressState;
	searchParams: SearchJobRequest;
	targetIndex?: number;
};

export const initState = (searchParams: SearchParams): State => ({
	accounts: { state: "loading" },
	jobs: { state: "loading" },
	progressState: "none",
	searchParams,
});
