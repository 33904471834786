import { AccountWithoutPassword } from "../../repositories/account/model";

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class Role {
	/**
	 * 管理者権限かどうか
	 * @param {AccountWithoutPassword} account
	 * @returns {boolean}
	 */
	public static isAdmin(account: AccountWithoutPassword): boolean {
		return account.role === "admin";
	}

	/**
	 * 管理権限を持っているか
	 * @param {AccountWithoutPassword} account
	 * @returns {boolean}
	 */
	public static hasManagementRole(account: AccountWithoutPassword): boolean {
		return this.isAdmin(account) || account.role === "organizationAdmin";
	}

	/**
	 * 案件の編集権限を持っているか。
	 * @param {AccountWithoutPassword} account
	 * @returns {boolean}
	 */
	public static hasJobEditRole(account: AccountWithoutPassword): boolean {
		if (this.hasManagementRole(account)) return true;
		if (account.role === "general") return true;
		return false;
	}
}
