import { ContentsStateViewer, ErrorViewer } from "@bleu/utility-components";
import { ContentsState, Error, ProgressState } from "@lu/request";
import React, { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Organization } from "../../../../../../server/repositories/organization/model";
import { endpoint, organizationsEndpoint } from "../../../../routes/endpoints";
import { EditorType } from "../../../../types/editorTypes";
import Page from "../../../parts/Page";

import { OrganizationEditorBody } from "./Body";

type Props = {
	error?: Error;
	organization: ContentsState<Partial<Organization>>;
	organizationId?: string;
	progressState: ProgressState;
	type: EditorType;
	onChangeOrganization: (value: Partial<Organization>) => void;
	onSubmit: (value: Partial<Organization>) => void;
};

export const OrganizationEditorPage: FunctionComponent<Props> = React.memo(
	({ error, organization, organizationId, progressState, type, onChangeOrganization, onSubmit }) => {
		const [t] = useTranslation();

		const pageTitle = useMemo(() => {
			if (type === "create") return t("pageTitle.createOrganization");

			return `${t("pageTitle.editOrganization")}${
				organization.state === "loaded" ? ` - ${organization.value.name}` : ""
			}`;
		}, [organization.state, type]);

		const paths = useMemo(
			() =>
				[
					[{ label: t("pageTitle.organizations"), to: endpoint.organizations }],
					type === "create"
						? [
								{
									label: t(`pageTitle.${type}Organization`),
									to: `${endpoint.organizations}/${organizationsEndpoint.create}`,
								},
						  ]
						: [
								{
									label:
										organization.state === "loaded"
											? organization.value.name ?? ""
											: t("pageTitle.organizationDetails"),
									to: `${endpoint.organizations}/${organizationId}`,
								},
								{
									label: t(`pageTitle.${type}Organization`),
									to: `${endpoint.organizations}/${organizationId}/${organizationsEndpoint.edit}`,
								},
						  ],
				].flatMap((item) => item),
			[organization.state, organizationId, type]
		);

		return (
			<Page className="organization-editor-page">
				<Page.Header paths={paths} title={pageTitle}></Page.Header>
				<Page.Body>
					<ErrorViewer error={error} />
					{organization.state === "loaded" && (
						<>
							<OrganizationEditorBody
								organization={organization.value}
								progressState={progressState}
								type={type}
								onChange={onChangeOrganization}
								onSubmit={onSubmit}
							/>
						</>
					)}
					<ContentsStateViewer values={{ organization }} />
				</Page.Body>
			</Page>
		);
	}
);
