import { ContentsStateViewer, ErrorViewer } from "@bleu/utility-components";
import { ContentsState, Error, ProgressState } from "@lu/request";
import React, { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Account } from "../../../../../../server/repositories/account/model";
import { accountsEndpoint, endpoint } from "../../../../routes/endpoints";
import Page from "../../../parts/Page";

import { PasswordEditorBody } from "./Body";

type Props = {
	account: ContentsState<Partial<Account>>;
	accountId?: string;
	error?: Error;
	progressState: ProgressState;
	onChangeAccount: (value: Partial<Account>) => void;
	onSubmit: (value: Partial<Account>) => void;
};

export const PasswordEditorPage: FunctionComponent<Props> = React.memo(
	({ account, accountId = "", error, progressState, onChangeAccount, onSubmit }) => {
		const [t] = useTranslation();

		const pageTitle = useMemo(() => {
			return `${t("pageTitle.editPassword")}${account.state === "loaded" ? ` - ${account.value.name ?? ""}` : ""}`;
		}, [account.state]);

		const paths = useMemo(
			() => [
				{ label: t("pageTitle.accounts"), to: endpoint.accounts },
				{
					label: account.state === "loaded" ? account.value.name ?? "" : t("pageTitle.accountDetails"),
					to: `${endpoint.accounts}/${accountId}`,
				},
				{
					label: t("pageTitle.editPassword"),
					to: `${endpoint.accounts}/${accountId}/${accountsEndpoint.password}`,
				},
			],

			[accountId, account.state]
		);

		return (
			<Page className="password-editor-page">
				<Page.Header paths={paths} title={pageTitle}></Page.Header>
				<Page.Body>
					<ErrorViewer error={error} />
					{account.state === "loaded" && (
						<PasswordEditorBody
							account={account.value}
							progressState={progressState}
							onChange={onChangeAccount}
							onSubmit={onSubmit}
						/>
					)}
					<ContentsStateViewer values={{ account }} />
				</Page.Body>
			</Page>
		);
	}
);
