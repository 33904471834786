import { ButtonsBlock, ContentsStateViewer, ErrorViewer } from "@bleu/utility-components";
import { ContentsState, Error, ProgressState } from "@lu/request";
import React, { FunctionComponent, useMemo } from "react";
import Button from "react-bootstrap/esm/Button";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Account } from "../../../../../../server/repositories/account/model";
import { Organization } from "../../../../../../server/repositories/organization/model";
import { accountsEndpoint, endpoint } from "../../../../routes/endpoints";
import { EditorType } from "../../../../types/editorTypes";
import Page from "../../../parts/Page";

import { AccountEditorBody } from "./Body";

type Props = {
	account: ContentsState<Partial<Account>>;
	accountId?: string;
	error?: Error;
	organizations: ContentsState<Organization[]>;
	progressState: ProgressState;
	type: EditorType;
	onChangeAccount: (value: Partial<Account>) => void;
	onSubmit: (value: Partial<Account>) => void;
};

export const AccountEditorPage: FunctionComponent<Props> = React.memo(
	({ account, accountId = "", error, organizations, progressState, type, onChangeAccount, onSubmit }) => {
		const [t] = useTranslation();

		const pageTitle = useMemo(() => {
			if (type === "create") return t("pageTitle.createAccount");

			return `${t("pageTitle.editAccount")}${account.state === "loaded" ? ` - ${account.value.name ?? ""}` : ""}`;
		}, [type, account.state]);

		const paths = useMemo(
			() =>
				[
					[{ label: t("pageTitle.accounts"), to: endpoint.accounts }],
					type === "create"
						? [
								{
									label: t(`pageTitle.${type}Account`),
									to: `${endpoint.accounts}/${accountsEndpoint.create}`,
								},
						  ]
						: [
								{
									label: account.state === "loaded" ? account.value.name ?? "" : t("pageTitle.accountDetails"),
									to: `${endpoint.accounts}/${accountId}`,
								},
								{
									label: t(`pageTitle.${type}Account`),
									to: `${endpoint.accounts}/${accountId}/${accountsEndpoint.edit}`,
								},
						  ],
				].flatMap((item) => item),
			[accountId, type, account.state]
		);

		return (
			<Page className="account-editor-page">
				<Page.Header paths={paths} title={pageTitle}>
					{type === "edit" && (
						<ButtonsBlock align="right" wide>
							<Button as={Link as any} to={`${endpoint.accounts}/${accountId}/${accountsEndpoint.password}`}>
								<i className="bi-lock-fill" />
								<span>{t("accountEditorPage.changePassword")}</span>
							</Button>
						</ButtonsBlock>
					)}
				</Page.Header>
				<Page.Body>
					<ErrorViewer error={error} />
					{account.state === "loaded" && organizations.state === "loaded" && (
						<AccountEditorBody
							account={account.value}
							organizations={organizations.value}
							progressState={progressState}
							type={type}
							onChange={onChangeAccount}
							onSubmit={onSubmit}
						/>
					)}
					<ContentsStateViewer values={{ account, organizations }} />
				</Page.Body>
			</Page>
		);
	}
);
