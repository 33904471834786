import { makeError } from "@bleu/core";
import React, { FunctionComponent, useCallback, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";

import { Organization } from "../../../../../server/repositories/organization/model";
import { apiEndpoint } from "../../../../../server/router/api/endpoint";
import {
	GetOneOrganizationResponse,
	UpsertOrganizationResponse,
} from "../../../../../server/types/request/organization";
import { OrganizationEditorPage } from "../../../components/pages/Organizations/Editor";
import { get, post, put } from "../../../lib/request";
import { endpoint } from "../../../routes/endpoints";
import { EditorType } from "../../../types/editorTypes";

import { reducer } from "./reducer";
import { initState } from "./state";

type Props = {
	type: EditorType;
};

export const OrganizationEditorContainer: FunctionComponent<Props> = React.memo(({ type }) => {
	const [t] = useTranslation();

	const navigate = useNavigate();

	const { organizationId } = useParams<"organizationId">();

	const [state, dispatch] = useReducer(reducer, type, initState);

	useEffect(() => {
		document.title = `${t("pageTitle.editOrganization")} - ${t("pageTitle.siteName")}`;
	}, []);

	useEffect(() => {
		if (organizationId == null) return;

		get<GetOneOrganizationResponse>(`${apiEndpoint.organization}/${organizationId}`)
			.then((res) => {
				dispatch({ type: "organization", payload: { state: "loaded", value: res.data.organization } });
			})
			.catch((error) => {
				dispatch({ type: "organization", payload: { state: "failed", error: makeError(error) } });
			});
	}, [organizationId]);

	const handleChangeOrganization = useCallback(
		(value: Partial<Organization>) => dispatch({ type: "organization", payload: { state: "loaded", value } }),
		[]
	);

	const handleSubmit = useCallback(
		(organization: Partial<Organization>) => {
			dispatch({ type: "progressState", payload: "progress" });

			(type === "create"
				? post<UpsertOrganizationResponse>(apiEndpoint.organization, organization)
				: put<UpsertOrganizationResponse>(`${apiEndpoint.organization}/${organizationId ?? ""}`, organization)
			)
				.then((res) => {
					dispatch({ type: "progressState", payload: "done" });
					navigate(`${endpoint.organizations}/${res.data.organization._id.toString()}`, {
						state: { message: t(`message.${type}Organization`) },
					});
				})
				.catch((error) => {
					dispatch({ type: "error", payload: makeError(error) });
				});
		},
		[organizationId, type]
	);

	return (
		<OrganizationEditorPage
			organizationId={organizationId}
			type={type}
			onChangeOrganization={handleChangeOrganization}
			onSubmit={handleSubmit}
			{...state}
		/>
	);
});
