import { ErrorViewer } from "@bleu/utility-components";
import { Key } from "@lu/repository";
import { Error, ProgressState } from "@lu/request";
import React, { FunctionComponent, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Job } from "../../../../../../../server/repositories/job/model";
import { ConfirmModal } from "../../../../parts/ConfirmModal";

type Props = {
	error: Error;
	job?: Job;
	progressState: ProgressState;
	onHide: (jobId?: Key) => void;
};

export const CloneJobModal: FunctionComponent<Props> = React.memo(({ error, job, progressState, onHide }) => {
	const [t] = useTranslation();

	const handleCancel = useCallback(() => onHide(), [onHide]);

	const handleOk = useCallback(() => {
		job != null && onHide(job._id);
	}, [job?._id, onHide]);

	return (
		<ConfirmModal
			approveLabel={t("jobsPage.clone")}
			disabled={progressState === "progress"}
			show={job != null}
			title={t("jobsPage.cloneJob")}
			onCancel={handleCancel}
			onOk={handleOk}
		>
			<ErrorViewer error={error} />
			<div className="jobs-page__clone-job-modal__message">{t("jobsPage.cloneJobMessage")}</div>
			<div className="jobs-page__clone-job-modal__message">
				<span>{t("job.title")}</span>
				<span>{job?.title}</span>
			</div>
		</ConfirmModal>
	);
});
