import { ContentsState } from "@lu/request";
import React, { FunctionComponent, useCallback, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { AccountWithoutPassword } from "../../../server/repositories/account/model";
import { ErrorPage } from "../components/pages/error";
import { Drawer } from "../components/parts/Drawer";
import { NavBar } from "../components/parts/NavBar";
import { WithAuth } from "../containers/auth";
import { LoginContainer } from "../containers/login";
import { LogoutContainter } from "../containers/logout";
import { Main } from "../containers/main";
import { SearchParamsProvider } from "../lib/customHooks/useSearchParams";
import { getFluid } from "../utils/fluid";

import { Accounts } from "./accounts";
import { endpoint } from "./endpoints";
import { Jobs } from "./jobs";
import { Organizations } from "./organizations";
import { reducer } from "./reducer";
import { initState } from "./state";

export const App: FunctionComponent = () => {
	const [t] = useTranslation();

	const [state, dispatch] = useReducer(reducer, initState);

	useEffect(() => {
		const fluid = getFluid(window.innerWidth);
		dispatch({ type: "fluid", payload: fluid });
	}, []);

	useEffect(() => {
		const func = () => {
			const fluid = getFluid(window.innerWidth);

			if (state.fluid !== fluid) {
				dispatch({ type: "fluid", payload: fluid });
			}
		};

		window.addEventListener("resize", func);

		return () => {
			window.removeEventListener("resize", func);
		};
	}, [state.fluid]);

	const handleOpenDrawer = useCallback((payload: boolean) => dispatch({ type: "drawerOpen", payload }), []);

	const handleLogin = useCallback(
		(account: ContentsState<AccountWithoutPassword>) => dispatch({ type: "account", payload: account }),
		[]
	);

	const handleLogout = useCallback(() => dispatch({ type: "account", payload: { state: "loading" } }), []);

	const handleToggleDrawer = useCallback(
		() => dispatch({ type: "drawerOpen", payload: !state.drawerOpen }),
		[state.drawerOpen]
	);

	return (
		<BrowserRouter>
			<NavBar
				account={state.account}
				onToggleDrawer={state.account.state === "loaded" ? handleToggleDrawer : undefined}
			/>
			<div className="main-container">
				{state.account.state === "loaded" && state.fluid !== undefined && (
					<Drawer
						loginAccount={state.account.value}
						fluid={state.fluid}
						open={state.drawerOpen}
						onOpen={handleOpenDrawer}
					/>
				)}
				<Main>
					<SearchParamsProvider>
						<Routes>
							<Route path={endpoint.logout} element={<LogoutContainter onLogout={handleLogout} />} />
							<Route path={endpoint.login} element={<LoginContainer onLogin={handleLogin} />} />
							<Route
								path={`${endpoint.accounts}/*`}
								element={
									<WithAuth loginAccount={state.account} onLogin={handleLogin}>
										<Accounts />
									</WithAuth>
								}
							/>
							<Route
								path={`${endpoint.organizations}/*`}
								element={
									<WithAuth loginAccount={state.account} onLogin={handleLogin} requiredRole="admin">
										<Organizations />
									</WithAuth>
								}
							/>
							<Route
								path={`${endpoint.jobs}/*`}
								element={
									<WithAuth loginAccount={state.account} onLogin={handleLogin}>
										<Jobs />
									</WithAuth>
								}
							/>
							<Route
								path={endpoint.top}
								element={
									<WithAuth loginAccount={state.account} onLogin={handleLogin}>
										<Jobs />
									</WithAuth>
								}
							/>
							<Route path="*" element={<ErrorPage text={t("notFound.text")} title={t("pageTitle.notFound")} />} />
						</Routes>
					</SearchParamsProvider>
				</Main>
			</div>
		</BrowserRouter>
	);
};
