export const endpoint = {
	accounts: "/accounts",
	jobs: "/jobs",
	login: "/login",
	logout: "/logout",
	organizations: "/organizations",
	top: "/",
} as const;

export const aggregatesEndpoint = {
	create: "create",
	cross: "cross",
	edit: "edit",
	gt: "gt",
	syncRawdata: "sync-rawdata",
	uploadRawdata: "upload-rawdata",
} as const;

export const accountsEndpoint = {
	create: "create",
	edit: "edit",
	password: "password",
} as const;

export const jobsEndpoint = {
	aggregates: "aggregates",
	create: "create",
	edit: "edit",
} as const;

export const organizationsEndpoint = {
	create: "create",
	edit: "edit",
} as const;
