import React, { FunctionComponent } from "react";
import { Dropdown } from "react-bootstrap";
import { DropdownToggleProps } from "react-bootstrap/esm/DropdownToggle";

import { DropdownToggleButton } from "./Button";

type Props = DropdownToggleProps;

export const DropdownToggle: FunctionComponent<Props> = React.memo(({ children, ...props }) => {
	return (
		<Dropdown.Toggle as={DropdownToggleButton} {...props}>
			{children}
		</Dropdown.Toggle>
	);
});
