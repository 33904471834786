import { makeError } from "@bleu/core";
import React, { FunctionComponent, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import { apiEndpoint } from "../../../../../server/router/api/endpoint";
import { GetAggregateQuestion, GetAggregateResponse } from "../../../../../server/types/request/aggregate";
import { GetOneJobResponse } from "../../../../../server/types/request/job";
import { AggregateJobDetailsPage } from "../../../components/pages/aggregates/Details";
import { get } from "../../../lib/request";

import { reducer } from "./reducer";
import { initState } from "./state";

export const AggregateJobDetailsContainer: FunctionComponent = React.memo(() => {
	const [t] = useTranslation();

	const { aggregateJobId, jobId } = useParams<"jobId" | "aggregateJobId">();

	const [state, dispatch] = useReducer(reducer, initState);

	useEffect(() => {
		document.title = `${t("pageTitle.aggregateDetails")} - ${t("pageTitle.siteName")}`;
	}, []);

	useEffect(() => {
		if (aggregateJobId == null) return;

		get<GetAggregateResponse>(`${apiEndpoint.aggregateJob}/${aggregateJobId}`)
			.then((res) => {
				dispatch({
					type: "aggregateJob",
					payload: { state: "loaded", value: res.data.aggregateJob },
				});
			})
			.catch((error) => {
				console.error(error);
				dispatch({
					type: "aggregateJob",
					payload: { state: "failed", error: makeError(error) },
				});
			});
	}, [aggregateJobId]);

	useEffect(() => {
		if (jobId == null) return;

		Promise.all([
			get<GetOneJobResponse>(`${apiEndpoint.job}/${jobId}`)
				.then((res) => {
					dispatch({
						type: "job",
						payload: { state: "loaded", value: res.data.job },
					});
					return res.data.job;
				})
				.catch((error) => {
					dispatch({
						type: "job",
						payload: { state: "failed", error: makeError(error) },
					});
					throw error;
				}),
			get<GetAggregateQuestion>(`${apiEndpoint.aggregateQuestion}/${jobId}`)
				.then((res) => {
					dispatch({
						type: "questions",
						payload: { state: "loaded", value: res.data.questions },
					});
					return res.data.questions;
				})
				.catch((error) => {
					dispatch({
						type: "questions",
						payload: { state: "failed", error: makeError(error) },
					});
					throw error;
				}),
		]).catch((error) => {
			console.error(error);
		});
	}, [jobId]);

	return <AggregateJobDetailsPage aggregateJobId={aggregateJobId} jobId={jobId} {...state} />;
});
