import classNames from "classnames";
import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";

export type Path = { label: string; to: string };

type Props = {
	className?: string;
	paths: Path[];
};

export const PathsContainer: FunctionComponent<Props> = React.memo(({ className, paths }) => {
	return (
		<div className={classNames("paths-container", className)}>
			{paths.map(({ label, to }, i) => (
				<div key={label} className="paths-container__path">
					{i > 0 && <i className="bi-chevron-right"></i>}
					<Link to={to}>{label}</Link>
				</div>
			))}
		</div>
	);
});
