import { ButtonsBlock } from "@bleu/utility-components";
import { ContentsState } from "@lu/request";
import React, { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Role } from "../../../../../../server/lib/permission/role";
import { AccountWithoutPassword, systemRole } from "../../../../../../server/repositories/account/model";
import { Organization } from "../../../../../../server/repositories/organization/model";
import { useAccount } from "../../../../lib/customHooks/useAccount";
import { accountsEndpoint, endpoint } from "../../../../routes/endpoints";
import { EditButton } from "../../../parts/buttons";
import { Tip } from "../../../parts/Tip";

type Props = {
	account: ContentsState<AccountWithoutPassword>;
	accountId: string;
	organization: ContentsState<Organization>;
};

export const AccountDetailsHeader: FunctionComponent<Props> = React.memo(({ account, accountId, organization }) => {
	const [t] = useTranslation();

	const loginAccount = useAccount();

	const email = useMemo(() => {
		if (account.state !== "loaded") return "";

		return account.value.email;
	}, [account]);

	const isAdmin = useMemo(() => Role.isAdmin(loginAccount), [loginAccount]);

	const name = useMemo(() => {
		if (account.state !== "loaded") return "";

		return account.value.name;
	}, [account]);

	const organizationTip = useMemo(() => {
		if (organization.state !== "loaded") return "";

		return <Tip to={`${endpoint.organizations}/${organization.value._id.toString()}`}> {organization.value.name}</Tip>;
	}, [organization]);

	const role = useMemo(() => {
		if (account.state !== "loaded") return "";

		return systemRole[account.value.role];
	}, [account]);

	return (
		<div className="account-details-page__header">
			<ButtonsBlock className="account-details-page__header__buttons-container" align="right" wide>
				{account.state === "loaded" && (Role.hasManagementRole(account.value) || account.value._id === accountId) && (
					<EditButton as={Link} to={`${endpoint.accounts}/${accountId}/${accountsEndpoint.edit}`}>
						{t("accountDetailsPage.edit")}
					</EditButton>
				)}
			</ButtonsBlock>
			<h4 className="account-details-page__header__name">{name}</h4>
			<div className="account-details-page__header__values">
				<ValueWrapper>
					<i className="bi-envelope-fill" />
					<span>{email}</span>
				</ValueWrapper>
				{isAdmin && (
					<ValueWrapper>
						<i className="bi-building" />
						{organizationTip}
					</ValueWrapper>
				)}
				<ValueWrapper>
					<i className="bi-person-badge" />
					<span>{role}</span>
				</ValueWrapper>
			</div>
		</div>
	);
});

const ValueWrapper: FunctionComponent = React.memo(({ children }) => {
	return <div className="account-details-page__header__value-wrapper">{children}</div>;
});
