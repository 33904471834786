import { ContentsState } from "@lu/request";
import React, { FunctionComponent } from "react";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import NavBarBase from "react-bootstrap/Navbar";
import { useTranslation } from "react-i18next";

import { AccountWithoutPassword } from "../../../../../server/repositories/account/model";
import { endpoint } from "../../../routes/endpoints";

import { AccountDropdown } from "./account-dropdown";

export type Props = {
	account: ContentsState<AccountWithoutPassword>;
	bg?: string;
	onToggleDrawer?: () => void;
};

export const NavBar: FunctionComponent<Props> = React.memo(({ account, bg = "white", onToggleDrawer }) => {
	const [t] = useTranslation();

	return (
		<NavBarBase className="nav-bar" bg={bg} expand="sm" variant="light">
			{onToggleDrawer != null && (
				<Button className="nav-bar__drawer-control" size="lg" variant={bg} onClick={onToggleDrawer}>
					<i className="bi-list" />
				</Button>
			)}
			<NavBarBase.Brand className="nav-bar__brand" href={endpoint.top}>
				<img className="nav-bar__brand__image" alt="" src="/images/brand48x48.png" />
				<span className="nav-bar__brand__text">{t("pageTitle.siteName")}</span>
			</NavBarBase.Brand>
			<Nav className="nav-bar__right-menu">
				{account.state === "loaded" && <AccountDropdown account={account.value} variant={bg} />}
			</Nav>
		</NavBarBase>
	);
});
