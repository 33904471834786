import { SystemRole } from "../../../server/repositories/account/model";

const roleList: SystemRole[] = ["viewer", "general", "organizationAdmin", "admin"];

export const checkAuthority = (checked: SystemRole, required: SystemRole): boolean => {
	const checkedIndex = roleList.findIndex((r) => r === checked);
	const requiredIndex = roleList.findIndex((r) => r === required);

	return checkedIndex >= requiredIndex;
};
