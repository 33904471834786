import { makeError } from "@bleu/core";
import { GetCrossAggregateResponse } from "@lu/muscat-analytics-library/dist/model/api/corss-aggregate";
import React, { FunctionComponent, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import { apiEndpoint } from "../../../../../server/router/api/endpoint";
import { GetAggregateResponse } from "../../../../../server/types/request/aggregate";
import { GetOneJobResponse } from "../../../../../server/types/request/job";
import { CrossViewerPage } from "../../../components/pages/aggregates/CrossViewer";
import { get } from "../../../lib/request";

import { reducer } from "./reducer";
import { initState } from "./state";

export const CrossViewerContainer: FunctionComponent = React.memo(() => {
	const [t] = useTranslation();

	const { aggregateJobId, jobId } = useParams<"aggregateJobId" | "jobId">();

	const [state, dispatch] = useReducer(reducer, initState);

	useEffect(() => {
		document.title = `${t("pageTitle.cross")} - ${t("pageTitle.siteName")}`;
	}, []);

	useEffect(() => {
		if (aggregateJobId == null) return;

		get<GetCrossAggregateResponse>(`${apiEndpoint.aggregateCrossData}/${aggregateJobId}`)
			.then((res) => {
				dispatch({ type: "crossDatas", payload: { state: "loaded", value: res.data.data } });
			})
			.catch((error) => {
				dispatch({ type: "crossDatas", payload: { state: "failed", error: makeError(error) } });
			});

		get<GetAggregateResponse>(`${apiEndpoint.aggregateJob}/${aggregateJobId}`)
			.then((res) => {
				dispatch({
					type: "aggregateJob",
					payload: { state: "loaded", value: res.data.aggregateJob },
				});
			})
			.catch((error) => {
				console.error(error);
				dispatch({
					type: "aggregateJob",
					payload: { state: "failed", error: makeError(error) },
				});
			});
	}, [aggregateJobId]);

	useEffect(() => {
		if (jobId == null) return;

		get<GetOneJobResponse>(`${apiEndpoint.job}/${jobId}`)
			.then((res) => {
				dispatch({ type: "job", payload: { state: "loaded", value: res.data.job } });
			})
			.catch((error) => {
				dispatch({ type: "job", payload: { state: "failed", error: makeError(error) } });
			});
	}, [jobId]);

	return <CrossViewerPage aggregateJobId={aggregateJobId} jobId={jobId} {...state} />;
});
