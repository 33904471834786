import { ContentsState } from "@lu/request";

import { AccountWithoutPassword } from "../../../../../server/repositories/account/model";
import { Organization } from "../../../../../server/repositories/organization/model";
import { GetJobListResponse } from "../../../../../server/types/request/job";

export type State = {
	account: ContentsState<AccountWithoutPassword>;
	createJobs: ContentsState<GetJobListResponse>;
	memberJobs: ContentsState<GetJobListResponse>;
	organization: ContentsState<Organization>;
};

export const initState: State = {
	account: { state: "loading" },
	createJobs: { state: "loading" },
	memberJobs: { state: "loading" },
	organization: { state: "loading" },
};
