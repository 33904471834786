import { makeError } from "@bleu/core";
import { LoadingSpinner } from "@bleu/utility-components";
import { ContentsState } from "@lu/request";
import React, { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation } from "react-router";

import { AccountWithoutPassword, SystemRole } from "../../../../server/repositories/account/model";
import { apiEndpoint } from "../../../../server/router/api/endpoint";
import { AuthResponse } from "../../../../server/types/auth";
import { AccountProvider } from "../../lib/customHooks/useAccount";
import { post } from "../../lib/request";
import { endpoint } from "../../routes/endpoints";
import { checkAuthority } from "../../utils/auth-checker";

export type WithAuthProps = {
	loginAccount: ContentsState<AccountWithoutPassword>;
	requiredRole?: SystemRole;
	onLogin: (account: ContentsState<AccountWithoutPassword>) => void;
};

export const WithAuth: FunctionComponent<WithAuthProps> = React.memo(
	({ children, loginAccount, requiredRole, onLogin }) => {
		const [t] = useTranslation();

		const { pathname } = useLocation();

		useEffect(() => {
			if (loginAccount.state === "loaded") return;

			post<AuthResponse>(apiEndpoint.authentication)
				.then((res) => {
					if (res.data.account != null) {
						onLogin({ state: "loaded", value: res.data.account });
					} else {
						onLogin({ state: "failed", error: { message: t("erroeMessage.alreadyLogout") } });
					}
				})
				.catch((error) => {
					console.error(error);
					onLogin({ state: "failed", error: makeError(error) });
				});
		}, [loginAccount.state, onLogin]);

		return (
			<>
				{loginAccount.state === "loading" ? (
					<LoadingSpinner />
				) : loginAccount.state === "failed" ||
				  (loginAccount.state === "loaded" &&
						requiredRole != null &&
						!checkAuthority(loginAccount.value.role, requiredRole)) ? (
					<Navigate to={endpoint.login} state={{ redirect: pathname }} />
				) : (
					<AccountProvider account={loginAccount.value}>{children}</AccountProvider>
				)}
			</>
		);
	}
);
