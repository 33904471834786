import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";

import { ErrorPage } from "../components/pages/error";
import { AccountDetailsContainer } from "../containers/accounts/Details";
import { MyAccountEditorContainer } from "../containers/myAccount/Edit";
import { MyPasswordContainer } from "../containers/myAccount/Password";

import { accountsEndpoint } from "./endpoints";

export const MyAccount: FunctionComponent = () => {
	const [t] = useTranslation();

	return (
		<Routes>
			<Route path="/" element={<AccountDetailsContainer />} />
			<Route path={accountsEndpoint.edit} element={<MyAccountEditorContainer type="edit" />} />
			<Route path={accountsEndpoint.password} element={<MyPasswordContainer />} />
			<Route path="*" element={<ErrorPage text={t("errorPage.notFound")} title={t("pageTitle.notFound")} />} />
		</Routes>
	);
};
