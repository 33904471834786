import { AggregateTargetQuestion } from "@bleu-analytics/core";
import { AggregateJobViewer } from "@bleu-analytics/editor";
import { ButtonsBlock, ContentsStateViewer } from "@bleu/utility-components";
import { Key } from "@lu/repository";
import { ContentsState } from "@lu/request";
import React, { FunctionComponent, useMemo } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { AggregateJobWithConfig } from "../../../../../../server/repositories/aggregate-job/model";
import { Job } from "../../../../../../server/repositories/job/model";
import { aggregatesEndpoint, endpoint, jobsEndpoint } from "../../../../routes/endpoints";
import { EditButton } from "../../../parts/buttons";
import Page from "../../../parts/Page";

type Props = {
	aggregateJob: ContentsState<AggregateJobWithConfig>;
	aggregateJobId?: Key;
	job: ContentsState<Job>;
	jobId: Key;
	questions: ContentsState<AggregateTargetQuestion[]>;
};

export const AggregateJobDetailsPage: FunctionComponent<Props> = React.memo(
	({ aggregateJob, aggregateJobId, job, jobId, questions }) => {
		const [t] = useTranslation();

		const pageTitle = useMemo(
			() => `${t("pageTitle.aggregateDetails")} - ${job.state === "loaded" ? job.value.title : ""}`,
			[job]
		);

		const paths = useMemo(
			() => [
				{ label: t("pageTitle.jobs"), to: endpoint.jobs },
				{
					label: (job.state === "loaded" ? job.value.title : undefined) ?? t("pageTitle.jobDetails"),
					to: `${endpoint.jobs}/${jobId}`,
				},
				{ label: t("pageTitle.aggregates"), to: `${endpoint.jobs}/${jobId}/${jobsEndpoint.aggregates}` },
				{
					label: aggregateJob.state === "loaded" ? aggregateJob.value.name : t("pageTitle.aggregateDetails"),
					to: `${endpoint.jobs}/${jobId}/${jobsEndpoint.aggregates}/${aggregateJobId}`,
				},
			],
			[aggregateJob, aggregateJobId, job, jobId]
		);

		return (
			<Page className="aggregate-details-page">
				<Page.Header paths={paths} title={pageTitle}>
					<ButtonsBlock align="right" wide>
						<Dropdown>
							<Dropdown.Toggle>
								<i className="bi-eye-fill" />
								<span>{t("jobDetailsPage.preview")}</span>
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item
									as={Link}
									to={`${endpoint.jobs}/${jobId}/${jobsEndpoint.aggregates}/${aggregateJobId}/${aggregatesEndpoint.cross}`}
								>
									{t("aggregateDetailsPage.cross")}
								</Dropdown.Item>
								<Dropdown.Item
									as={Link}
									to={`${endpoint.jobs}/${jobId}/${jobsEndpoint.aggregates}/${aggregateJobId}/${aggregatesEndpoint.gt}`}
								>
									{t("aggregateDetailsPage.gt")}
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
						<EditButton
							as={Link}
							to={`${endpoint.jobs}/${jobId}/${jobsEndpoint.aggregates}/${aggregateJobId}/${aggregatesEndpoint.edit}`}
						>
							{t("aggregateDetailsPage.edit")}
						</EditButton>
						<DropdownButton
							align="end"
							disabled={aggregateJob.state !== "loaded"}
							title={t("aggregateDetailsPage.rawdata")}
						>
							{aggregateJob.state === "loaded" && (
								<>
									{aggregateJob.value.importType === "manual" && (
										<Dropdown.Item
											as={Link}
											to={`${endpoint.jobs}/${jobId}/${jobsEndpoint.aggregates}/${aggregateJobId}/${aggregatesEndpoint.uploadRawdata}`}
										>
											{t("aggregateDetailsPage.upload")}
										</Dropdown.Item>
									)}
									{aggregateJob.value.importType !== "manual" && (
										<Dropdown.Item
											as={Link}
											to={`${endpoint.jobs}/${jobId}/${jobsEndpoint.aggregates}/${aggregateJobId}/${aggregatesEndpoint.syncRawdata}`}
										>
											{t("aggregateDetailsPage.sync")}
										</Dropdown.Item>
									)}
								</>
							)}
						</DropdownButton>
					</ButtonsBlock>
				</Page.Header>
				<Page.Body>
					{aggregateJob.state === "loaded" && questions.state === "loaded" && (
						<AggregateJobViewer aggregateJob={aggregateJob.value} questions={questions.value} />
					)}
					<ContentsStateViewer values={{ aggregateJob, questions }} />
				</Page.Body>
			</Page>
		);
	}
);
