import { PagingTable } from "@bleu/utility-components";
import React, { FunctionComponent, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { Role } from "../../../../../../server/lib/permission/role";
import { systemRole } from "../../../../../../server/repositories/account/model";
import { Organization } from "../../../../../../server/repositories/organization/model";
import { GetAccountListResponse } from "../../../../../../server/types/request/account";
import { useAccount } from "../../../../lib/customHooks/useAccount";
import { endpoint } from "../../../../routes/endpoints";

export const AccountsPageBody: FunctionComponent<{
	accounts: GetAccountListResponse;
	organizations: Organization[];
	onGet: (page: number) => void;
}> = React.memo(({ accounts, organizations, onGet }) => {
	const { page, pages, rows } = accounts;

	const [t] = useTranslation();

	const navigate = useNavigate();

	const account = useAccount();

	const isAdmin = useMemo(() => Role.isAdmin(account), [account]);

	const organizationMap = useMemo(() => new Map(organizations.map(({ _id, name }) => [_id, name])), [organizations]);

	const header = useMemo(
		() =>
			[
				{ label: t("account.name"), value: "name" },
				{ label: t("account.email"), value: "email" },
				isAdmin ? { label: t("account.organization"), value: "organization" } : undefined,
				{ label: t("account.role"), value: "role" },
				{ label: t("account.status"), value: "status" },
			].filter((item): item is { label: string; value: string } => Boolean(item)),
		[isAdmin]
	);

	const items = useMemo(
		() =>
			rows.map(({ inactive = false, organizationId, role, ..._account }) => ({
				..._account,
				organization: organizationMap.get(organizationId),
				role: systemRole[role],
				status: t(`accountStatus.${inactive ? "inactive" : "active"}`),
				to: `${endpoint.accounts}/${_account._id ?? ""}`,
			})),
		[accounts, organizationMap]
	);

	const handleClickItem = useCallback(
		(index: number) => {
			navigate(`${endpoint.accounts}/${rows[index]._id}`);
		},
		[rows]
	);

	return (
		<PagingTable items={items} header={header} maxPage={pages} page={page} onClickItem={handleClickItem} onGet={onGet}>
			<colgroup className="accounts-page__table__colgroup">
				<col className="accounts-page__table__colgroup__name" />
				<col className="accounts-page__table__colgroup__email" />
				{isAdmin && <col className="accounts-page__table__colgroup__organization" />}
				<col className="accounts-page__table__colgroup__role" />
				<col className="accounts-page__table__colgroup__status" />
			</colgroup>
		</PagingTable>
	);
});
