import { makeError } from "@bleu/core";
import React, { FunctionComponent, useCallback, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";

import { Account } from "../../../../../server/repositories/account/model";
import { apiEndpoint } from "../../../../../server/router/api/endpoint";
import { GetOneAccountResponse, UpsertAccountResponse } from "../../../../../server/types/request/account";
import { PasswordEditorPage } from "../../../components/pages/accounts/Password";
import { get, put } from "../../../lib/request";
import { endpoint } from "../../../routes/endpoints";

import { reducer } from "./reducer";
import { initState } from "./state";

type Props = {
	accountId?: string;
};

export const PasswordContainer: FunctionComponent<Props> = React.memo(({ accountId }) => {
	const [t] = useTranslation();

	const _accountId = accountId ?? useParams<"accountId">().accountId;

	const navigate = useNavigate();

	const [state, dispatch] = useReducer(reducer, initState);

	useEffect(() => {
		document.title = `${t("pageTitle.editPassword")} - ${t("pageTitle.siteName")}`;
	}, []);

	useEffect(() => {
		if (_accountId === undefined) return;

		get<GetOneAccountResponse>(`${apiEndpoint.account}/${_accountId}`)
			.then((res) => {
				dispatch({ type: "account", payload: { state: "loaded", value: res.data.account } });
			})
			.catch((error) => {
				dispatch({ type: "account", payload: { state: "failed", error: makeError(error) } });
			});
	}, [_accountId]);

	const handleChangeAccount = useCallback(
		(value: Partial<Account>) => dispatch({ type: "account", payload: { state: "loaded", value } }),
		[]
	);

	const handleSubmit = useCallback(
		(account: Partial<Account>) => {
			dispatch({ type: "progressState", payload: "progress" });

			put<UpsertAccountResponse>(`${apiEndpoint.account}/${_accountId}`, account)
				.then((res) => {
					dispatch({ type: "progressState", payload: "done" });
					navigate(`${endpoint.accounts}/${res.data.account._id.toString()}`, {
						state: { message: t("message.changedPassword") },
					});
				})
				.catch((error) => {
					dispatch({ type: "error", payload: makeError(error) });
				});
		},
		[_accountId]
	);

	return (
		<PasswordEditorPage
			accountId={_accountId}
			onChangeAccount={handleChangeAccount}
			onSubmit={handleSubmit}
			{...state}
		/>
	);
});
