import { ButtonsBlock } from "@bleu/utility-components";
import classNames from "classnames";
import React, { FunctionComponent, useCallback, useMemo, useReducer } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Collapse from "react-bootstrap/Collapse";
import { useTranslation } from "react-i18next";

import { IconButton } from "../buttons/IconButton";

import {
	CheckboxItem,
	CheckboxProps,
	DateInputItem,
	DateInputProps,
	SelectItem,
	SelectProps,
	TextInputItem,
	TextInputProps,
} from "./item";

export type ItemProps =
	| Omit<CheckboxProps, "value" | "onChange">
	| Omit<DateInputProps, "value" | "onChange">
	| Omit<SelectProps, "value" | "onChange">
	| Omit<TextInputProps, "value" | "onChange">;

export type SearchParams = { [key in string]?: any | any[] | { since?: Date; until?: Date } };

type State = {
	open: boolean;
	searchOptions: SearchParams;
};

type Action =
	| { type: "open"; payload: boolean }
	| {
			type: "searchOptions";
			payload: SearchParams;
	  }
	| {
			type: "searchOption";
			payload: { key: string; value: any | any[] | { sicne?: Date; until?: Date } };
	  };

const reducer = (state: State, action: Action): State => {
	switch (action.type) {
		case "open":
			return {
				...state,
				open: action.payload,
			};
		case "searchOption":
			return {
				...state,
				searchOptions: {
					...state.searchOptions,
					[action.payload.key]: action.payload.value,
				},
			};
		case "searchOptions":
			return { ...state, searchOptions: action.payload };
	}
};

type Props = {
	className?: string;
	defaultSearchOptions?: { [key in string]: any };
	items: ItemProps[];
	type?: "search" | "filter" | "extract";
	onSearch: (searchVal: { [key in string]: any }) => void;
};

const initState = (searchOptions: { [key in string]: any }): State => ({
	open: true,
	searchOptions,
});

export const SearchField: FunctionComponent<Props> = React.memo(
	({ className, defaultSearchOptions = {}, items, type = "search", onSearch }) => {
		const [t] = useTranslation();

		const [state, dispatch] = useReducer(reducer, defaultSearchOptions, initState);

		const icon = useMemo(() => <i className={`bi-${type === "search" ? "search" : "funnel-fill"}`} />, [type]);

		const handleChangeSearchOption = useCallback((key: string, value: any | any[] | { since?: Date; until?: Date }) => {
			dispatch({ type: "searchOption", payload: { key, value } });
		}, []);

		const handleClickOpen = useCallback(() => dispatch({ type: "open", payload: !state.open }), [state.open]);

		const handleClickReset = useCallback(() => {
			dispatch({ type: "searchOptions", payload: {} });
			onSearch({});
		}, []);

		const handleClickSearch = useCallback(() => {
			onSearch(state.searchOptions);
		}, [state.searchOptions]);

		return (
			<Card className={classNames("search-field", className, { "search-field--open": state.open })}>
				<div className="search-field__header">
					<IconButton
						className="search-field__header__button"
						id="search-field-control"
						aria-controls="search-field-collapse"
						aria-expanded={state.open}
						variant="white"
						onClick={handleClickOpen}
					>
						<i className="bi-chevron-up" />
					</IconButton>
					<div className="search-field__header__title">{t(`searchField.${type}`)}</div>
				</div>
				<Card.Body>
					<Collapse in={state.open}>
						<div id="search-field-collapse">
							<SearchMenuItems propsList={items} values={state.searchOptions} onChange={handleChangeSearchOption} />
							<ButtonsBlock align="right" wide>
								<Button variant="outline-primary" onClick={handleClickReset}>
									<span>{t("searchField.reset")}</span>
								</Button>
								<Button variant="primary" onClick={handleClickSearch}>
									{icon}
									<span>{t(`searchField.${type}`)}</span>
								</Button>
							</ButtonsBlock>
						</div>
					</Collapse>
				</Card.Body>
			</Card>
		);
	}
);

const SearchMenuItems: FunctionComponent<{
	propsList: ItemProps[];
	values: { [key in string]: any | any[] | { since?: Date; until?: Date } };
	onChange: (key: string, value: any | any[] | { since?: Date; until?: Date }) => void;
}> = React.memo(({ propsList, values, onChange }) => {
	return (
		<div className="search-field__menu-items">
			{propsList.map((props) =>
				props.type === "checkbox" ? (
					<CheckboxItem key={props._key} {...props} value={values[props._key] as string[]} onChange={onChange} />
				) : props.type === "date" ? (
					<DateInputItem
						key={props._key}
						{...props}
						value={values[props._key] as { since?: Date; until?: Date }}
						onChange={onChange}
					/>
				) : props.type === "select" ? (
					<SelectItem key={props._key} {...props} value={values[props._key] as string} onChange={onChange} />
				) : (
					<TextInputItem key={props._key} {...props} value={values[props._key] as string} onChange={onChange} />
				)
			)}
		</div>
	);
});
