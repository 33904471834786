import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { apiEndpoint } from "../../../../server/router/api/endpoint";
import { LogoutPage } from "../../components/pages/logout";
import { post } from "../../lib/request";

type Props = {
	onLogout: () => void;
};

export const LogoutContainter: React.FunctionComponent<Props> = ({ onLogout }) => {
	const [t] = useTranslation();

	const [isLogout, setIslogout] = useState<boolean>(false);

	useEffect(() => {
		document.title = `${t("pageTitle.logout")} - ${t("pageTitle.siteName1")}`;
	}, []);

	useEffect(() => {
		post(apiEndpoint.logout, {}).finally(() => {
			setIslogout(true);
			onLogout();
		});
	}, [onLogout]);

	return isLogout ? <LogoutPage /> : null;
};
