import {
	JobData,
	UpsertJobDataRequest,
	ageQuestionTemplate,
	genderQuestionTemplate,
	prefectureQuestionTemplate,
} from "@bleu/core";
import { EnqueteEditor } from "@bleu/editor";
import { Portal } from "@bleu/utility-components";
import { ContentsState } from "@lu/request";
import i18n from "i18next";
import React, { FunctionComponent } from "react";
import { I18nextProvider } from "react-i18next";

import { AccountWithoutPassword } from "../../../../../../server/repositories/account/model";
import { webEndpoint } from "../../../../../../server/router/web/endpoint";
import { Job } from "../../../../types/job";

import { EditorChildren } from "./Children";

type Props = {
	accounts: ContentsState<AccountWithoutPassword[]>;
	jobId?: string;
	jobData: ContentsState<JobData<Job>>;
	onClose: () => void;
	onSave: (data: UpsertJobDataRequest<Job>) => Promise<JobData<Job>>;
	onUpdate: (job: Job) => void;
	onUploadImage: (file: File) => Promise<string>;
};

export const JobEditorPage: FunctionComponent<Props> = React.memo(
	({ accounts, jobData, onClose, onSave, onUpdate, onUploadImage }) => {
		return (
			<Portal>
				<EnqueteEditor
					data={jobData}
					icon={<img src="/images/brand48x48.png" />}
					questionOptions={{
						attributeQuestionTemplates: [ageQuestionTemplate, genderQuestionTemplate, prefectureQuestionTemplate],
						enableMainAttributeQuestionsCount: true,
						maxAttributeQuestionsCount: 6,
					}}
					variants={{ background: "background", controlSecondary: "outline-primary" }}
					webEndpoints={webEndpoint}
					webUrls={{
						enquete: `${process.env.MUSCAT_APP_URL ?? ""}/enquete`,
						preview: `${process.env.MUSCAT_APP_URL ?? ""}/preview`,
					}}
					onClose={onClose}
					onSave={onSave}
					onUpdateJob={onUpdate}
					onUploadImage={onUploadImage}
				>
					{accounts.state === "loaded" && jobData.state === "loaded" && (
						<I18nextProvider i18n={i18n}>
							<EditorChildren accounts={accounts.value} job={jobData.value.job} onChangeJob={onUpdate} />
						</I18nextProvider>
					)}
				</EnqueteEditor>
			</Portal>
		);
	}
);
