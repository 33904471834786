import { Organization } from "../../../../../../server/repositories/organization/model";

import { UpdateAction } from "./updateAction";

export const update = (organization: Partial<Organization>, action: UpdateAction): Partial<Organization> => {
	switch (action.type) {
		case "code":
			return { ...organization, code: action.payload };
		case "name":
			return { ...organization, name: action.payload };
	}
};
