import { ButtonsBlock, ContentsStateViewer } from "@bleu/utility-components";
import { Key } from "@lu/repository";
import { ContentsState, Error, ProgressState } from "@lu/request";
import React, { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { AccountWithoutPassword } from "../../../../../../server/repositories/account/model";
import { GetJobListResponse } from "../../../../../../server/types/request/job";
import { useAccount } from "../../../../lib/customHooks/useAccount";
import { endpoint, jobsEndpoint } from "../../../../routes/endpoints";
import { checkAuthority } from "../../../../utils/auth-checker";
import { AddButton } from "../../../parts/buttons";
import Page from "../../../parts/Page";
import { ItemProps, SearchField, SearchParams } from "../../../parts/SearchField";

import { JobsPageBody } from "./Body";

type Props = {
	accounts: ContentsState<AccountWithoutPassword[]>;
	error?: Error;
	jobs: ContentsState<GetJobListResponse>;
	progressState: ProgressState;
	searchParams: SearchParams;
	targetIndex?: number;
	onClone: (jobId: Key) => void;
	onGet: (page: number) => void;
	onSearch: (SearchParams: SearchParams) => void;
	onSelectTarget: (index?: number) => void;
};

export const JobsPage: FunctionComponent<Props> = React.memo(({ accounts, jobs, searchParams, onSearch, ...props }) => {
	const [t] = useTranslation();

	const account = useAccount();

	const accountOptions = useMemo(() => {
		if (accounts.state !== "loaded") return [];

		return accounts.value.map(({ _id, name }) => ({ label: name, value: _id }));
	}, [accounts]);

	const searchItems: ItemProps[] = useMemo(() => {
		return [
			{ _key: "title", label: t("job.title"), type: "text" },
			{ _key: "createAccount", label: t("job.createAccount"), type: "select", options: accountOptions },
			{ _key: "createdAt", label: t("job.createdAt"), type: "date" },
			{ _key: "startDate", label: t("job.startDate"), type: "date" },
			{ _key: "finDate", label: t("job.finDate"), type: "date" },
			{
				_key: "enqueteStatus",
				label: t("enquete.status"),
				multiple: true,
				type: "select",
				options: [
					{ label: t("enqueteStatus.debug"), value: "debug" },
					{ label: t("enqueteStatus.progress"), value: "progress" },
					{ label: t("enqueteStatus.complete"), value: "complete" },
					{ label: t("enqueteStatus.stopping"), value: "stopping" },
				],
			},
		];
	}, [accountOptions]);

	return (
		<Page className="jobs-page">
			<Page.Header title={t("pageTitle.jobs")}>
				{checkAuthority(account.role, "general") && (
					<ButtonsBlock align="right" wide>
						<AddButton as={Link} to={`${endpoint.jobs}/${jobsEndpoint.create}`}>
							{t("jobsPage.createEnquete")}
						</AddButton>
					</ButtonsBlock>
				)}
			</Page.Header>
			<Page.Body>
				<SearchField defaultSearchOptions={searchParams} items={searchItems} onSearch={onSearch} />
				{accounts.state === "loaded" && jobs.state === "loaded" && (
					<JobsPageBody accounts={accounts.value} jobs={jobs.value} {...props} />
				)}
				<ContentsStateViewer values={{ accounts, jobs }} />
			</Page.Body>
		</Page>
	);
});
