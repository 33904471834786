import { ContentsStateViewer } from "@bleu/utility-components";
import { ContentsState } from "@lu/request";
import React, { FunctionComponent } from "react";

import { GetAccountListResponse } from "../../../../../../../server/types/request/account";

import { AccountsTableBody } from "./Body";

type Props = {
	accounts: ContentsState<GetAccountListResponse>;
	onGet: (page: number) => void;
};

export const AccountsTable: FunctionComponent<Props> = React.memo(({ accounts, onGet }) => {
	return (
		<>
			{accounts.state === "loaded" && <AccountsTableBody accounts={accounts.value} onGet={onGet} />}
			<ContentsStateViewer values={{ accounts }} />
		</>
	);
});
