import { ButtonsBlock, TitledBlock, ValidatableInput } from "@bleu/utility-components";
import { ProgressState } from "@lu/request";
import React, { FunctionComponent, useCallback, useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { Account } from "../../../../../../server/repositories/account/model";
import { endpoint } from "../../../../routes/endpoints";

import { update } from "./update";

type Props = {
	account: Partial<Account>;
	progressState: ProgressState;
	onChange: (account: Partial<Account>) => void;
	onSubmit: (account: Partial<Account>) => void;
};

export const PasswordEditorBody: FunctionComponent<Props> = React.memo(
	({ account, progressState, onChange, onSubmit }) => {
		const [t] = useTranslation();

		const navigate = useNavigate();

		const { password = "" } = account;

		const [passwordConfirm, setPAsswordConfirm] = useState<string>();

		const disabled = useMemo(() => password === "" || password !== passwordConfirm, [password, passwordConfirm]);

		const handleCancel = useCallback(() => {
			navigate(`${endpoint.accounts}/${account._id ?? ""}`, {
				state: { message: t("message.cancel") },
			});
		}, []);

		const handleInputPassword = useCallback(
			(payload: string) => {
				onChange(update(account, { type: "password", payload }));
			},
			[account, onChange]
		);

		const handleSubmit = useCallback(() => onSubmit(account), [account, onSubmit]);

		const passawordConfirmValidationFunc = useCallback(
			(value?: string | number) => {
				if (value != null && value !== "" && value !== password)
					return { valid: false, message: t("validatableInput.passwordNotMatch") };
				return { valid: true, message: "" };
			},
			[password]
		);

		return (
			<Form className="password-editor-page__body">
				<TitledBlock title={t("account.password")}>
					<ValidatableInput required type="password" value={password} onInput={handleInputPassword} />
					<Form.Group>
						<Form.Label>{t("passwordEditorPage.passwordConfirm")}</Form.Label>
						<ValidatableInput
							required
							type="password"
							value={passwordConfirm}
							validationFunc={passawordConfirmValidationFunc}
							onInput={setPAsswordConfirm}
						/>
					</Form.Group>
				</TitledBlock>

				<ButtonsBlock wide>
					<Button disabled={progressState === "progress"} variant="outline-primary" onClick={handleCancel}>
						{t("passwordEditorPage.cancel")}
					</Button>
					<Button disabled={disabled || progressState === "progress"} onClick={handleSubmit}>
						{t("passwordEditorPage.submit")}
					</Button>
				</ButtonsBlock>
			</Form>
		);
	}
);
