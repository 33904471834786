import { ContentsStateViewer } from "@bleu/utility-components";
import { ContentsState } from "@lu/request";
import React, { FunctionComponent, useCallback } from "react";

import { GetJobListResponse } from "../../../../../../../server/types/request/job";

import { JobsTableBody } from "./Body";

type Props = {
	jobs: ContentsState<GetJobListResponse>;
	type: "create" | "member";
	onGet: (type: "create" | "member", page: number) => void;
};

export const JobsTable: FunctionComponent<Props> = React.memo(({ jobs, type, onGet }) => {
	const handleGet = useCallback((page: number) => onGet(type, page), [type, onGet]);

	return (
		<>
			{jobs.state === "loaded" && <JobsTableBody jobs={jobs.value} onGet={handleGet} />}
			<ContentsStateViewer values={{ jobs }} />
		</>
	);
});
