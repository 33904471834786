import { ButtonsBlock, ContentsStateViewer } from "@bleu/utility-components";
import { Key } from "@lu/repository";
import { ContentsState } from "@lu/request";
import React, { FunctionComponent, useMemo } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Job } from "../../../../../../server/repositories/job/model";
import { GetAggregateJobListResponse } from "../../../../../../server/types/request/aggregate";
import { aggregatesEndpoint, endpoint, jobsEndpoint } from "../../../../routes/endpoints";
import Page from "../../../parts/Page";

import { AggregatesPageBody } from "./Body";

type Props = {
	aggregates: ContentsState<GetAggregateJobListResponse>;
	job: ContentsState<Job>;
	jobId: Key;
	onGet: (page: number) => void;
};

export const AggregatesPage: FunctionComponent<Props> = React.memo(({ aggregates, job, jobId, onGet }) => {
	const [t] = useTranslation();

	const pageTitle = useMemo(
		() => `${t("pageTitle.aggregates")} - ${job.state === "loaded" ? job.value.title : ""}`,
		[job]
	);

	const paths = useMemo(
		() => [
			{ label: t("pageTitle.jobs"), to: endpoint.jobs },
			{
				label: (job.state === "loaded" ? job.value.title : undefined) ?? t("pageTitle.jobDetails"),
				to: `${endpoint.jobs}/${jobId}`,
			},
			{ label: t("pageTitle.aggregates"), to: `${endpoint.jobs}/${jobId}/${jobsEndpoint.aggregates}` },
		],
		[job, jobId]
	);

	return (
		<Page className="aggregates-page">
			<Page.Header paths={paths} title={pageTitle}>
				<ButtonsBlock align="right" wide>
					<Button
						as={Link as any}
						to={`${endpoint.jobs}/${jobId}/${jobsEndpoint.aggregates}/${aggregatesEndpoint.create}`}
					>
						<i className="bi-plus-lg" />
						<span>{t("aggregatesPage.create")}</span>
					</Button>
				</ButtonsBlock>
			</Page.Header>
			<Page.Body>
				{aggregates.state === "loaded" && <AggregatesPageBody aggregates={aggregates.value} onGet={onGet} />}
				<ContentsStateViewer values={{ aggregates }} />
			</Page.Body>
		</Page>
	);
});
