import { PagingTable } from "@bleu/utility-components";
import React, { FunctionComponent, useCallback, useMemo } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { GetAggregateJobListResponse } from "../../../../../../server/types/request/aggregate";
import { aggregatesEndpoint, endpoint, jobsEndpoint } from "../../../../routes/endpoints";
import { DropdownToggle } from "../../../parts/DropdownToggle";

export const AggregatesPageBody: FunctionComponent<{
	aggregates: GetAggregateJobListResponse;
	onGet: (page: number) => void;
}> = React.memo(({ aggregates, onGet }) => {
	const { page, pages, rows } = aggregates;

	const [t] = useTranslation();

	const navigate = useNavigate();

	const header = useMemo(
		() => [
			{ label: t("aggregateJob.name"), value: "name" },
			{ label: t("aggregateJob.importType"), value: "importType" },
			{ label: t("aggregateJob.uploadDateTime"), value: "uploadDateTime" },
			{ label: t("aggregateJob.createdAt"), value: "createdAt" },
			{ label: "", value: "dropdown" },
		],
		[]
	);

	const items = useMemo(
		() =>
			rows.map(({ _id, createdAt, importType, jobId, uploadDateTime, ...row }) => {
				const menu = (
					<>
						<Dropdown.Item as={Link} to={`${endpoint.jobs}/${jobId}/${jobsEndpoint.aggregates}/${_id}`}>
							{t("aggregatesPage.open")}
						</Dropdown.Item>
						<Dropdown.Item
							as={Link}
							to={`${endpoint.jobs}/${jobId}/${jobsEndpoint.aggregates}/${_id}/${aggregatesEndpoint.edit}`}
						>
							{t("aggregatesPage.edit")}
						</Dropdown.Item>
						{importType === "manual" ? (
							<Dropdown.Item
								as={Link}
								to={`${endpoint.jobs}/${jobId}/${jobsEndpoint.aggregates}/${_id}/${aggregatesEndpoint.uploadRawdata}`}
							>
								{t("aggregatesPage.uploadRawdata")}
							</Dropdown.Item>
						) : (
							<Dropdown.Item
								as={Link}
								to={`${endpoint.jobs}/${jobId}/${jobsEndpoint.aggregates}/${_id}/${aggregatesEndpoint.syncRawdata}`}
							>
								{t("aggregatesPage.syncRawdata")}
							</Dropdown.Item>
						)}
						<Dropdown.Divider />
						<Dropdown.Item
							as={Link}
							to={`${endpoint.jobs}/${jobId}/${jobsEndpoint.aggregates}/${_id}/${aggregatesEndpoint.cross}`}
						>
							{t("aggregatesPage.cross")}
						</Dropdown.Item>
						<Dropdown.Item
							as={Link}
							to={`${endpoint.jobs}/${jobId}/${jobsEndpoint.aggregates}/${_id}/${aggregatesEndpoint.gt}`}
						>
							{t("aggregatesPage.gt")}
						</Dropdown.Item>
					</>
				);

				return {
					...row,
					createdAt: new Date(createdAt).toLocaleString(),
					contextMenu: menu,
					dropdown: (
						<Dropdown
							align="end"
							onClick={(event) => {
								event.stopPropagation();
							}}
						>
							<DropdownToggle variant="white">
								<i className="bi-three-dots-vertical" />
							</DropdownToggle>
							<Dropdown.Menu>{menu}</Dropdown.Menu>
						</Dropdown>
					),
					importType: t(`importType.${importType}`),
					uploadDateTime:
						uploadDateTime != null && uploadDateTime !== "" ? new Date(uploadDateTime).toLocaleString() : "",
				};
			}),
		[rows]
	);

	const handleClick = useCallback(
		(index: number) => {
			const { _id, jobId } = rows[index];
			navigate(`${endpoint.jobs}/${jobId}/${jobsEndpoint.aggregates}/${_id}`);
		},
		[rows]
	);

	return (
		<PagingTable
			className="aggregates-page__table"
			id="aggregates-table"
			header={header}
			items={items}
			maxPage={pages}
			page={page}
			onClickItem={handleClick}
			onGet={onGet}
		>
			<colgroup className="aggregates-page__table__colgroup">
				<col className="aggregates-page__table__colgroup__name" />
				<col className="aggregates-page__table__colgroup__import-type" />
				<col className="aggregates-page__table__colgroup__upload-datetime" />
				<col className="aggregates-page__table__colgroup__created-at" />
				<col className="aggregates-page__table__colgroup__dropdown" />
			</colgroup>
		</PagingTable>
	);
});
