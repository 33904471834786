import { GTViewer } from "@bleu-analytics/viewer";
import { ButtonsBlock, ContentsStateViewer } from "@bleu/utility-components";
import { GTData } from "@lu/muscat-analytics-library";
import { Key } from "@lu/repository";
import { ContentsState } from "@lu/request";
import React, { FunctionComponent, useMemo } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { AggregateJob } from "../../../../../../server/repositories/aggregate-job/model";
import { Job } from "../../../../../../server/repositories/job/model";
import { apiEndpoint } from "../../../../../../server/router/api/endpoint";
import { aggregatesEndpoint, endpoint, jobsEndpoint } from "../../../../routes/endpoints";
import Page from "../../../parts/Page";

type Props = {
	aggregateJob: ContentsState<AggregateJob>;
	aggregateJobId: Key;
	gtDatas: ContentsState<GTData[]>;
	job: ContentsState<Job>;
	jobId: Key;
};

export const GTViewerPage: FunctionComponent<Props> = React.memo(
	({ aggregateJob, aggregateJobId, gtDatas, job, jobId }) => {
		const [t] = useTranslation();

		const paths = useMemo(
			() => [
				{ label: t("pageTitle.jobs"), to: endpoint.jobs },
				{
					label: (job.state === "loaded" ? job.value.title : undefined) ?? t("pageTitle.jobDetails"),
					to: `${endpoint.jobs}/${jobId}`,
				},
				{ label: t("pageTitle.aggregates"), to: `${endpoint.jobs}/${jobId}/${jobsEndpoint.aggregates}` },
				{
					label:
						(aggregateJob.state === "loaded" ? aggregateJob.value.name : undefined) ??
						t("pageTitle.aggregateJobDetails"),
					to: `${endpoint.jobs}/${jobId}/${jobsEndpoint.aggregates}/${aggregateJobId}`,
				},
				{
					label: t("pageTitle.gt"),
					to: `${endpoint.jobs}/${jobId}/${jobsEndpoint.aggregates}/${aggregateJobId}/${aggregatesEndpoint.gt}`,
				},
			],
			[aggregateJob, aggregateJobId, job, jobId]
		);

		return (
			<Page className="gt-viewer-page">
				<Page.Header className="gt-viewer-page__header" paths={paths} title={t("pageTitle.gt")}>
					<ButtonsBlock align="right" wide>
						<Dropdown>
							<Dropdown.Toggle>
								<i className="bi-download" />
								<span>{t("gtViewerPage.downloads")}</span>
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item as="a" download href={`${apiEndpoint.downloadAggregateGT}/${aggregateJobId}?type=both`}>
									{t("gtViewerPage.both")}
								</Dropdown.Item>
								<Dropdown.Item as="a" download href={`${apiEndpoint.downloadAggregateGT}/${aggregateJobId}?type=n`}>
									{t("gtViewerPage.n")}
								</Dropdown.Item>
								<Dropdown.Item
									as="a"
									download
									href={`${apiEndpoint.downloadAggregateGT}/${aggregateJobId}?type=percent`}
								>
									{t("gtViewerPage.percent")}
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</ButtonsBlock>
				</Page.Header>
				<Page.Body className="gt-viewer-page__body">
					{gtDatas.state === "loaded" && (
						<GTViewer defaultViewType="both" values={gtDatas.value} defaultVertical={true} />
					)}
					<ContentsStateViewer values={{ gtDatas }} />
				</Page.Body>
			</Page>
		);
	}
);
