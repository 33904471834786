import { ValidatableSelect } from "@bleu/utility-components";
import { Key } from "@lu/repository";
import React, { FunctionComponent, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { AccountWithoutPassword } from "../../../../../../server/repositories/account/model";
import { Job } from "../../../../types/job";

import { FormGroup } from "./FormGroup";

type Props = {
	accounts: AccountWithoutPassword[];
	job: Job;
	onChangeJob: (job: Job) => void;
};

export const EditorChildren: FunctionComponent<Props> = React.memo(({ accounts, job, onChangeJob }) => {
	const [t] = useTranslation();

	const { createAccountId, memberAccountIds } = job;

	const accountMap = useMemo(() => new Map(accounts.map(({ _id, name }) => [_id, name])), [accounts]);

	const accountOptions = useMemo(
		() => accounts.map(({ _id, name }) => ({ label: name, value: _id.toString() })),
		[accounts]
	);

	const memberAccountValues = useMemo(
		() => memberAccountIds.map((accountId) => accountId.toString()),
		[memberAccountIds]
	);

	const handleChangeMemberAccounts = useCallback(
		(memberAccountIds: Key[]) =>
			onChangeJob({
				...job,
				memberAccountIds,
			}),
		[job, onChangeJob]
	);

	return (
		<>
			<FormGroup label={t("job.createAccount")}>
				<div>{accountMap.get(createAccountId)}</div>
			</FormGroup>
			<FormGroup label={t("job.memberAccounts")}>
				<ValidatableSelect
					multiple
					options={accountOptions}
					value={memberAccountValues}
					isFunc={Array.isArray}
					onChange={handleChangeMemberAccounts}
				/>
			</FormGroup>
		</>
	);
});
