import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";

import { ErrorPage } from "../components/pages/error";
import { AccountDetailsContainer } from "../containers/accounts/Details";
import { AccountEditorContainer } from "../containers/accounts/Editor";
import { PasswordContainer } from "../containers/accounts/Password";
import { AccountsContainer } from "../containers/accounts/Root";
import { CheckAuthority } from "../containers/checkAuthority";
import { useAccount } from "../lib/customHooks/useAccount";

import { accountsEndpoint } from "./endpoints";
import { MyAccount } from "./myAccount";

export const Accounts: FunctionComponent = () => {
	const [t] = useTranslation();

	const account = useAccount();

	return (
		<Routes>
			<Route path="/" element={<AccountsContainer />} />
			<Route path={`${account._id}/*`} element={<MyAccount />} />
			<Route
				path={`${accountsEndpoint.create}`}
				element={
					<CheckAuthority requiredRole="organizationAdmin">
						<AccountEditorContainer type="create" />
					</CheckAuthority>
				}
			/>
			<Route path=":accountId" element={<AccountDetailsContainer />} />
			<Route
				path={`:accountId/${accountsEndpoint.edit}`}
				element={
					<CheckAuthority requiredRole="organizationAdmin">
						<AccountEditorContainer type="edit" />
					</CheckAuthority>
				}
			/>
			<Route
				path={`:accountId/${accountsEndpoint.password}`}
				element={
					<CheckAuthority requiredRole="organizationAdmin">
						<PasswordContainer />
					</CheckAuthority>
				}
			/>
			<Route path="*" element={<ErrorPage text={t("errorPage.notFound")} title={t("pageTitle.notFound")} />} />
		</Routes>
	);
};
