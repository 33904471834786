import { Action } from "./action";
import { State } from "./state";

export const reducer = (state: State, action: Action): State => {
	switch (action.type) {
		case "accounts":
			return { ...state, accounts: action.payload };
		case "job":
			if (state.jobData.state !== "loaded") return state;
			return {
				...state,
				jobData: {
					...state.jobData,
					value: {
						...state.jobData.value,
						job: action.payload,
					},
				},
			};
		case "jobData":
			return {
				...state,
				jobData: action.payload,
				progressState: "done",
			};
		case "progressState":
			return {
				...state,
				progressState: action.payload,
			};
	}
};
