import { makeError } from "@bleu/core";
import React, { FunctionComponent, useCallback, useEffect, useMemo, useReducer } from "react";
import { useTranslation } from "react-i18next";

import { Role } from "../../../../../server/lib/permission/role";
import { apiEndpoint } from "../../../../../server/router/api/endpoint";
import { GetAccountListResponse, SearchAccountRequest } from "../../../../../server/types/request/account";
import { GetOrganizationResponse } from "../../../../../server/types/request/organization";
import { AccountsPage } from "../../../components/pages/accounts/Root";
import { SearchParams } from "../../../components/parts/SearchField";
import { useAccount } from "../../../lib/customHooks/useAccount";
import { useSearchParams } from "../../../lib/customHooks/useSearchParams";
import { get, search } from "../../../lib/request";
import { parseAccountStatuses, parseString, parseSystemRoles } from "../../../utils/parsers";

import { reducer } from "./reducer";
import { initState } from "./state";

const parseSearchParams = (searchParams: SearchParams): SearchAccountRequest => {
	return {
		email: parseString(searchParams.email),
		inactive: parseAccountStatuses(searchParams.status),
		name: parseString(searchParams.name),
		organizationId: parseString(searchParams.organizationId),
		role: parseSystemRoles(searchParams.role),
	};
};

export const AccountsContainer: FunctionComponent = React.memo(() => {
	const [t] = useTranslation();

	const account = useAccount();

	const [searchParams, setSearchParams] = useSearchParams("accounts");

	const [state, dispatch] = useReducer(reducer, searchParams, initState);

	const isAdmin = useMemo(() => Role.isAdmin(account), [account]);

	useEffect(() => {
		document.title = `${t("pageTitle.accounts")} - ${t("pageTitle.siteName")}`;
	}, []);

	const handleGet = useCallback(
		(page: number) => {
			search<SearchAccountRequest, GetAccountListResponse>(
				apiEndpoint.searchAccount,
				{ page, limit: 10 },
				state.searchParams
			)
				.then((res) => {
					dispatch({ type: "accounts", payload: { state: "loaded", value: res.data } });
				})
				.catch((error) => {
					dispatch({ type: "accounts", payload: { state: "failed", error: makeError(error) } });
				});
		},
		[state.searchParams]
	);

	useEffect(() => handleGet(0), [handleGet]);

	useEffect(() => {
		if (!isAdmin) return dispatch({ type: "organizations", payload: { state: "loaded", value: [] } });

		get<GetOrganizationResponse>(apiEndpoint.organization)
			.then((res) => {
				dispatch({ type: "organizations", payload: { state: "loaded", value: res.data.organizations } });
			})
			.catch((error) => {
				dispatch({ type: "organizations", payload: { state: "failed", error: makeError(error) } });
			});
	}, [isAdmin]);

	const handleSearch = useCallback(
		(searchParams: SearchParams) => {
			dispatch({ type: "searchParams", payload: parseSearchParams(searchParams) });

			setSearchParams(searchParams);
		},
		[setSearchParams]
	);

	return <AccountsPage onGet={handleGet} onSearch={handleSearch} {...state} />;
});
