import { TitledBlock } from "@bleu/utility-components";
import { ContentsState } from "@lu/request";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { GetAccountListResponse } from "../../../../../../server/types/request/account";

import { AccountsTable } from "./AccountsTable";

type Props = {
	accounts: ContentsState<GetAccountListResponse>;
	onGetAccounts: (page: number) => void;
};

export const OrganizationDetailsBody: FunctionComponent<Props> = React.memo(({ accounts, onGetAccounts }) => {
	const [t] = useTranslation();

	return (
		<div className="organization-details-page__body">
			<TitledBlock title={t("organizationDetailsPage.members")}>
				<AccountsTable accounts={accounts} onGet={onGetAccounts} />
			</TitledBlock>
		</div>
	);
});
