import { CrossViewer } from "@bleu-analytics/viewer";
import { ButtonsBlock, ContentsStateViewer } from "@bleu/utility-components";
import { CrossData } from "@lu/muscat-analytics-library";
import { Key } from "@lu/repository";
import { ContentsState } from "@lu/request";
import React, { FunctionComponent, useMemo, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { AggregateJob } from "../../../../../../server/repositories/aggregate-job/model";
import { Job } from "../../../../../../server/repositories/job/model";
import { apiEndpoint } from "../../../../../../server/router/api/endpoint";
import { aggregatesEndpoint, endpoint, jobsEndpoint } from "../../../../routes/endpoints";
import Page from "../../../parts/Page";

type Props = {
	aggregateJob: ContentsState<AggregateJob>;
	aggregateJobId: Key;
	crossDatas: ContentsState<CrossData[]>;
	job: ContentsState<Job>;
	jobId: Key;
};

export const CrossViewerPage: FunctionComponent<Props> = React.memo(
	({ aggregateJob, aggregateJobId, crossDatas, job, jobId }) => {
		const [t] = useTranslation();

		const [vertical, setVertical] = useState(true);

		const paths = useMemo(
			() => [
				{ label: t("pageTitle.jobs"), to: endpoint.jobs },
				{
					label: (job.state === "loaded" ? job.value.title : undefined) ?? t("pageTitle.jobDetails"),
					to: `${endpoint.jobs}/${jobId}`,
				},
				{ label: t("pageTitle.aggregates"), to: `${endpoint.jobs}/${jobId}/${jobsEndpoint.aggregates}` },
				{
					label:
						(aggregateJob.state === "loaded" ? aggregateJob.value.name : undefined) ??
						t("pageTitle.aggregateJobDetails"),
					to: `${endpoint.jobs}/${jobId}/${jobsEndpoint.aggregates}/${aggregateJobId}`,
				},
				{
					label: t("pageTitle.cross"),
					to: `${endpoint.jobs}/${jobId}/${jobsEndpoint.aggregates}/${aggregateJobId}/${aggregatesEndpoint.cross}`,
				},
			],
			[aggregateJob, aggregateJobId, job, jobId]
		);

		return (
			<Page className="cross-viewer-page">
				<Page.Header className="cross-viewer-page__header" paths={paths} title={t("pageTitle.cross")}>
					<ButtonsBlock align="right" wide>
						<Dropdown>
							<Dropdown.Toggle>
								<i className="bi-download" />
								<span>{t("crossViewerPage.downloads")}</span>
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item
									as="a"
									download
									href={`${apiEndpoint.downloadAggregateCross}/${aggregateJobId}?type=both&axisDirection=${
										vertical ? "vertical" : "horizontal"
									}`}
								>
									{t("crossViewerPage.both")}
								</Dropdown.Item>
								<Dropdown.Item
									as="a"
									download
									href={`${apiEndpoint.downloadAggregateCross}/${aggregateJobId}?type=n&axisDirection=${
										vertical ? "vertical" : "horizontal"
									}`}
								>
									{t("crossViewerPage.n")}
								</Dropdown.Item>
								<Dropdown.Item
									as="a"
									download
									href={`${apiEndpoint.downloadAggregateCross}/${aggregateJobId}?type=percent&axisDirection=${
										vertical ? "vertical" : "horizontal"
									}`}
								>
									{t("crossViewerPage.percent")}
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</ButtonsBlock>
				</Page.Header>
				<Page.Body className="cross-viewer-page__body">
					{crossDatas.state === "loaded" && (
						<CrossViewer
							axisType="separate"
							defaultVertical={true}
							defaultViewType="both"
							values={crossDatas.value}
							onChangeVertical={setVertical}
						/>
					)}
					<ContentsStateViewer values={{ crossDatas }} />
				</Page.Body>
			</Page>
		);
	}
);
