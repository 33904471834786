import { Fluid } from "../types/fluid";

export const getFluid = (width: number): Fluid => {
	if (width < 576) {
		return "xs";
	} else if (width < 768) {
		return "sm";
	} else if (width < 992) {
		return "md";
	} else if (width < 1200) {
		return "lg";
	} else {
		return "xl";
	}
};

export const mobile = (fluid: Fluid): boolean => {
	switch (fluid) {
		case "xs":
		case "sm":
		case "md":
			return true;
		default:
			return false;
	}
};
