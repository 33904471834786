import { ContentsState, Error, ProgressState } from "@lu/request";

import { Account } from "../../../../../server/repositories/account/model";

export type State = {
	account: ContentsState<Partial<Account>>;
	error?: Error;
	progressState: ProgressState;
};

export const initState: State = {
	account: { state: "loading" },
	progressState: "none",
};
