import { Action } from "./action";
import { State } from "./state";

export const reducer = (state: State, action: Action): State => {
	switch (action.type) {
		case "accounts":
			return { ...state, accounts: action.payload };
		case "error":
			return {
				...state,
				error: action.payload,
				progressState: "done",
			};
		case "jobs":
			return { ...state, jobs: action.payload };
		case "progressState":
			return { ...state, error: undefined, progressState: action.payload };
		case "searchParams":
			return {
				...state,
				searchParams: action.payload,
			};
		case "targetIndex":
			return {
				...state,
				targetIndex: action.payload,
			};
	}
};
