import { ButtonsBlock, ContentsStateViewer } from "@bleu/utility-components";
import { ContentsState } from "@lu/request";
import React, { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Organization } from "../../../../../../server/repositories/organization/model";
import { GetAccountListResponse } from "../../../../../../server/types/request/account";
import { endpoint, organizationsEndpoint } from "../../../../routes/endpoints";
import { EditButton } from "../../../parts/buttons";
import Page from "../../../parts/Page";

import { OrganizationDetailsBody } from "./Body";

type Props = {
	accounts: ContentsState<GetAccountListResponse>;
	organization: ContentsState<Organization>;
	organizationId?: string;
	onGetAccounts: (page: number) => void;
};

export const OrganizationDetailsPage: FunctionComponent<Props> = React.memo(
	({ accounts, organization, organizationId, onGetAccounts }) => {
		const [t] = useTranslation();

		const paths = useMemo(
			() => [
				{ label: t("pageTitle.organizations"), to: endpoint.organizations },
				{
					label: organization.state === "loaded" ? organization.value.name : t("pageTitle.organizationDetails"),
					to: `${endpoint.organizations}/${organizationId}`,
				},
			],
			[organization, organizationId]
		);

		const titleElement = useMemo(() => {
			if (organization.state === "loaded") {
				return (
					<div className="organization-details-page__title">
						<h4 className="organization-details-page__title__main">{organization.value.name}</h4>
						<div className="organization-details-page__title__sub">{organization.value.code}</div>
					</div>
				);
			} else {
				return t("pageTitle.organizationDetails");
			}
		}, [organization]);

		return (
			<Page className="organization-details-page">
				<Page.Header paths={paths} title={titleElement}>
					<ButtonsBlock align="right" wide>
						<EditButton as={Link} to={`${endpoint.organizations}/${organizationId}/${organizationsEndpoint.edit}`}>
							{t("organizationDetailsPage.edit")}
						</EditButton>
					</ButtonsBlock>
				</Page.Header>
				<Page.Body>
					{organization.state === "loaded" && (
						<OrganizationDetailsBody accounts={accounts} onGetAccounts={onGetAccounts} />
					)}
					<ContentsStateViewer values={{ organization }} />
				</Page.Body>
			</Page>
		);
	}
);
