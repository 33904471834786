import { Action } from "./action";
import { State } from "./state";

export const reducer = (state: State, action: Action): State => {
	switch (action.type) {
		case "changeLoading":
			return {
				...state,
				loading: action.payload,
			};
		case "error":
			return {
				...state,
				error: action.payload,
				loading: false,
			};
		case "update":
			return {
				...state,
				[action.payload.name]: action.payload.value,
			};
	}
};
