import { Account } from "../../../../../../server/repositories/account/model";

import { UpdateAction } from "./updateAction";

export const update = (account: Partial<Account>, action: UpdateAction): Partial<Account> => {
	switch (action.type) {
		case "email":
			return { ...account, email: action.payload };
		case "inactive":
			return { ...account, inactive: action.payload };
		case "name":
			return { ...account, name: action.payload };
		case "organization":
			return { ...account, organizationId: action.payload };
		case "password":
			return { ...account, password: action.payload };
		case "role":
			return { ...account, role: action.payload };
	}
};
