import { Quota } from "@lu/muscat-library/dist/models/quota";
import React, { FunctionComponent } from "react";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";

type Props = {
	quotas: Quota[];
};

export const QuotasTable: FunctionComponent<Props> = React.memo(({ quotas }) => {
	const { t } = useTranslation();

	return (
		<div className="quotas-table">
			<Table className="quotas-table__table">
				<thead>
					<tr>
						<th>{t("quota.code")}</th>
						<th>{t("quota.name")}</th>
						<th>{t("quota.plan")}</th>
						<th>{t("quota.limit")}</th>
						<th>{t("quota.count")}</th>
					</tr>
				</thead>
				<tbody>
					{quotas.map((quota, i) => (
						<tr key={i}>
							<td>{quota.code}</td>
							<td>{quota.name}</td>
							<td>{quota.plan.toLocaleString()}</td>
							<td>{quota.limit.toLocaleString()}</td>
							<td>{quota.count.toLocaleString()}</td>
						</tr>
					))}
				</tbody>
			</Table>
		</div>
	);
});
