import classNames from "classnames";
import React, { CSSProperties, FunctionComponent } from "react";

type Props = {
	className?: string;
	style?: CSSProperties;
};

export const Body: FunctionComponent<Props> = ({ children, className, style }) => {
	return (
		<div className={classNames("page__body", className)} style={style}>
			{children}
		</div>
	);
};
