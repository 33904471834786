import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";

import { ErrorPage } from "../components/pages/error";
import { JobDetailsContainer } from "../containers/jobs/Details";
import { JobEditorContainer } from "../containers/jobs/Editor";
import { JobsContainer } from "../containers/jobs/Root";

import { Aggregates } from "./aggregates";
import { jobsEndpoint } from "./endpoints";

export const Jobs: FunctionComponent = () => {
	const [t] = useTranslation();
	return (
		<Routes>
			<Route path="/" element={<JobsContainer />} />
			<Route path={jobsEndpoint.create} element={<JobEditorContainer type="create" />} />
			<Route path=":jobId" element={<JobDetailsContainer />} />
			<Route path={`:jobId/${jobsEndpoint.edit}`} element={<JobEditorContainer type="edit" />} />
			<Route path={`:jobId/${jobsEndpoint.aggregates}/*`} element={<Aggregates />} />
			<Route path="*" element={<ErrorPage text={t("errorPage.notFound")} title={t("pageTitle.notFound")} />} />
		</Routes>
	);
};
