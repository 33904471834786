import { ContentsState } from "@lu/request";

import { Job } from "../../../../../server/repositories/job/model";
import { GetAggregateJobListResponse } from "../../../../../server/types/request/aggregate";

export type State = {
	aggregates: ContentsState<GetAggregateJobListResponse>;
	job: ContentsState<Job>;
};

export const initState: State = {
	aggregates: { state: "loading" },
	job: { state: "loading" },
};
