import { ButtonsBlock, ContentsStateViewer } from "@bleu/utility-components";
import { ContentsState } from "@lu/request";
import React, { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { GetOrganizationListResponse } from "../../../../../../server/types/request/organization";
import { endpoint, organizationsEndpoint } from "../../../../routes/endpoints";
import { AddButton } from "../../../parts/buttons";
import Page from "../../../parts/Page";
import { ItemProps, SearchField, SearchParams } from "../../../parts/SearchField";

import { OrganizationsPageBody } from "./Body";

type Props = {
	organizations: ContentsState<GetOrganizationListResponse>;
	searchParams: SearchParams;
	onGet: (page: number) => void;
	onSearch: (searchParams: SearchParams) => void;
};

export const OrganizationsPage: FunctionComponent<Props> = React.memo(
	({ organizations, searchParams, onGet, onSearch }) => {
		const [t] = useTranslation();

		const searchItems: ItemProps[] = useMemo(
			() => [
				{ _key: "name", label: t("organization.name"), type: "text" },
				{ _key: "code", label: t("organization.code"), type: "text" },
			],
			[]
		);

		return (
			<Page className="organizations-page">
				<Page.Header title={t("pageTitle.organizations")}>
					<ButtonsBlock align="right" wide>
						<AddButton as={Link} to={`${endpoint.organizations}/${organizationsEndpoint.create}`}>
							{t("organizationsPage.addOrganization")}
						</AddButton>
					</ButtonsBlock>
				</Page.Header>
				<Page.Body>
					<SearchField defaultSearchOptions={searchParams} items={searchItems} onSearch={onSearch} />
					{organizations.state === "loaded" && (
						<OrganizationsPageBody organizations={organizations.value} onGet={onGet} />
					)}
					<ContentsStateViewer values={{ organizations }} />
				</Page.Body>
			</Page>
		);
	}
);
