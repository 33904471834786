import { ContentsState } from "@lu/request";

import { AccountWithoutPassword } from "../../../server/repositories/account/model";
import { Fluid } from "../types/fluid";

export type State = {
	account: ContentsState<AccountWithoutPassword>;
	drawerOpen: boolean;
	fluid?: Fluid;
};

export const initState: State = {
	account: { state: "loading" },
	drawerOpen: false,
};
