import { makeError } from "@bleu/core";
import React, { FunctionComponent, useCallback, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import { apiEndpoint } from "../../../../../server/router/api/endpoint";
import { GetAccountListResponse, SearchAccountRequest } from "../../../../../server/types/request/account";
import { GetOneOrganizationResponse } from "../../../../../server/types/request/organization";
import { OrganizationDetailsPage } from "../../../components/pages/Organizations/Details";
import { get, search } from "../../../lib/request";

import { reducer } from "./reducer";
import { initState } from "./state";

export const OrganizationDetailsContainer: FunctionComponent = React.memo(() => {
	const [t] = useTranslation();

	const { organizationId } = useParams<"organizationId">();

	const [state, dispatch] = useReducer(reducer, initState);

	const handleGetAccounts = useCallback(
		(page: number) => {
			search<SearchAccountRequest, GetAccountListResponse>(
				apiEndpoint.searchAccount,
				{ page, limit: 10 },
				{ organizationId }
			)
				.then((res) => {
					dispatch({ type: "accounts", payload: { state: "loaded", value: res.data } });
				})
				.catch((error) => {
					dispatch({ type: "accounts", payload: { state: "failed", error: makeError(error) } });
				});
		},
		[organizationId]
	);

	useEffect(() => handleGetAccounts(0), [handleGetAccounts]);

	useEffect(() => {
		document.title = `${t("pageTitle.organizationDetails")} - ${t("pageTitle.siteName")}`;
	}, []);

	useEffect(() => {
		if (organizationId === undefined) return;

		get<GetOneOrganizationResponse>(`${apiEndpoint.organization}/${organizationId}`)
			.then((res) => {
				dispatch({ type: "organization", payload: { state: "loaded", value: res.data.organization } });
			})
			.catch((error) => {
				dispatch({ type: "organization", payload: { state: "failed", error: makeError(error) } });
			});
	}, [organizationId]);

	useEffect(() => {
		document.title = `${
			state.organization.state !== "loaded" ? t("pageTitle.organizationDetails") : state.organization.value.name
		} - ${t("pageTitle.siteName")}`;
	}, [state.organization]);

	return <OrganizationDetailsPage organizationId={organizationId} onGetAccounts={handleGetAccounts} {...state} />;
});
