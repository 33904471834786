import React, { ChangeEvent, FunctionComponent, useCallback, useState } from "react";
import Form from "react-bootstrap/Form";

type Props = {
	isInvalid?: boolean;
	readOnly?: boolean;
	name?: string;
	toggle?: boolean;
	value?: string;
	onInput?: (value: string, event: ChangeEvent<HTMLInputElement>) => void;
};

export const PasswordInput: FunctionComponent<Props> = React.memo(
	({ isInvalid, readOnly, name, toggle = false, value, onInput }) => {
		const [show, setShow] = useState(false);

		const handleClick = useCallback(() => setShow(!show), [show]);

		const handleInput = useCallback(
			(event: ChangeEvent<HTMLInputElement>) => onInput?.(event.target.value, event),
			[onInput]
		);

		const handleMouseDown = useCallback(() => setShow(true), []);

		const handleMouseUp = useCallback(() => setShow(false), []);

		return (
			<div className="password-input">
				<Form.Control
					className="password-input__control"
					isInvalid={isInvalid}
					name={name}
					readOnly={readOnly}
					type={show ? "text" : "password"}
					value={value}
					onInput={handleInput}
				/>

				<div
					className="password-input__button"
					onClick={toggle ? handleClick : undefined}
					onMouseDown={!toggle ? handleMouseDown : undefined}
					onMouseUp={!toggle ? handleMouseUp : undefined}
				>
					{<i className={`bi-eye-${show ? "slash-" : ""}fill`} />}
				</div>
			</div>
		);
	}
);
