import classNames from "classnames";
import React, { FunctionComponent } from "react";
import { Container } from "react-bootstrap";

import { Body } from "./body";
import { Header } from "./header";

type Props = {
	className?: string;
};

const PageElement: FunctionComponent<Props> = React.memo(({ children, className }) => {
	return (
		<Container fluid className={classNames("page", className)}>
			{children}
		</Container>
	);
});

type Page = typeof PageElement & { Body: typeof Body; Header: typeof Header };

// eslint-disable-next-line @typescript-eslint/no-redeclare
const Page: Page = PageElement as Page;

Page.Body = Body;
Page.Header = Header;

export default Page;
