import { EnqueteStatus, enqueteStatus } from "@lu/muscat-library/dist/models/enquete";
import { RangeDateString } from "@lu/repository";

import { SystemRole, systemRole } from "../../../server/repositories/account/model";

export const isEnqueteStatus = (value: unknown): value is EnqueteStatus => {
	return typeof value === "string" && value in enqueteStatus;
};

export const isSystemRole = (value: unknown): value is SystemRole => {
	return typeof value === "string" && value in systemRole;
};

export const parseAccountStatuses = (value: unknown): Array<boolean | undefined> | undefined => {
	if (!Array.isArray(value) || value.length === 0) return undefined;

	return value.map((item) => (item === "active" ? [false, undefined] : [true])).flatMap((item) => item);
};

export const parseArray = <T extends any>(value: unknown, isFunc: (value: unknown) => value is T): T[] | undefined => {
	if (!Array.isArray(value)) return undefined;

	const values = value.filter((value) => isFunc(value));
	return values.length > 0 ? values : undefined;
};

export const parseEnqueteStatuses = (value: unknown): EnqueteStatus[] | undefined => {
	return parseArray(value, isEnqueteStatus);
};

export const parseRangeDate = (value?: unknown): RangeDateString | undefined => {
	if (typeof value !== "object" || value == null) return undefined;

	const since = "since" in value ? new Date(value["since" as keyof typeof value]) : undefined;
	const until = "until" in value ? new Date(value["until" as keyof typeof value]) : undefined;

	if (since == null && until == null) return undefined;

	const from =
		since != null && since instanceof Date
			? (() => {
					since.setMinutes(since.getTimezoneOffset());
					return since.toISOString();
			  })()
			: undefined;

	const to =
		until != null && until instanceof Date
			? (() => {
					until.setDate(until.getDate() + 1);
					until.setMinutes(-1 + until.getTimezoneOffset());
					return until.toISOString();
			  })()
			: undefined;

	return { from, to };
};

export const parseString = (value: unknown): string | undefined => {
	return typeof value === "string" ? value : undefined;
};

export const parseSystemRole = (value: unknown): SystemRole | undefined => {
	return isSystemRole(value) ? value : undefined;
};

export const parseSystemRoles = (value: unknown): SystemRole[] | undefined => {
	return parseArray(value, isSystemRole);
};
