import classNames from "classnames";
import React, { FunctionComponent, ReactNode } from "react";
import { Form } from "react-bootstrap";

type Props = {
	className?: string;
	label: ReactNode;
};

export const FormGroup: FunctionComponent<Props> = React.memo(({ children, className, label }) => {
	return (
		<Form.Group className={classNames("job-editor-page__form-group", className)}>
			<Form.Label className="job-editor-page__form-group__label">{label}</Form.Label>
			{children}
		</Form.Group>
	);
});
