import React, { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useAccount } from "../../../lib/customHooks/useAccount";
import { EditorType } from "../../../types/editorTypes";
import { AccountEditorContainer } from "../../accounts/Editor";

type Props = {
	type: EditorType;
};

export const MyAccountEditorContainer: FunctionComponent<Props> = React.memo(({ type }) => {
	const [t] = useTranslation();

	const { _id } = useAccount();

	useEffect(() => {
		document.title = `${t("pageTitle.editAccount")} - ${t("pageTitle.siteName")}`;
	}, []);

	return <AccountEditorContainer accountId={_id.toString()} type={type} />;
});
