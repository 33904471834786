import { makeError } from "@bleu/core";
import React, { FunctionComponent, useCallback, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import { apiEndpoint } from "../../../../../server/router/api/endpoint";
import { GetAggregateJobListResponse } from "../../../../../server/types/request/aggregate";
import { GetOneJobResponse } from "../../../../../server/types/request/job";
import { AggregatesPage } from "../../../components/pages/aggregates/Root";
import { get } from "../../../lib/request";

import { reducer } from "./reducer";
import { initState } from "./state";

export const AggregatesContainer: FunctionComponent = React.memo(() => {
	const [t] = useTranslation();

	const { jobId } = useParams<"jobId">();

	const [state, dispatch] = useReducer(reducer, initState);

	useEffect(() => {
		document.title = `${t("pageTitle.aggregates")} - ${t("pageTitle.siteName")}`;
	}, []);

	useEffect(() => {
		get<GetOneJobResponse>(`${apiEndpoint.job}/${jobId}`)
			.then((res) => {
				dispatch({ type: "job", payload: { state: "loaded", value: res.data.job } });
			})
			.catch((error) => {
				dispatch({ type: "job", payload: { state: "failed", error: makeError(error) } });
			});
	}, [jobId]);

	const handleGet = useCallback(
		(page: number) => {
			get<GetAggregateJobListResponse>(`${apiEndpoint.aggregateJobs}/${jobId}`, { page, limit: 10 })
				.then((res) => {
					dispatch({ type: "aggregates", payload: { state: "loaded", value: res.data } });
				})
				.catch((error) => {
					dispatch({ type: "aggregates", payload: { state: "failed", error: makeError(error) } });
				});
		},
		[jobId]
	);

	useEffect(() => handleGet(0), [handleGet]);

	return <AggregatesPage jobId={jobId} onGet={handleGet} {...state} />;
});
