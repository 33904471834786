import { TooltipButton } from "@bleu/utility-components";
import classNames from "classnames";
import React, { ComponentProps, FunctionComponent } from "react";
import Button from "react-bootstrap/Button";

type Props = Omit<ComponentProps<typeof TooltipButton>, "tooltipText"> & { tooltipText?: string };

export const IconButton: FunctionComponent<Props> = React.memo(({ children, className, tooltipText, ...props }) => {
	return tooltipText != null ? (
		<TooltipButton className={classNames("icon-button", className)} tooltipText={tooltipText} {...props}>
			{children}
		</TooltipButton>
	) : (
		<Button className={classNames("icon-button", className)} {...props}>
			{children}
		</Button>
	);
});
