import { Action } from "./action";
import { State } from "./state";

export const reducer = (state: State, action: Action): State => {
	switch (action.type) {
		case "aggregateJob":
			return {
				...state,
				aggregateJob: action.payload,
			};
		case "gtDatas":
			return {
				...state,
				gtDatas: action.payload,
			};
		case "job":
			return {
				...state,
				job: action.payload,
			};
		case "progress":
			return {
				...state,
				progressState: action.payload,
			};
	}
};
