import { Action } from "./account";
import { State } from "./state";

export const reducer = (state: State, action: Action): State => {
	switch (action.type) {
		case "accounts":
			return {
				...state,
				accounts: action.payload,
			};
		case "organizations":
			return {
				...state,
				organizations: action.payload,
			};
		case "searchParams":
			return {
				...state,
				searchParams: action.payload,
			};
	}
};
