import React, { FunctionComponent, createContext, useCallback, useContext, useReducer } from "react";

import { SearchParams } from "../../components/parts/SearchField";

type Context = { [key in string]: SearchParams };

const reducer = (_: Context, action: { key: string; searchParams: SearchParams }) => {
	return { [action.key]: action.searchParams };
};

const initState: Context = {};

const SearchParamsContext = createContext<[Context, (key: string, searchParam: SearchParams) => void]>(
	null as unknown as [Context, (key: string, searchParam: SearchParams) => void]
);

export const useSearchParams = (type: string): [SearchParams, (searchParam: SearchParams) => void] => {
	const [context, setContext] = useContext(SearchParamsContext);

	return [context[type] ?? {}, setContext.bind(undefined, type)];
};

export const SearchParamsProvider: FunctionComponent = React.memo(({ children }) => {
	const [state, dispatch] = useReducer(reducer, initState);

	const handleSetSearchParams = useCallback(
		(key: string, searchParams: SearchParams) => dispatch({ key, searchParams }),
		[]
	);
	return <SearchParamsContext.Provider value={[state, handleSetSearchParams]}>{children}</SearchParamsContext.Provider>;
});
