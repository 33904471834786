import { ContentsStateViewer } from "@bleu/utility-components";
import { ContentsState } from "@lu/request";
import React, { FunctionComponent, useMemo } from "react";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";

import { AccountWithoutPassword } from "../../../../../../server/repositories/account/model";
import { Organization } from "../../../../../../server/repositories/organization/model";
import { GetJobListResponse } from "../../../../../../server/types/request/job";
import { endpoint } from "../../../../routes/endpoints";
import Page from "../../../parts/Page";
import { PathsContainer } from "../../../parts/PathsContainer";

import { AccountDetailsBody } from "./Body";
import { AccountDetailsHeader } from "./Header";

type Props = {
	account: ContentsState<AccountWithoutPassword>;
	accountId: string;
	createJobs: ContentsState<GetJobListResponse>;
	memberJobs: ContentsState<GetJobListResponse>;
	organization: ContentsState<Organization>;
	onGetJobs: (type: "create" | "member", page: number) => void;
};
export const AccountDetailsPage: FunctionComponent<Props> = React.memo(
	({ account, accountId, organization, onGetJobs, ...props }) => {
		const [t] = useTranslation();

		const paths = useMemo(
			() => [
				{ label: t("pageTitle.accounts"), to: endpoint.accounts },
				{
					label: account.state === "loaded" ? account.value.name : t("pageTitle.accountDetails"),
					to: `${endpoint.accounts}/${accountId}`,
				},
			],
			[accountId, organization]
		);

		return (
			<div className="account-details-page">
				<header className="account-details-page__header-container">
					<Container fluid="lg">
						<PathsContainer paths={paths} />
						<AccountDetailsHeader account={account} accountId={accountId} organization={organization} />
					</Container>
				</header>
				<Page.Body>
					<Container fluid="lg">
						{account.state === "loaded" && <AccountDetailsBody onGet={onGetJobs} {...props} />}
						<ContentsStateViewer values={{ account }} />
					</Container>
				</Page.Body>
			</div>
		);
	}
);
