import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { SystemRole } from "../../../../server/repositories/account/model";
import { ErrorPage } from "../../components/pages/error";
import { useAccount } from "../../lib/customHooks/useAccount";
import { checkAuthority } from "../../utils/auth-checker";

type Props = {
	requiredRole?: SystemRole;
};

export const CheckAuthority: FunctionComponent<Props> = React.memo(({ children, requiredRole }) => {
	const [t] = useTranslation();

	const { role } = useAccount();

	return requiredRole != null || checkAuthority(role, requiredRole) ? (
		<>{children}</>
	) : (
		<ErrorPage text={t("errorPage.notFound")} title={t("pageTitle.notFound")} />
	);
});
