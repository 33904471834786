import { TitledBlock } from "@bleu/utility-components";
import { ContentsState } from "@lu/request";
import classNames from "classnames";
import React, { FunctionComponent, useState } from "react";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { useTranslation } from "react-i18next";

import { GetJobListResponse } from "../../../../../../server/types/request/job";

import { JobsTable } from "./JobsTable";

type Props = {
	createJobs: ContentsState<GetJobListResponse>;
	memberJobs: ContentsState<GetJobListResponse>;
	onGet: (type: "create" | "member", page: number) => void;
};

export const AccountDetailsBody: FunctionComponent<Props> = React.memo(({ createJobs, memberJobs, onGet }) => {
	const [t] = useTranslation();

	const [activeKey, setActiveKey] = useState<null | string>("create");

	return (
		<div className="account-details-page__body">
			<TitledBlock title={t("accountDetailsPage.jobs")}>
				<Tab.Container id="account-jobs" activeKey={activeKey ?? "create"} onSelect={setActiveKey}>
					<Nav className="account-details-page__body__tabs" variant="pills">
						<NavTab active={activeKey === "create"} eventKey="create">
							{t("accountDetailsPage.createJobs")}
						</NavTab>
						<NavTab active={activeKey === "members"} eventKey="members">
							{t("accountDetailsPage.membersJobs")}
						</NavTab>
					</Nav>
					<Tab.Content>
						<Tab.Pane eventKey="create">
							<JobsTable jobs={createJobs} type="create" onGet={onGet} />
						</Tab.Pane>
						<Tab.Pane eventKey="members">
							<JobsTable jobs={memberJobs} type="member" onGet={onGet} />
						</Tab.Pane>
					</Tab.Content>
				</Tab.Container>
			</TitledBlock>
		</div>
	);
});

const NavTab: FunctionComponent<{ active: boolean; eventKey: string }> = React.memo(
	({ children, active, eventKey }) => {
		return (
			<Nav.Item
				className={classNames("account-details-page__body__tab", {
					"account-details-page__body__tab--active": active,
				})}
			>
				<Nav.Link className="account-details-page__body__tab__link" eventKey={eventKey}>
					{children}
				</Nav.Link>
			</Nav.Item>
		);
	}
);
