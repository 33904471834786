import { AggregateTargetQuestion, createAggregateJob } from "@bleu-analytics/core";
import { Key } from "@lu/repository";

import { Account } from "../../../server/repositories/account/model";
import * as model from "../../../server/repositories/job/model";
import { Organization } from "../../../server/repositories/organization/model";
import { EditableAggregateJob } from "../types/aggregateJob";

export const initAccount = (organizationId: Key): Partial<Account> => {
	return {
		email: "",
		name: "",
		organizationId,
		password: "",
	};
};

export const initAggregateJob = (job: model.Job, questions: AggregateTargetQuestion[]): EditableAggregateJob => {
	return createAggregateJob(job, questions, {});
};

export const initJob = (accountId: Key): Partial<model.Job> => {
	return {
		createAccountId: accountId,
		memberAccountIds: [accountId],
	};
};

export const initOrganization = (): Partial<Organization> => {
	return {
		name: "",
		code: "",
	};
};
