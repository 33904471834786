import { GetJobDataResponse, makeError } from "@bleu/core";
import { Key } from "@lu/repository";
import React, { FunctionComponent, useCallback, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { apiEndpoint } from "../../../../../server/router/api/endpoint";
import { GetAccountResponse } from "../../../../../server/types/request/account";
import { GetJobListResponse, SearchJobRequest, UpsertJobResponse } from "../../../../../server/types/request/job";
import { JobsPage } from "../../../components/pages/jobs/Root";
import { SearchParams } from "../../../components/parts/SearchField";
import { useAccount } from "../../../lib/customHooks/useAccount";
import { useSearchParams } from "../../../lib/customHooks/useSearchParams";
import { get, post, search } from "../../../lib/request";
import { endpoint } from "../../../routes/endpoints";
import { Job } from "../../../types/job";
import { parseEnqueteStatuses, parseRangeDate, parseString } from "../../../utils/parsers";

import { reducer } from "./reducer";
import { initState } from "./state";

const parseSearchParams = (searchParams: SearchParams): SearchJobRequest => {
	return {
		_id: parseString(searchParams.id),
		createAccountId: parseString(searchParams.createAccount),
		createdAt: parseRangeDate(searchParams.createdAt),
		enqueteStatus: parseEnqueteStatuses(searchParams.enqueteStatus),
		enqueteTitle: parseString(searchParams.enqueteTitle),
		findate: parseRangeDate(searchParams.finDate),
		startdate: parseRangeDate(searchParams.startDate),
		memberAccountIds: parseString(searchParams.memberAccounts),
	};
};

export const JobsContainer: FunctionComponent = React.memo(() => {
	const [t] = useTranslation();

	const navigate = useNavigate();

	const account = useAccount();

	const [searchParams, setSearchParams] = useSearchParams("jobs");

	const [state, dispatch] = useReducer(reducer, searchParams, initState);

	useEffect(() => {
		document.title = `${t("pageTitle.siteName")}`;
	}, []);

	useEffect(() => {
		// account
		get<GetAccountResponse>(`${apiEndpoint.account}`)
			.then(({ data }) => {
				dispatch({ type: "accounts", payload: { state: "loaded", value: data.accounts } });
			})
			.catch((error) => {
				dispatch({ type: "accounts", payload: { state: "failed", error: makeError(error) } });
			});
	}, []);

	const handleGet = useCallback(
		(page: number) => {
			search<SearchJobRequest, GetJobListResponse>(
				apiEndpoint.searchJob,
				{ page, limit: 10 },
				parseSearchParams(state.searchParams)
			)
				.then(({ data }) => {
					dispatch({ type: "jobs", payload: { state: "loaded", value: data } });
				})
				.catch((error) => {
					dispatch({ type: "jobs", payload: { state: "failed", error: makeError(error) } });
				});
		},
		[state.searchParams]
	);

	useEffect(() => handleGet(0), [handleGet]);

	const handleClone = useCallback(
		async (jobId: Key) => {
			return await get<GetJobDataResponse<Job>>(`${apiEndpoint.enquete}/${jobId.toString()}`)
				.then(async ({ data }) => {
					const job = {
						...data.enquete,
						...data.job,
						createAccountId: account._id,
						enqueteStatus: "debug",
						title: `${t("jobsPage.clonedPrefix")}${data.job.title ?? ""}${t("jobsPage.clonedSuffix")}`,
					};

					return await post<UpsertJobResponse>(`${apiEndpoint.job}`, job)
						.then(() => {
							dispatch({ type: "targetIndex" });
							handleGet(state.jobs.state === "loaded" ? state.jobs.value.page : 0);
							navigate(endpoint.jobs, { state: { message: t("message.clonedJob") } });
						})
						.catch((error) => {
							console.error(error);
							dispatch({ type: "error", payload: makeError(error) });
						});
				})
				.catch((error) => {
					console.error(error);
					dispatch({ type: "error", payload: makeError(error) });
				});
		},
		[account._id, state.jobs, handleGet]
	);

	const handleSearch = useCallback(
		(payload: SearchParams) => {
			dispatch({ type: "searchParams", payload });

			setSearchParams(searchParams);
		},
		[setSearchParams]
	);

	const handleSelectTarget = useCallback((payload?: number) => dispatch({ type: "targetIndex", payload }), []);

	return (
		<JobsPage
			onClone={handleClone}
			onGet={handleGet}
			onSearch={handleSearch}
			onSelectTarget={handleSelectTarget}
			{...state}
		/>
	);
});
