import { ContentsState, Error, ProgressState } from "@lu/request";

import { Organization } from "../../../../../server/repositories/organization/model";
import { EditorType } from "../../../types/editorTypes";
import { initOrganization } from "../../../utils/initializers";

export type State = {
	error?: Error;
	organization: ContentsState<Partial<Organization>>;
	progressState: ProgressState;
};

export const initState = (type: EditorType): State => ({
	organization: type === "create" ? { state: "loaded", value: initOrganization() } : { state: "loading" },
	progressState: "none",
});
