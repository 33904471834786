import { ContentsState } from "@lu/request";

import {
	GetOrganizationListResponse,
	SearchOrganizationRequest,
} from "../../../../../server/types/request/organization";
import { SearchParams } from "../../../components/parts/SearchField";

export type State = {
	organizations: ContentsState<GetOrganizationListResponse>;
	searchParams: SearchOrganizationRequest;
};

export const initState = (searchParams: SearchParams): State => ({
	organizations: { state: "loading" },
	searchParams,
});
