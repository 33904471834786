import { Action } from "./action";
import { State } from "./state";

export const reducer = (state: State, action: Action): State => {
	switch (action.type) {
		case "account":
			return {
				...state,
				account: action.payload,
			};
		case "error":
			return {
				...state,
				error: action.payload,
				progressState: "done",
			};
		case "progressState":
			return {
				...state,
				progressState: action.payload,
			};
	}
};
