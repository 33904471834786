import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";

import { ErrorPage } from "../components/pages/error";
import { CrossViewerContainer } from "../containers/aggregates/CrossViewer";
import { AggregateJobDetailsContainer } from "../containers/aggregates/Details";
import { AggregateJobEditorContainer } from "../containers/aggregates/Editor";
import { GTViewerContainer } from "../containers/aggregates/GTViewer";
import { AggregatesContainer } from "../containers/aggregates/Root";
import { SyncRawdataContainer } from "../containers/aggregates/SyncRawdata";
import { UploadRawdataContainer } from "../containers/aggregates/UploadRawdata";

import { aggregatesEndpoint } from "./endpoints";

export const Aggregates: FunctionComponent = React.memo(() => {
	const [t] = useTranslation();

	return (
		<Routes>
			<Route path="/" element={<AggregatesContainer />} />
			<Route path={aggregatesEndpoint.create} element={<AggregateJobEditorContainer type="create" />} />
			<Route path=":aggregateJobId" element={<AggregateJobDetailsContainer />} />
			<Route
				path={`:aggregateJobId/${aggregatesEndpoint.edit}`}
				element={<AggregateJobEditorContainer type="edit" />}
			/>
			<Route path={`:aggregateJobId/${aggregatesEndpoint.syncRawdata}`} element={<SyncRawdataContainer />} />
			<Route path={`:aggregateJobId/${aggregatesEndpoint.uploadRawdata}`} element={<UploadRawdataContainer />} />
			<Route path={`:aggregateJobId/${aggregatesEndpoint.cross}`} element={<CrossViewerContainer />} />
			<Route path={`:aggregateJobId/${aggregatesEndpoint.gt}`} element={<GTViewerContainer />} />
			<Route path="*" element={<ErrorPage text={t("errorPage.notFound")} title={t("pageTitle.notFound")} />} />
		</Routes>
	);
});
