import { makeError } from "@bleu/core";
import React, { FunctionComponent, useCallback, useEffect, useMemo, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import { Role } from "../../../../../server/lib/permission/role";
import { apiEndpoint } from "../../../../../server/router/api/endpoint";
import { GetOneAccountResponse } from "../../../../../server/types/request/account";
import { GetJobListResponse, SearchJobRequest } from "../../../../../server/types/request/job";
import { GetOneOrganizationResponse } from "../../../../../server/types/request/organization";
import { AccountDetailsPage } from "../../../components/pages/accounts/Details";
import { useAccount } from "../../../lib/customHooks/useAccount";
import { get, search } from "../../../lib/request";

import { reducer } from "./reducer";
import { initState } from "./state";

export const AccountDetailsContainer: FunctionComponent = React.memo(() => {
	const [t] = useTranslation();

	const account = useAccount();

	const accountId = useParams<"accountId">().accountId ?? account._id.toString();

	const [state, dispatch] = useReducer(reducer, initState);

	const isAdmin = useMemo(() => Role.isAdmin(account), [account]);

	const handleGetJobs = useCallback(
		(target: "create" | "member", page: number) => {
			search<SearchJobRequest, GetJobListResponse>(
				apiEndpoint.searchJob,
				{ page, limit: 10 },
				{ [`${target === "create" ? "createAccountId" : "memberAccountIds"}`]: accountId }
			)
				.then((res) => {
					console.log(target, res.data);
					dispatch({ type: `${target}Jobs` as const, payload: { state: "loaded", value: res.data } });
				})
				.catch((error) => {
					dispatch({ type: `${target}Jobs` as const, payload: { state: "failed", error: makeError(error) } });
				});
		},
		[accountId]
	);

	useEffect(() => {
		handleGetJobs("create", 0);
		handleGetJobs("member", 0);
	}, [handleGetJobs]);

	useEffect(() => {
		if (accountId === undefined) return;

		get<GetOneAccountResponse>(`${apiEndpoint.account}/${accountId}`)
			.then((res) => {
				if (res.data.account != null) {
					dispatch({ type: "account", payload: { state: "loaded", value: res.data.account } });

					if (!isAdmin) return;

					get<GetOneOrganizationResponse>(`${apiEndpoint.organization}/${res.data.account?.organizationId.toString()}`)
						.then((res) => {
							dispatch({ type: "organization", payload: { state: "loaded", value: res.data.organization } });
						})
						.catch((error) => {
							dispatch({ type: "organization", payload: { state: "failed", error: makeError(error) } });
						});
				}
			})
			.catch((error) => {
				dispatch({ type: "account", payload: { state: "failed", error: makeError(error) } });
			});
	}, [accountId, isAdmin]);

	useEffect(() => {
		document.title = `${
			state.account.state !== "loaded" ? t("pageTitle.accountDetails") : state.account.value.name
		} - ${t("pageTitle.siteName")}`;
	}, [state.account]);

	return <AccountDetailsPage accountId={accountId} onGetJobs={handleGetJobs} {...state} />;
});
