import { Action } from "./action";
import { State } from "./state";

export const reducer = (state: State, action: Action): State => {
	switch (action.type) {
		case "initialized":
			return {
				...state,
				initialized: action.payload,
			};
	}
};
