import classNames from "classnames";
import React, { FunctionComponent, ReactNode } from "react";

import { Path, PathsContainer } from "../PathsContainer";

type Props = {
	className?: string;
	paths?: Path[];
	title: ReactNode;
};

export const Header: FunctionComponent<Props> = ({ children, className, paths, title }) => {
	return (
		<header className={classNames("page__header", className)}>
			{!(paths == null) && !(paths.length === 0) && <PathsContainer paths={paths} />}
			<div className="page__header__title">
				{typeof title === "string" ? <h4>{title}</h4> : title}
				{children}
			</div>
		</header>
	);
};
