import { ButtonsBlock } from "@bleu/utility-components";
import React, { FunctionComponent } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

type Props = {
	approveLabel?: string;
	disabled?: boolean;
	show: boolean;
	title?: string;
	onCancel: () => void;
	onOk: () => void;
};

export const ConfirmModal: FunctionComponent<Props> = React.memo(
	({ children, approveLabel, disabled, show, title, onCancel, onOk }) => {
		const [t] = useTranslation();

		return (
			<Modal className="confirm-modal" backdrop="static" centered show={show}>
				<Modal.Header>
					<Modal.Title>{title ?? t("confirmModal.title")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>{children}</Modal.Body>
				<Modal.Footer>
					<ButtonsBlock wide>
						<Button variant="outline-primary" onClick={onCancel}>
							{t("confirmModal.cancel")}
						</Button>
						<Button disabled={disabled} variant="primary" onClick={onOk}>
							{approveLabel ?? t("confirmModal.ok")}
						</Button>
					</ButtonsBlock>
				</Modal.Footer>
			</Modal>
		);
	}
);
