import React, { FunctionComponent, MouseEvent, Ref } from "react";
import { Button } from "react-bootstrap";

type Props = {
	ref?: Ref<HTMLButtonElement>;
	variant?: string;
	onClick: (event: MouseEvent<HTMLButtonElement>) => void;
	onMouseDown?: (event: MouseEvent<HTMLButtonElement>) => void;
};

export const DropdownToggleButton: FunctionComponent<Props> = React.memo(
	React.forwardRef(({ children, variant, onClick, onMouseDown }, ref) => {
		return (
			<Button
				className="dropdown-toggle-button"
				ref={ref}
				variant={variant}
				onClick={onClick}
				onMouseDown={onMouseDown}
			>
				{children}
			</Button>
		);
	})
);
