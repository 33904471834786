import { makeError } from "@bleu/core";
import React, { FunctionComponent, useCallback, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { apiEndpoint } from "../../../../../server/router/api/endpoint";
import {
	GetOrganizationListResponse,
	SearchOrganizationRequest,
} from "../../../../../server/types/request/organization";
import { OrganizationsPage } from "../../../components/pages/Organizations/root";
import { SearchParams } from "../../../components/parts/SearchField";
import { search } from "../../../lib/request";
import { parseString } from "../../../utils/parsers";

import { reducer } from "./reducer";
import { initState } from "./state";

const parseSearchParams = (searchParams: SearchParams): SearchOrganizationRequest => {
	return {
		_id: parseString(searchParams.id),
		code: parseString(searchParams.code),
		name: parseString(searchParams.name),
	};
};

export const OrganizationsContainer: FunctionComponent = React.memo(() => {
	const [t] = useTranslation();

	useEffect(() => {
		document.title = `${t("pageTitle.organizations")} - ${t("pageTitle.siteName")}`;
	}, []);

	const [searchParams, setSearchParams] = useSearchParams("organizations");

	const [state, dispatch] = useReducer(reducer, searchParams, initState);

	const handleGet = useCallback(
		(page: number) => {
			search<SearchOrganizationRequest, GetOrganizationListResponse>(
				apiEndpoint.searchOrganization,
				{ page, limit: 10 },
				parseSearchParams(state.searchParams)
			)
				.then((res) => {
					dispatch({ type: "organizations", payload: { state: "loaded", value: res.data } });
				})
				.catch((error) => {
					dispatch({ type: "organizations", payload: { state: "failed", error: makeError(error) } });
				});
		},
		[state.searchParams]
	);

	useEffect(() => handleGet(0), [handleGet]);

	const handleSearch = useCallback(
		(payload: SearchParams) => {
			dispatch({ type: "searchParams", payload });

			setSearchParams(payload);
		},
		[setSearchParams]
	);

	return <OrganizationsPage onGet={handleGet} onSearch={handleSearch} {...state} />;
});
