import { PagingTable } from "@bleu/utility-components";
import React, { FunctionComponent, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { GetOrganizationListResponse } from "../../../../../../server/types/request/organization";
import { endpoint } from "../../../../routes/endpoints";

export const OrganizationsPageBody: FunctionComponent<{
	organizations: GetOrganizationListResponse;
	onGet: (page: number) => void;
}> = React.memo(({ organizations, onGet }) => {
	const { page, pages, rows } = organizations;

	const [t] = useTranslation();

	const navigate = useNavigate();

	const header = useMemo(
		() => [
			{
				label: t("organization.name"),
				value: "name",
			},
			{
				label: t("organization.code"),
				value: "code",
			},
		],
		[]
	);

	const handeClickItem = useCallback(
		(index: number) => {
			navigate(`${endpoint.organizations}/${rows[index]._id}`);
		},
		[rows]
	);

	return (
		<PagingTable items={rows} header={header} maxPage={pages} page={page} onClickItem={handeClickItem} onGet={onGet}>
			<colgroup className="organizations-page__table__colgroup">
				<col className="organizations-page__table__colgroup__name" span={1} />
				<col className="organizations-page__table__colgroup__code" span={1} />
			</colgroup>
		</PagingTable>
	);
});
