import { GetJobDataResponse, UpsertJobDataRequest, UpsertJobDataResponse, makeError } from "@bleu/core";
import React, { FunctionComponent, useCallback, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";

import { apiEndpoint } from "../../../../../server/router/api/endpoint";
import { GetAccountResponse } from "../../../../../server/types/request/account";
import { UploadFileResponse } from "../../../../../server/types/request/image";
import { JobEditorPage } from "../../../components/pages/jobs/Editor";
import { useAccount } from "../../../lib/customHooks/useAccount";
import { get, post, postFormData, put } from "../../../lib/request";
import { endpoint } from "../../../routes/endpoints";
import { EditorType } from "../../../types/editorTypes";
import { Job } from "../../../types/job";

import { reducer } from "./reducer";
import { initState } from "./state";

type Props = {
	type: EditorType;
};

export const JobEditorContainer: FunctionComponent<Props> = React.memo(({ type }) => {
	const [t] = useTranslation();

	const navigate = useNavigate();

	const { _id } = useAccount();

	const { jobId } = useParams<"jobId">();

	const [state, dispatch] = useReducer(reducer, { accountId: _id, type }, initState);

	useEffect(() => {
		document.title = `${t(`pageTitle.${type}Job`)} - ${t("pageTitle.siteName")}`;
	}, []);

	useEffect(() => {
		get<GetAccountResponse>(apiEndpoint.account)
			.then((res) => {
				dispatch({ type: "accounts", payload: { state: "loaded", value: res.data.accounts } });
			})
			.catch((error) => {
				dispatch({ type: "accounts", payload: { state: "failed", error: makeError(error) } });
			});
	}, []);

	useEffect(() => {
		if (jobId == null) return;

		get<GetJobDataResponse<Job>>(`${apiEndpoint.enquete}/${jobId}`)
			.then(({ data }) => {
				dispatch({ type: "jobData", payload: { state: "loaded", value: data } });
			})
			.catch((error) => {
				dispatch({ type: "jobData", payload: { state: "failed", error: makeError(error) } });
			});
	}, [jobId]);

	const handleClose = useCallback(
		() => navigate(`${endpoint.jobs}/${type === "create" ? "" : jobId ?? ""}`),
		[navigate, jobId]
	);

	const handleSave = useCallback(
		async (request: UpsertJobDataRequest<Job>) => {
			dispatch({ type: "progressState", payload: "progress" });

			// // TODO: Editorの機能に格納
			// request.job.finDate = request.job.finDate == null ? undefined : new Date(request.job.finDate).toISOString();
			// request.job.startDate = request.job.startDate == null ? undefined : new Date(request.job.startDate).toISOString();

			// enqueteStatus
			if (request.enquete.status != null) request.job.enqueteStatus = request.enquete.status;

			return await (type === "create" && request.job._id == null
				? post<UpsertJobDataResponse<Job>>(apiEndpoint.enquete, {
						...request,
						job: {
							...request.job,
							createAccountId: _id,
							memberAccountIds: [_id],
						},
						quotas: [],
				  })
				: put<UpsertJobDataResponse<Job>>(
						`${apiEndpoint.enquete}/${jobId ?? request.job._id?.toString() ?? ""}`,
						request
				  )
			).then(({ data }) => {
				dispatch({ type: "jobData", payload: { state: "loaded", value: data } });
				return data;
			});
		},
		[_id, jobId, type]
	);

	const handleUpdateJob = useCallback((payload: Job) => dispatch({ type: "job", payload }), []);

	const handleUploadImage = useCallback(
		(file: File) => {
			if (state.jobData.state !== "loaded" || state.jobData.value.job.enqueteId == null) return;
			const formData = new FormData();
			formData.append("file", file);

			return postFormData<UploadFileResponse>(
				`${apiEndpoint.images}/${state.jobData.value.job.enqueteId}`,
				formData
			).then((res) => {
				return res.data.url;
			});
		},
		[state.jobData]
	);

	return (
		<JobEditorPage
			jobId={jobId}
			onClose={handleClose}
			onSave={handleSave}
			onUpdate={handleUpdateJob}
			onUploadImage={handleUploadImage}
			{...state}
		/>
	);
});
