import { ButtonsBlock, ContentsStateViewer } from "@bleu/utility-components";
import { ContentsState } from "@lu/request";
import React, { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Role } from "../../../../../../server/lib/permission/role";
import { systemRole } from "../../../../../../server/repositories/account/model";
import { Organization } from "../../../../../../server/repositories/organization/model";
import { GetAccountListResponse, SearchAccountRequest } from "../../../../../../server/types/request/account";
import { useAccount } from "../../../../lib/customHooks/useAccount";
import { accountsEndpoint, endpoint } from "../../../../routes/endpoints";
import { AddButton } from "../../../parts/buttons";
import Page from "../../../parts/Page";
import { ItemProps, SearchField, SearchParams } from "../../../parts/SearchField";

import { AccountsPageBody } from "./Body";

type Props = {
	accounts: ContentsState<GetAccountListResponse>;
	organizations: ContentsState<Organization[]>;
	searchParams: SearchAccountRequest;
	onGet: (page: number) => void;
	onSearch: (searchParams: SearchParams) => void;
};

export const AccountsPage: FunctionComponent<Props> = React.memo(
	({ accounts, organizations, searchParams, onGet, onSearch }) => {
		const [t] = useTranslation();

		const account = useAccount();

		const isAdmin = useMemo(() => Role.isAdmin(account), [account]);

		const organizationOptions = useMemo(() => {
			if (organizations.state !== "loaded") return [];

			return organizations.value.map(({ _id, name }) => ({
				label: name,
				value: _id.toString(),
			}));
		}, [organizations]);

		const roleOptions = useMemo(
			() =>
				[
					isAdmin ? { label: systemRole.admin as string, value: "admin" } : undefined,
					{
						label: systemRole.organizationAdmin as string,
						value: "organizationAdmin",
					},
					{ label: systemRole.general as string, value: "general" },
					{ label: systemRole.viewer as string, value: "viewer" },
				].filter((item): item is { label: string; value: string } => Boolean(item)),
			[isAdmin]
		);

		const statusOptions = useMemo(
			() => [
				{ label: t("accountStatus.active"), value: "active" },
				{ label: t("accountStatus.inactive"), value: "inactive" },
			],
			[]
		);

		const searchItems: ItemProps[] = useMemo(() => {
			const itemProps: Array<ItemProps | undefined> = [
				{ _key: "name", label: t("account.name"), type: "text" },
				{ _key: "email", label: t("account.email"), type: "text" },
				isAdmin
					? {
							_key: "organizationId",
							label: t("account.organization"),
							type: "select",
							options: organizationOptions,
					  }
					: undefined,
				{
					_key: "role",
					label: t("account.role"),
					type: "select",
					multiple: true,
					options: roleOptions,
				},
				{
					_key: "status",
					label: t("account.status"),
					type: "select",
					multiple: true,
					options: statusOptions,
				},
			];

			return itemProps.filter((item): item is ItemProps => Boolean(item));
		}, [organizationOptions, roleOptions]);

		return (
			<Page className="accounts-page">
				<Page.Header title={t("pageTitle.accounts")}>
					<ButtonsBlock align="right" wide>
						<AddButton as={Link} to={`${endpoint.accounts}/${accountsEndpoint.create}`}>
							{t("accountsPage.addAccount")}
						</AddButton>
					</ButtonsBlock>
				</Page.Header>
				<Page.Body>
					<SearchField defaultSearchOptions={searchParams} items={searchItems} onSearch={onSearch} />
					{accounts.state === "loaded" && organizations.state === "loaded" && (
						<AccountsPageBody accounts={accounts.value} organizations={organizations.value} onGet={onGet} />
					)}
					<ContentsStateViewer values={{ accounts }} />
				</Page.Body>
			</Page>
		);
	}
);
