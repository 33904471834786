import { Checkbox, ValidatableSelect } from "@bleu/utility-components";
import classNames from "classnames";
import React, { ChangeEvent, FunctionComponent, useCallback, useMemo } from "react";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";

const parseDateValue = (datetime?: Date): string => {
	if (datetime == null) return "";

	const year = `000${datetime.getFullYear()}`.slice(-4);
	const month = `0${datetime.getMonth() + 1}`.slice(-2);
	const date = `0${datetime.getDate()}`.slice(-2);
	return `${year}-${month}-${date}`;
};

type BaseProps = {
	_key: string;
	className?: string;
	label: string;
};

export type CheckboxProps = BaseProps & {
	options: Array<{ label: string; value: any }>;
	type: "checkbox";
	value?: any[];
	onChange: (key: string, value: any[]) => void;
};

export type DateInputProps = BaseProps & {
	type: "date";
	value?: { since?: Date; until?: Date };
	onChange: (key: string, value: { since?: Date; until?: Date }) => void;
};

export type SelectProps = BaseProps & {
	multiple?: boolean;
	options: Array<{ label: string; value: any }>;
	required?: boolean;
	searchable?: boolean;
	type: "select";
	value?: any | any[];
	onChange: (key: string, value: any | any[]) => void;
};

export type TextInputProps = BaseProps & {
	type: "email" | "text";
	value?: string;
	onChange: (key: string, value: string) => void;
};

export const CheckboxItem: FunctionComponent<CheckboxProps> = React.memo(
	({ _key, className, label, options, value, onChange }) => {
		const set = useMemo(() => new Set(value), [value]);

		const handleChange = useCallback(
			(val, event: ChangeEvent<HTMLInputElement>) => {
				event.target.checked ?? false ? set.add(val) : set.delete(val);
				onChange(_key, Array.from(set.values()));
			},
			[_key, set, onChange]
		);

		return (
			<FormGroup className={classNames("search-field__item", "search-field__item--checkbox", className)}>
				<Form.Label className="search-field__item__label">{label}</Form.Label>
				<div className="search-field__item__control">
					{options.map((option) => (
						<Checkbox
							key={option.value}
							id={`${_key}-${option.value}`}
							checked={set.has(option.value)}
							label={option.label}
							onChange={handleChange.bind(undefined, option.value)}
						/>
					))}
				</div>
			</FormGroup>
		);
	}
);

export const DateInputItem: FunctionComponent<DateInputProps> = React.memo(
	({ _key, className, label, value, type, onChange }) => {
		const { since, until } = useMemo(() => {
			if (value == null) return { since: "", until: "" };
			const { since, until } = value;
			return { since: parseDateValue(since), until: parseDateValue(until) };
		}, [value]);

		const handleChange = useCallback(
			(type, e: ChangeEvent<HTMLInputElement>) =>
				onChange(_key, { ...value, [type]: e.currentTarget.valueAsDate ?? undefined }),
			[_key, value, onChange]
		);
		return (
			<FormGroup className={classNames("search-field__item", "search-field__item--date", className)}>
				<Form.Label className="search-field__item__label">{label}</Form.Label>
				<div className="search-field__item__control">
					<Form.Control type={type} value={since} onChange={handleChange.bind(this, "since")} />
					<span>
						<i className="bi-dash" />
					</span>
					<Form.Control type={type} value={until} onChange={handleChange.bind(this, "until")} />
				</div>
			</FormGroup>
		);
	}
);

export const SelectItem: FunctionComponent<SelectProps> = React.memo(
	({ _key, className, label, multiple = false, options, required = false, value, onChange }) => {
		const handleChange = useCallback((value: unknown) => onChange(_key, value), [_key, onChange]);

		const selectOptions = useMemo(
			() => (required || multiple ? options : [{ label: " ", value: "" }].concat(options)),
			[multiple, options]
		);

		return (
			<FormGroup className={classNames("search-field__item", "search-field__item--select", className)}>
				<Form.Label className="search-field__item__label">{label}</Form.Label>
				<div className="search-field__item__control">
					<ValidatableSelect
						multiple={multiple}
						options={selectOptions}
						placeholder={"\u200b"}
						required={required}
						value={value}
						onChange={handleChange}
					/>
				</div>
			</FormGroup>
		);
	}
);

export const TextInputItem: FunctionComponent<TextInputProps> = React.memo(
	({ _key, className, label, type, value = "", onChange }) => {
		const handleInput = useCallback(
			(event: ChangeEvent<HTMLInputElement>) => {
				onChange(_key, event.target.value);
			},
			[_key, onChange]
		);

		return (
			<FormGroup className={classNames("search-field__item", className)}>
				<Form.Label className="search-field__item__label">{label}</Form.Label>
				<div className="search-field__item__control">
					<Form.Control type={type} value={value} onInput={handleInput} />
				</div>
			</FormGroup>
		);
	}
);
