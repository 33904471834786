import { ContentsState } from "@lu/request";

import { Organization } from "../../../../../server/repositories/organization/model";
import { GetAccountListResponse } from "../../../../../server/types/request/account";

export type State = {
	accounts: ContentsState<GetAccountListResponse>;
	organization: ContentsState<Organization>;
};

export const initState: State = {
	accounts: { state: "loading" },
	organization: { state: "loading" },
};
