import { makeError } from "@bleu/core";
import React, { FunctionComponent, useCallback, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";

import { Role } from "../../../../../server/lib/permission/role";
import { Account } from "../../../../../server/repositories/account/model";
import { apiEndpoint } from "../../../../../server/router/api/endpoint";
import { GetOneAccountResponse, UpsertAccountResponse } from "../../../../../server/types/request/account";
import { GetOrganizationResponse } from "../../../../../server/types/request/organization";
import { AccountEditorPage } from "../../../components/pages/accounts/Editor";
import { useAccount } from "../../../lib/customHooks/useAccount";
import { get, post, put } from "../../../lib/request";
import { endpoint } from "../../../routes/endpoints";
import { EditorType } from "../../../types/editorTypes";

import { reducer } from "./reducer";
import { initState } from "./state";

type Props = {
	accountId?: string;
	type: EditorType;
};

export const AccountEditorContainer: FunctionComponent<Props> = React.memo(({ accountId, type }) => {
	const [t] = useTranslation();

	const _accountId = accountId ?? useParams<"accountId">().accountId;

	const navigate = useNavigate();

	const account = useAccount();

	const [state, dispatch] = useReducer(reducer, { organizationId: account.organizationId, type }, initState);

	useEffect(() => {
		document.title = `${t(`pageTitle.${type}Account`)} - ${t("pageTitle.siteName")}`;
	}, []);

	useEffect(() => {
		if (_accountId === undefined) return;

		get<GetOneAccountResponse>(`${apiEndpoint.account}/${_accountId}`)
			.then((res) => {
				dispatch({ type: "account", payload: { state: "loaded", value: res.data.account } });
			})
			.catch((error) => {
				dispatch({ type: "account", payload: { state: "failed", error: makeError(error) } });
			});
	}, [_accountId]);

	useEffect(() => {
		if (!Role.isAdmin(account)) return dispatch({ type: "organizations", payload: { state: "loaded", value: [] } });

		get<GetOrganizationResponse>(apiEndpoint.organization)
			.then((res) => {
				dispatch({ type: "organizations", payload: { state: "loaded", value: res.data.organizations } });
			})
			.catch((error) => {
				dispatch({ type: "organizations", payload: { state: "failed", error: makeError(error) } });
			});
	}, [account]);

	const handleChangeAccount = useCallback(
		(value: Partial<Account>) => dispatch({ type: "account", payload: { state: "loaded", value } }),
		[]
	);

	const handleSubmit = useCallback(
		(account: Partial<Account>) => {
			dispatch({ type: "progressState", payload: "progress" });

			(type === "create"
				? post<UpsertAccountResponse>(apiEndpoint.account, account)
				: put<UpsertAccountResponse>(`${apiEndpoint.account}/${_accountId}`, account)
			)
				.then((res) => {
					dispatch({ type: "progressState", payload: "done" });
					navigate(`${endpoint.accounts}/${res.data.account._id.toString()}`, {
						state: { message: t(`message.${type}Account`) },
					});
				})
				.catch((error) => {
					dispatch({ type: "error", payload: makeError(error) });
				});
		},
		[_accountId, type]
	);

	return (
		<AccountEditorPage
			accountId={_accountId}
			type={type}
			onChangeAccount={handleChangeAccount}
			onSubmit={handleSubmit}
			{...state}
		/>
	);
});
