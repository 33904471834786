export const apiEndpoint = {
	login: "/api/login",
	logout: "/api/logout",
	authentication: "/api/auth",

	account: "/api/account",
	organization: "/api/organization",
	job: "/api/job",
	enquete: "/api/enquete",
	quota: "/api/quota",

	jobStatus: "/api/job/status",

	searchAccount: "/api/search/account",
	searchJob: "/api/search/job",
	searchOrganization: "/api/search/organization",

	downloadQuestionSheet: "/api/download/questionsheet",
	downloadRawData: "/api/download/rawdata",

	// 集計
	aggregateJob: "/api/aggregate-job",
	aggregateJobs: "/api/aggregate-jobs",
	aggregateQuestion: "/api/aggregate-questions",
	aggregateCrossData: "/api/data/cross",
	aggregateGTData: "/api/data/gt",
	downloadAggregateCross: "/api/download/cross",
	downloadAggregateGT: "/api/download/gt",
	aggregateRawdata: "/api/upload/rawdata",
	aggregateAutoRawdata: "/api/upload/auto-rawdata",

	// material
	images: "/api/upload/images",
} as const;

export type ApiEndpoint = keyof typeof apiEndpoint;
