import React, { ComponentPropsWithRef, FunctionComponent, JSXElementConstructor } from "react";
import Button, { ButtonProps } from "react-bootstrap/Button";

type Props<T extends keyof JSX.IntrinsicElements | JSXElementConstructor<any> = JSXElementConstructor<any>> =
	ButtonProps & {
		as?: T;
	} & ComponentPropsWithRef<T>;

export const AddButton: FunctionComponent<Props> = React.memo(({ children, variant = "primary", ...props }) => {
	return (
		<Button variant={variant} {...props}>
			<i className="bi-plus" />
			{children != null && <span>{children}</span>}
		</Button>
	);
});
