import { AggregateTargetQuestion, convertToMuscatAggregateJob } from "@bleu-analytics/core";
import { AggregateJobEditor } from "@bleu-analytics/editor";
import { ButtonsBlock, ContentsStateViewer, ErrorViewer } from "@bleu/utility-components";
import { Key } from "@lu/repository";
import { ContentsState, Error, ProgressState } from "@lu/request";
import React, { FunctionComponent, useCallback, useMemo } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { Job } from "../../../../../../server/repositories/job/model";
import { aggregatesEndpoint, endpoint, jobsEndpoint } from "../../../../routes/endpoints";
import { EditableAggregateJob, PartialAggregateJob } from "../../../../types/aggregateJob";
import { EditorType } from "../../../../types/editorTypes";
import Page from "../../../parts/Page";

type Props = {
	aggregateJob: ContentsState<EditableAggregateJob>;
	aggregateJobId?: Key;
	error?: Error;
	job: ContentsState<Job>;
	jobId: Key;
	progressState: ProgressState;
	questions: ContentsState<AggregateTargetQuestion[]>;
	type: EditorType;
	onCancel: () => void;
	onSave: (
		aggregateJob: PartialAggregateJob,
		action?: "syncRawdata" | "uploadRawdata" | "viewCross" | "viewGT"
	) => void;
	onUpdate: (aggregateJob: EditableAggregateJob) => void;
};

export const AggregateJobEditorPage: FunctionComponent<Props> = React.memo(
	({ aggregateJob, aggregateJobId, error, job, jobId, progressState, questions, type, onCancel, onSave, onUpdate }) => {
		const [t] = useTranslation();

		const pageTitle = useMemo(
			() => `${t(`pageTitle.${type}Aggregate`)} - ${job.state === "loaded" ? job.value.title : ""}`,
			[job, type]
		);

		const paths = useMemo(
			() =>
				[
					{ label: t("pageTitle.jobs"), to: endpoint.jobs },
					{
						label: (job.state === "loaded" ? job.value.title : undefined) ?? t("pageTitle.jobDetails"),
						to: `${endpoint.jobs}/${jobId}`,
					},
					{ label: t("pageTitle.aggregates"), to: `${endpoint.jobs}/${jobId}/${jobsEndpoint.aggregates}` },
					type === "edit"
						? {
								label: aggregateJob.state === "loaded" ? aggregateJob.value.name : t("pageTitle.aggregateDetails"),
								to: `${endpoint.jobs}/${jobId}/${jobsEndpoint.aggregates}/${aggregateJobId}`,
						  }
						: undefined,
					{
						label: t(`pageTitle.${type}Aggregate`),
						to: `${endpoint.jobs}/${jobId}/${jobsEndpoint.aggregates}/${
							type === "create" ? aggregatesEndpoint.create : `${aggregateJobId}/${aggregatesEndpoint.edit}`
						}`,
					},
				].filter((path) => Boolean(path)),
			[aggregateJob, aggregateJobId, job, jobId, type]
		);

		const handleSave = useCallback(
			() => aggregateJob.state === "loaded" && onSave(convertToMuscatAggregateJob(aggregateJob.value)),
			[aggregateJob, onSave]
		);

		const handleUpdate = useCallback((aggregateJob: any) => onUpdate(aggregateJob), [onUpdate]);

		const handleViewCross = useCallback(
			() => aggregateJob.state === "loaded" && onSave(convertToMuscatAggregateJob(aggregateJob.value), "viewCross"),
			[aggregateJob, onSave]
		);

		const handleViewGT = useCallback(
			() => aggregateJob.state === "loaded" && onSave(convertToMuscatAggregateJob(aggregateJob.value), "viewGT"),
			[aggregateJob, onSave]
		);

		return (
			<Page className="aggregate-editor-page">
				<Page.Header paths={paths} title={pageTitle}>
					<ButtonsBlock align="right" wide>
						<Dropdown>
							<Dropdown.Toggle>
								<i className="bi-eye-fill" />
								<span>{t("aggregateEditorPage.saveAndView")}</span>
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item disabled={progressState === "progress"} onClick={handleViewCross}>
									{t("aggregateEditorPage.cross")}
								</Dropdown.Item>
								<Dropdown.Item disabled={progressState === "progress"} onClick={handleViewGT}>
									{t("aggregateEditorPage.gt")}
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
						<Button disabled={progressState === "progress"} onClick={handleSave}>
							{t("aggregateEditorPage.save")}
						</Button>
					</ButtonsBlock>
				</Page.Header>
				<Page.Body className="aggregate-editor-page__body">
					<ErrorViewer arrayKeys={["grouping", "selectionGroups"]} error={error} />
					{aggregateJob.state === "loaded" && questions.state === "loaded" && (
						<>
							<AggregateJobEditor
								aggregateJob={aggregateJob.value}
								error={error}
								questions={questions.value}
								onSave={onSave}
								onUpdate={handleUpdate}
							/>
							<ButtonsBlock className="aggregate-editor-page__body__buttons" align="center" wide>
								<Button variant="outline-primary" onClick={onCancel}>
									{t("aggregateEditorPage.cancel")}
								</Button>
								<Button disabled={progressState === "progress"} onClick={handleSave}>
									{t("aggregateEditorPage.save")}
								</Button>
							</ButtonsBlock>
						</>
					)}
					<ContentsStateViewer values={{ aggregateJob }} />
				</Page.Body>
			</Page>
		);
	}
);
