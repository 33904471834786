import { PartialAggregateJob, convertToEditableAggregateJob } from "@bleu-analytics/core";
import { makeError } from "@bleu/core";
import React, { FunctionComponent, useCallback, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router";

import { AggregateJobWithConfig } from "../../../../../server/repositories/aggregate-job/model";
import { apiEndpoint } from "../../../../../server/router/api/endpoint";
import {
	GetAggregateQuestion,
	GetAggregateResponse,
	UpsertAggregateResponse,
} from "../../../../../server/types/request/aggregate";
import { GetOneJobResponse } from "../../../../../server/types/request/job";
import { AggregateJobEditorPage } from "../../../components/pages/aggregates/Editor";
import { get, post, put } from "../../../lib/request";
import { aggregatesEndpoint, endpoint, jobsEndpoint } from "../../../routes/endpoints";
import { EditableAggregateJob } from "../../../types/aggregateJob";
import { EditorType } from "../../../types/editorTypes";
import { initAggregateJob } from "../../../utils/initializers";

import { reducer } from "./reducer";
import { initState } from "./state";

type Props = {
	type: EditorType;
};

export const AggregateJobEditorContainer: FunctionComponent<Props> = React.memo(({ type }) => {
	const [t] = useTranslation();

	const location = useLocation();

	const navigate = useNavigate();

	const { aggregateJobId, jobId } = useParams<"jobId" | "aggregateJobId">();

	const [state, dispatch] = useReducer(reducer, initState);

	useEffect(() => {
		document.title = `${t(`pageTitle.${type}Aggregate`)} - ${t("pageTitle.siteName")}`;
	}, []);

	useEffect(() => {
		if (jobId == null) return;

		Promise.all([
			get<GetOneJobResponse>(`${apiEndpoint.job}/${jobId}`)
				.then((res) => {
					dispatch({ type: "job", payload: { state: "loaded", value: res.data.job } });
					return res.data.job;
				})
				.catch((error) => {
					dispatch({ type: "job", payload: { state: "failed", error: makeError(error) } });
					throw error;
				}),
			get<GetAggregateQuestion>(`${apiEndpoint.aggregateQuestion}/${jobId}`)
				.then((res) => {
					dispatch({ type: "questions", payload: { state: "loaded", value: res.data.questions } });
					return res.data.questions;
				})
				.catch((error) => {
					dispatch({ type: "questions", payload: { state: "failed", error: makeError(error) } });
					throw error;
				}),
		])
			.then(([job, questions]) => {
				if (aggregateJobId == null)
					return dispatch({
						type: "aggregateJob",
						payload: { state: "loaded", value: initAggregateJob(job, questions) },
					});

				get<GetAggregateResponse>(`${apiEndpoint.aggregateJob}/${aggregateJobId}`)
					.then((res) => {
						dispatch({
							type: "aggregateJob",
							payload: { state: "loaded", value: convertToEditableAggregateJob(questions, res.data.aggregateJob) },
						});
					})
					.catch((error) => {
						console.error(error);
						dispatch({ type: "aggregateJob", payload: { state: "failed", error: makeError(error) } });
					});
			})

			.catch((error) => {
				console.error(error);
			});
	}, [aggregateJobId, jobId]);

	const handleCancel = useCallback(() => {
		if (jobId == null) return;

		const to =
			location.state != null && typeof location.state === "object" && "prev" in location.state
				? (location.state as { prev: string }).prev
				: `${endpoint.jobs}/${jobId}/${jobsEndpoint.aggregates}`;

		navigate(to, { state: { message: t("message.cancel") } });
	}, [jobId]);

	const handleSave = useCallback(
		(
			aggregateJob: PartialAggregateJob<AggregateJobWithConfig>,
			action?: "syncRawdata" | "uploadRawdata" | "viewCross" | "viewGT"
		) => {
			if (jobId == null) return;
			dispatch({ type: "progressState", payload: "progress" });

			(type === "edit" && aggregateJobId != null
				? put<UpsertAggregateResponse>(`${apiEndpoint.aggregateJob}/${aggregateJobId}`, aggregateJob)
				: post<UpsertAggregateResponse>(`${apiEndpoint.aggregateJob}/${jobId}`, aggregateJob)
			)
				.then((res) => {
					if (state.questions.state === "loaded")
						dispatch({
							type: "aggregateJob",
							payload: {
								state: "loaded",
								value: convertToEditableAggregateJob(state.questions.value, res.data.aggregateJob),
							},
						});

					if (jobId == null) return;

					const to = (() => {
						switch (action) {
							case "syncRawdata":
								return `${endpoint.jobs}/${jobId}/${jobsEndpoint.aggregates}/${res.data.aggregateJob._id.toString()}/${
									aggregatesEndpoint.syncRawdata
								}`;
							case "uploadRawdata":
								return `${endpoint.jobs}/${jobId}/${jobsEndpoint.aggregates}/${res.data.aggregateJob._id.toString()}/${
									aggregatesEndpoint.uploadRawdata
								}`;
							case "viewCross":
								return `${endpoint.jobs}/${jobId}/${jobsEndpoint.aggregates}/${res.data.aggregateJob._id.toString()}/${
									aggregatesEndpoint.cross
								}`;
							case "viewGT":
								return `${endpoint.jobs}/${jobId}/${jobsEndpoint.aggregates}/${res.data.aggregateJob._id.toString()}/${
									aggregatesEndpoint.gt
								}`;
							default:
								return `${endpoint.jobs}/${jobId}/${jobsEndpoint.aggregates}`;
						}
					})();

					navigate(to, {
						state: { message: t(`message.${type}Aggregate`), prev: location.pathname },
					});
				})
				.catch((error) => {
					dispatch({ type: "error", payload: makeError(error) });
				});
		},
		[jobId, type]
	);

	const handleUpdate = useCallback(
		(value: EditableAggregateJob) => dispatch({ type: "aggregateJob", payload: { state: "loaded", value } }),
		[]
	);

	return (
		<AggregateJobEditorPage
			aggregateJobId={aggregateJobId}
			jobId={jobId}
			type={type}
			onCancel={handleCancel}
			onSave={handleSave}
			onUpdate={handleUpdate}
			{...state}
		/>
	);
});
