import { ContentsState, Error, ProgressState } from "@lu/request";

import { AggregateJob } from "../../../../../server/repositories/aggregate-job/model";
import { AggregateTargetQuestion } from "../../../../../server/types/request/aggregate";

export type State = {
	aggregateJob: ContentsState<AggregateJob>;
	error?: Error;
	progressState: ProgressState;
	questions: ContentsState<AggregateTargetQuestion[]>;
};

export const initState: State = {
	aggregateJob: { state: "loading" },
	progressState: "none",
	questions: { state: "loading" },
};
