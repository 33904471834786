import { EnqueteStatus, makeError } from "@bleu/core";
import React, { FunctionComponent, useCallback, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import { apiEndpoint } from "../../../../../server/router/api/endpoint";
import { GetOneJobResponse, UpsertJobStatusResponse } from "../../../../../server/types/request/job";
import { GetQuotaResponse } from "../../../../../server/types/request/quota";
import { JobDetailsPage } from "../../../components/pages/jobs/Details";
import { get, put } from "../../../lib/request";

import { reducer } from "./reducer";
import { initState } from "./state";

export const JobDetailsContainer: FunctionComponent = React.memo(() => {
	const [t] = useTranslation();

	const { jobId } = useParams<"jobId">();

	const [state, dispatch] = useReducer(reducer, initState);

	useEffect(() => {
		if (jobId == null) return;

		// job
		get<GetOneJobResponse>(`${apiEndpoint.job}/${jobId}`)
			.then((res) => {
				dispatch({ type: "job", payload: { state: "loaded", value: res.data.job } });

				if (res.data.job.enqueteId == null) return;

				get<GetQuotaResponse>(`${apiEndpoint.quota}/${res.data.job.enqueteId}`)
					.then((res) => {
						dispatch({ type: "quotas", payload: { state: "loaded", value: res.data.quotas } });
					})
					.catch((error) => {
						dispatch({ type: "quotas", payload: { state: "failed", error: makeError(error) } });
					});
			})
			.catch((error) => {
				dispatch({ type: "job", payload: { state: "failed", error: makeError(error) } });
			});
	}, [jobId]);

	useEffect(() => {
		document.title = `${
			state.job.state !== "loaded" || state.job.value.enqueteTitle == null
				? t("pageTitle.jobDetails")
				: state.job.value.enqueteTitle
		} - ${t("pageTitle.siteName")}`;
	}, [state.job]);

	const handleUpdateStatus = useCallback(
		(status: EnqueteStatus) => {
			put<UpsertJobStatusResponse>(`${apiEndpoint.jobStatus}/${jobId}`, { status })
				.then((res) => {
					dispatch({ type: "job", payload: { state: "loaded", value: res.data.job } });
				})
				.catch((error) => {
					console.error(error);
				});
		},
		[jobId]
	);

	return <JobDetailsPage jobId={jobId} onUpdateStatus={handleUpdateStatus} {...state} />;
});
