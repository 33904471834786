import { PagingTable } from "@bleu/utility-components";
import React, { FunctionComponent, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { GetJobListResponse } from "../../../../../../../server/types/request/job";
import { endpoint } from "../../../../../routes/endpoints";

export const JobsTableBody: FunctionComponent<{
	jobs: GetJobListResponse;
	onGet: (page: number) => void;
}> = React.memo(({ jobs, onGet }) => {
	const { page, pages, rows } = jobs;

	const [t] = useTranslation();

	const navigate = useNavigate();

	const header = useMemo(
		() => [
			{ label: t("job.enqueteTitle"), value: "enqueteTitle" },
			{ label: t("job.createdAt"), value: "createdAt" },
			{ label: t("job.startDate"), value: "startDate" },
			{ label: t("job.finDate"), value: "finDate" },
			{ label: t("enquete.status"), value: "status" },
		],
		[]
	);

	const items = useMemo(
		() =>
			rows.map(({ createAccount, createdAt, findate, startdate, status, ...row }) => ({
				...row,
				createAccount: createAccount.name,
				createdAt: new Date(createdAt).toLocaleString(),
				finDate: findate == null ? undefined : new Date(findate).toLocaleString(),
				startDate: startdate == null ? undefined : new Date(startdate).toLocaleString(),
				status: t(`enqueteStatus.${status}`),
				to: `${endpoint.jobs}/${row._id.toString()}`,
			})),
		[rows]
	);

	const handleClickItem = useCallback(
		(index: number) => {
			navigate(`${endpoint.jobs}/${rows[index]._id}`);
		},
		[rows]
	);

	return (
		<PagingTable header={header} items={items} maxPage={pages} page={page} onClickItem={handleClickItem} onGet={onGet}>
			<colgroup className="account-details-page__jobs-table__colgroup">
				<col className="account-details-page__jobs-table__colgroup__title" span={1} />
				<col className="account-details-page__jobs-table__colgroup__createdAt" span={1} />
				<col className="account-details-page__jobs-table__colgroup__start-date" span={1} />
				<col className="account-details-page__jobs-table__colgroup__fin-date" span={1} />
				<col className="account-details-page__jobs-table__colgroup__status" span={1} />
			</colgroup>
		</PagingTable>
	);
});
