import React, { FunctionComponent } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";

import { endpoint } from "../../../routes/endpoints";
import Page from "../../parts/Page";

export const LogoutPage: FunctionComponent = () => {
	const [t] = useTranslation();
	return (
		<Page className="logout-page">
			<Card className="logout-page__card">
				<Card.Body>
					<div className="text-center">
						<div className="logout-page__card__headline">{t("logoutPage.headline")}</div>
						<div className="logout-page__card__message">{t("logoutPage.message")}</div>
						<Button href={endpoint.login}>{t("logoutPage.toLogin")}</Button>
					</div>
				</Card.Body>
			</Card>
		</Page>
	);
};
