import { ButtonsBlock, TitledBlock, ValidatableInput } from "@bleu/utility-components";
import { ProgressState } from "@lu/request";
import React, { FunctionComponent, useCallback } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { Organization } from "../../../../../../server/repositories/organization/model";
import { endpoint } from "../../../../routes/endpoints";
import { EditorType } from "../../../../types/editorTypes";

import { update } from "./update";

type Props = {
	organization: Partial<Organization>;
	progressState: ProgressState;
	type: EditorType;
	onChange: (organization: Partial<Organization>) => void;
	onSubmit: (organization: Partial<Organization>) => void;
};

export const OrganizationEditorBody: FunctionComponent<Props> = React.memo(
	({ organization, progressState, type, onChange, onSubmit }) => {
		const [t] = useTranslation();

		const navigate = useNavigate();

		const { code, name } = organization;

		const handleCancel = useCallback(() => {
			navigate(type === "create" ? endpoint.organizations : `${endpoint.organizations}/${organization._id}`, {
				state: { message: t("message.cancel") },
			});
		}, [type]);

		const handleInputCode = useCallback(
			(value: string) => {
				onChange(update(organization, { type: "code", payload: value }));
			},
			[organization, onChange]
		);

		const handleInputName = useCallback(
			(value: string) => {
				onChange(update(organization, { type: "name", payload: value }));
			},
			[organization, onChange]
		);

		const handleSubmit = useCallback(() => onSubmit(organization), [organization, onSubmit]);

		return (
			<Form className="organization-editor-page__body">
				<TitledBlock title={t("organization.name")}>
					<ValidatableInput required value={name} onInput={handleInputName} />
				</TitledBlock>
				<TitledBlock title={t("organization.code")}>
					<ValidatableInput required value={code} onInput={handleInputCode} />
				</TitledBlock>
				<ButtonsBlock wide>
					<Button disabled={progressState === "progress"} variant="outline-primary" onClick={handleCancel}>
						{t("organizationEditorPage.cancel")}
					</Button>
					<Button disabled={progressState === "progress"} onClick={handleSubmit}>
						{t(`organizationEditorPage.${type}Submit`)}
					</Button>
				</ButtonsBlock>
			</Form>
		);
	}
);
