import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";

import { ErrorPage } from "../components/pages/error";
import { OrganizationDetailsContainer } from "../containers/organizations/Details";
import { OrganizationEditorContainer } from "../containers/organizations/Editor";
import { OrganizationsContainer } from "../containers/organizations/Root";

import { organizationsEndpoint } from "./endpoints";

export const Organizations: FunctionComponent = () => {
	const [t] = useTranslation();

	return (
		<Routes>
			<Route path="/" element={<OrganizationsContainer />} />
			<Route path={organizationsEndpoint.create} element={<OrganizationEditorContainer type="create" />} />
			<Route path=":organizationId" element={<OrganizationDetailsContainer />} />
			<Route
				path={`:organizationId/${organizationsEndpoint.edit}`}
				element={<OrganizationEditorContainer type="edit" />}
			/>
			<Route path="*" element={<ErrorPage text={t("errorPage.notFound")} title={t("pageTitle.notFound")} />} />
		</Routes>
	);
};
