import { PagingTable } from "@bleu/utility-components";
import React, { FunctionComponent, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { systemRole } from "../../../../../../../server/repositories/account/model";
import { GetAccountListResponse } from "../../../../../../../server/types/request/account";
import { endpoint } from "../../../../../routes/endpoints";

type Props = {
	accounts: GetAccountListResponse;
	onGet: (page: number) => void;
};

export const AccountsTableBody: FunctionComponent<Props> = React.memo(({ accounts, onGet }) => {
	const { page, pages, rows } = accounts;

	const [t] = useTranslation();

	const navigate = useNavigate();

	const header = useMemo(
		() =>
			[
				{ label: t("account.name"), value: "name" },
				{ label: t("account.email"), value: "email" },
				{ label: t("account.role"), value: "role" },
				{ label: t("account.status"), value: "status" },
			].filter((item): item is { label: string; value: string } => Boolean(item)),
		[]
	);

	const items = useMemo(
		() =>
			rows.map(({ _id, inactive = false, role, ..._account }) => ({
				..._account,
				role: systemRole[role],
				status: t(`accountStatus.${inactive ? "inactive" : "active"}`),
				to: `${endpoint.accounts}/${_id}`,
			})),
		[accounts]
	);

	const handleClickItem = useCallback(
		(index: number) => {
			navigate(`${endpoint.accounts}/${rows[index]._id}`);
		},
		[rows]
	);

	return (
		<PagingTable header={header} items={items} maxPage={pages} page={page} onClickItem={handleClickItem} onGet={onGet}>
			<colgroup className="organization-details-page__account-table__colgroup">
				<col className="organization-details-page__account-table__colgroup__name" />
				<col className="organization-details-page__account-table__colgroup__email" />
				<col className="organization-details-page__account-table__colgroup__role" />
				<col className="organization-details-page__account-table__colgroup__status" />
			</colgroup>
		</PagingTable>
	);
});
