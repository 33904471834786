import { Error } from "@lu/request";

import { LoginParams } from "../../components/pages/login";

export type State = LoginParams & {
	error?: Error;
	loading: boolean;
};

export const initState: State = {
	email: "",
	password: "",
	loading: false,
};
