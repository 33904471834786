import React, { FunctionComponent, useEffect, useMemo, useRef } from "react";
import Alert from "react-bootstrap/Alert";
import { useLocation } from "react-router";

export const Main: FunctionComponent = ({ children }) => {
	const { pathname } = useLocation();

	const { state } = useLocation();

	const ref = useRef<HTMLElement>(null);

	useEffect(() => {
		ref.current?.scrollTo(0, 0);
	}, [pathname, ref]);

	const message = useMemo(
		() => (state != null && typeof state === "object" ? (state as { [key in string]: string }).message : undefined),
		[state]
	);

	return (
		<main ref={ref}>
			{children}
			{message != null && (
				<div className="message-alert">
					<Alert variant="primary">{message}</Alert>
				</div>
			)}
		</main>
	);
};
