import { makeError } from "@bleu/core";
import React, { FunctionComponent, useCallback, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router";

import { apiEndpoint } from "../../../../../server/router/api/endpoint";
import { GetAggregateResponse } from "../../../../../server/types/request/aggregate";
import { SyncRawdataPage } from "../../../components/pages/aggregates/SyncRawdata";
import { get, post } from "../../../lib/request";
import { endpoint, jobsEndpoint } from "../../../routes/endpoints";

import { reducer } from "./reducer";
import { initState } from "./state";

export const SyncRawdataContainer: FunctionComponent = React.memo(() => {
	const [t] = useTranslation();

	const location = useLocation();

	const navigate = useNavigate();

	const { aggregateJobId, jobId } = useParams<"jobId" | "aggregateJobId">();

	const [state, dispatch] = useReducer(reducer, initState);

	useEffect(() => {
		document.title = `${t("pageTitle.syncRawdata")} - ${t("pageTitle.siteName")}`;
	}, []);

	useEffect(() => {
		if (aggregateJobId == null) return;
		get<GetAggregateResponse>(`${apiEndpoint.aggregateJob}/${aggregateJobId}`)
			.then((res) => {
				dispatch({ type: "aggregateJob", payload: { state: "loaded", value: res.data.aggregateJob } });
			})
			.catch((error) => {
				dispatch({ type: "aggregateJob", payload: { state: "failed", error: makeError(error) } });
			});
	}, [aggregateJobId]);

	const handleCancel = useCallback(() => {
		const to =
			location.state != null && typeof location.state === "object" && "prev" in location.state
				? (location.state as { prev: string }).prev
				: `${endpoint.jobs}/${jobId}/${jobsEndpoint.aggregates}`;

		navigate(to, { state: { message: t("message.cancel") } });
	}, [jobId]);

	const handleSync = useCallback(() => {
		dispatch({ type: "progressState", payload: "progress" });

		post<{}>(`${apiEndpoint.aggregateAutoRawdata}/${aggregateJobId}`, {})
			.then(() => {
				dispatch({ type: "progressState", payload: "done" });

				const to =
					location.state != null && typeof location.state === "object" && "prev" in location.state
						? (location.state as { prev: string }).prev
						: `${endpoint.jobs}/${jobId}`;

				navigate(to, { state: { message: t("message.syncRawdata") } });
			})
			.catch((error) => {
				dispatch({ type: "error", payload: makeError(error) });
			});
	}, [aggregateJobId]);

	return <SyncRawdataPage onCancel={handleCancel} onSync={handleSync} {...state} />;
});
