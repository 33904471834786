import { ButtonsBlock, Checkbox, TitledBlock, ValidatableInput, ValidatableSelect } from "@bleu/utility-components";
import { ProgressState } from "@lu/request";
import React, { ChangeEvent, FunctionComponent, useCallback, useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { Role } from "../../../../../../server/lib/permission/role";
import { Account, SystemRole, systemRole } from "../../../../../../server/repositories/account/model";
import { Organization } from "../../../../../../server/repositories/organization/model";
import { useAccount } from "../../../../lib/customHooks/useAccount";
import { endpoint } from "../../../../routes/endpoints";
import { EditorType } from "../../../../types/editorTypes";
import { isSystemRole } from "../../../../utils/parsers";

import { update } from "./update";

type Props = {
	account: Partial<Account>;
	organizations: Organization[];
	progressState: ProgressState;
	type: EditorType;
	onChange: (account: Partial<Account>) => void;
	onSubmit: (account: Partial<Account>) => void;
};

export const AccountEditorBody: FunctionComponent<Props> = React.memo(
	({ account, organizations, progressState, type, onChange, onSubmit }) => {
		const [t] = useTranslation();

		const navigate = useNavigate();

		const { _id, email, inactive = false, name, organizationId = "", password, role } = account;

		const loginAccount = useAccount();

		const [passwordConfirm, setPAsswordConfirm] = useState<string>();

		const disabled = useMemo(
			() => type === "create" && (password === "" || password !== passwordConfirm),
			[password, passwordConfirm, type]
		);

		const isAdmin = useMemo(() => Role.isAdmin(loginAccount), [loginAccount]);

		const organizationOptions = useMemo(
			() => organizations.map(({ _id, name }) => ({ label: name, value: _id.toString() })),
			[organizations]
		);

		const roleOptions = useMemo(
			() =>
				[
					Role.isAdmin(loginAccount) ? { label: systemRole.admin as string, value: "admin" } : undefined,
					{ label: systemRole.organizationAdmin as string, value: "organizationAdmin" },
					{ label: systemRole.general as string, value: "general" },
					{ label: systemRole.viewer as string, value: "viewer" },
				].filter((item): item is { label: string; value: string } => Boolean(item)),
			[]
		);

		const handleCancel = useCallback(() => {
			navigate(type === "create" ? endpoint.accounts : `${endpoint.accounts}/${account._id?.toString() ?? ""}`, {
				state: { message: t("message.cancel") },
			});
		}, [type]);

		const handleChangeInactive = useCallback(
			(event: ChangeEvent<HTMLInputElement>) => {
				onChange(update(account, { type: "inactive", payload: event.currentTarget.checked }));
			},
			[account, onChange]
		);
		const handleChangeOrganization = useCallback(
			(payload: unknown) => {
				typeof payload === "string" && onChange(update(account, { type: "organization", payload }));
			},
			[account, onChange]
		);

		const handleChangeRole = useCallback(
			(payload: SystemRole) => onChange(update(account, { type: "role", payload })),
			[account, onChange]
		);

		const handleInputEmail = useCallback(
			(payload: string) => {
				onChange(update(account, { type: "email", payload }));
			},
			[account, onChange]
		);

		const handleInputName = useCallback(
			(payload: string) => {
				onChange(update(account, { type: "name", payload }));
			},
			[account, onChange]
		);

		const handleInputPassword = useCallback(
			(payload: string) => {
				onChange(update(account, { type: "password", payload }));
			},
			[account, onChange]
		);

		const handleSubmit = useCallback(() => onSubmit(account), [account, onSubmit]);

		const passawordConfirmValidationFunc = useCallback(
			(value?: string | number) => {
				if (value != null && value !== "" && value !== password)
					return { valid: false, message: t("validatableInput.passwordNotMatch") };
				return { valid: true, message: "" };
			},
			[password]
		);

		return (
			<Form className="account-editor-page__body">
				<TitledBlock title={t("account.name")}>
					<ValidatableInput required value={name} onInput={handleInputName} />
				</TitledBlock>
				<TitledBlock title={t("account.email")}>
					<ValidatableInput required type="email" value={email} onInput={handleInputEmail} />
				</TitledBlock>
				{isAdmin && (
					<TitledBlock title={t("account.organization")}>
						<ValidatableSelect
							options={organizationOptions}
							required
							value={organizationId.toString()}
							onChange={handleChangeOrganization}
						/>
					</TitledBlock>
				)}
				{type === "create" && (
					<TitledBlock title={t("account.password")}>
						<ValidatableInput
							required={type === "create"}
							type="password"
							value={password}
							onInput={handleInputPassword}
						/>
						<Form.Group>
							<Form.Label>{t("accountEditorPage.passwordConfirm")}</Form.Label>
							<ValidatableInput
								required={type === "create"}
								type="password"
								value={passwordConfirm}
								validationFunc={passawordConfirmValidationFunc}
								onInput={setPAsswordConfirm}
							/>
						</Form.Group>
					</TitledBlock>
				)}
				<TitledBlock title={t("account.role")}>
					<ValidatableSelect
						options={roleOptions}
						required
						value={role}
						isFunc={isSystemRole}
						onChange={handleChangeRole}
					/>
				</TitledBlock>
				<TitledBlock title={t("account.status")}>
					<Checkbox
						id={`account-inactive-${_id?.toString() ?? ""}`}
						label={t("accountEditorPage.inactiveAccount")}
						checked={inactive}
						onChange={handleChangeInactive}
					/>
				</TitledBlock>
				<ButtonsBlock wide>
					<Button disabled={progressState === "progress"} variant="outline-primary" onClick={handleCancel}>
						{t("accountEditorPage.cancel")}
					</Button>
					<Button disabled={disabled || progressState === "progress"} onClick={handleSubmit}>
						{t(`accountEditorPage.${type}Submit`)}
					</Button>
				</ButtonsBlock>
			</Form>
		);
	}
);
