import { PagingTable } from "@bleu/utility-components";
import { Key } from "@lu/repository";
import { Error, ProgressState } from "@lu/request";
import React, { FunctionComponent, useCallback, useMemo } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

import { AccountWithoutPassword } from "../../../../../../../server/repositories/account/model";
import { GetJobListResponse } from "../../../../../../../server/types/request/job";
import { endpoint, jobsEndpoint } from "../../../../../routes/endpoints";
import { DropdownToggle } from "../../../../parts/DropdownToggle";

import { CloneJobModal } from "./CloneJobModal";

type Props = {
	accounts: AccountWithoutPassword[];
	error?: Error;
	jobs: GetJobListResponse;
	progressState: ProgressState;
	targetIndex?: number;
	onClone: (jobId: Key) => void;
	onGet: (page: number) => void;
	onSelectTarget: (index?: number) => void;
};

export const JobsPageBody: FunctionComponent<Props> = React.memo(
	({ accounts, error, jobs, progressState, targetIndex, onClone, onGet, onSelectTarget }) => {
		const { page, pages, rows } = jobs;

		const [t] = useTranslation();

		const navigate = useNavigate();

		const accountMap = useMemo(() => new Map(accounts.map(({ _id, name }) => [_id, name])), [accounts]);

		const header = useMemo(
			() => [
				{ label: t("job.title"), value: "title" },
				{ label: t("job.createAccount"), value: "createAccount" },
				{ label: t("job.createdAt"), value: "createdAt" },
				{ label: t("job.startDate"), value: "startDate" },
				{ label: t("job.finDate"), value: "finDate" },
				{ label: t("enquete.status"), value: "status" },
				{ label: "", value: "dropdown" },
			],
			[]
		);

		const items = useMemo(
			() =>
				rows.map(({ _id, createAccount, createdAt, enqueteStatus, findate, startdate, status, ...row }, i) => {
					const menu = (
						<>
							<Dropdown.Item as={Link} to={`${endpoint.jobs}/${_id}`}>
								{t("jobsPage.open")}
							</Dropdown.Item>
							<Dropdown.Item as={Link} to={`${endpoint.jobs}/${_id}/${jobsEndpoint.edit}`}>
								{t("jobsPage.edit")}
							</Dropdown.Item>
							<Dropdown.Divider />
							<Dropdown.Item onClick={() => onSelectTarget(i)}>{t("jobsPage.cloneJob")}</Dropdown.Item>
						</>
					);

					return {
						...row,
						createAccount: createAccount.name,
						createdAt: new Date(createdAt).toLocaleString(),
						contextMenu: menu,
						dropdown: (
							<Dropdown
								onClick={(event) => {
									event.stopPropagation();
								}}
							>
								<DropdownToggle variant="white">
									<i className="bi-three-dots-vertical" />
								</DropdownToggle>
								<Dropdown.Menu>{menu}</Dropdown.Menu>
							</Dropdown>
						),
						finDate: findate == null ? undefined : new Date(findate).toLocaleString(),
						startDate: startdate == null ? undefined : new Date(startdate).toLocaleString(),
						status: t(`enqueteStatus.${enqueteStatus}`),
					};
				}),
			[accountMap, rows, onSelectTarget]
		);

		const handleClickItem = useCallback(
			(index: number) => {
				navigate(`${endpoint.jobs}/${rows[index]._id}`);
			},
			[rows]
		);

		const handleHideCloneJobModal = useCallback(
			(jobId?: Key) => {
				jobId == null ? onSelectTarget() : onClone(jobId);
			},
			[onClone, onSelectTarget]
		);

		return (
			<>
				<PagingTable
					className="jobs-page__table"
					id="jobs-table"
					header={header}
					items={items}
					maxPage={pages}
					page={page}
					onClickItem={handleClickItem}
					onGet={onGet}
				>
					<colgroup className="jobs-page__table__colgroup">
						<col className="jobs-page__table__colgroup__title" />
						<col className="jobs-page__table__colgroup__account" />
						<col className="jobs-page__table__colgroup__createdAt" />
						<col className="jobs-page__table__colgroup__start-date" />
						<col className="jobs-page__table__colgroup__fin-date" />
						<col className="jobs-page__table__colgroup__status" />
						<col className="jobs-page__table__colgroup__dropdown" />
					</colgroup>
				</PagingTable>
				<CloneJobModal
					error={error}
					job={targetIndex == null ? undefined : rows[targetIndex]}
					progressState={progressState}
					onHide={handleHideCloneJobModal}
				/>
			</>
		);
	}
);
