import { DefaultSchema, Key } from "@lu/repository";

export const systemRole = {
	admin: "システム管理者",
	organizationAdmin: "管理者",
	general: "一般",
	viewer: "閲覧者",
} as const;

export type SystemRole = keyof typeof systemRole;
export const roleKeys = Object.keys(systemRole) as SystemRole[];

export type Account = {
	name: string;
	email: string;
	password: string;
	role: SystemRole;
	organizationId: Key;
	inactive?: boolean;
} & DefaultSchema;

export type AccountWithoutPassword = Omit<Account, "password">;
