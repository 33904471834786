import { getErrorText } from "@bleu/core";
import { ButtonsBlock, ErrorViewer } from "@bleu/utility-components";
import { Error } from "@lu/request";
import React, { FunctionComponent, useCallback, useMemo } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Feedback from "react-bootstrap/Feedback";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useTranslation } from "react-i18next";

import Page from "../../parts/Page";
import { PasswordInput } from "../../parts/PasswordInput";

export type LoginParams = {
	email: string;
	password: string;
};

type Props = LoginParams & {
	error: Error;
	onChange: (name: keyof LoginParams, value: string) => void;
	onSubmit: () => void;
};
export const LoginPage: FunctionComponent<Props> = React.memo(({ email, error, password, onChange, onSubmit }) => {
	const [t] = useTranslation();

	const onChangeEmail = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => onChange("email", event.target.value),
		[onChange]
	);
	const handleChangePassword = useCallback((payload: string) => onChange("password", payload), [onChange]);
	const disabled = useMemo(() => email.length === 0 || password.length === 0, [email, password]);

	const invalid = useMemo(() => error != null, [error]);

	const invalidEmail = useMemo(() => Boolean(getErrorText(error?.errors, ["email"])), [error]);

	const invalidPassword = useMemo(() => Boolean(getErrorText(error?.errors, ["password"])), [error]);

	return (
		<Page className="login-page">
			<Card>
				<Card.Body>
					<ErrorViewer error={error} />
					<Form.Group className="login-page__form-group">
						<Form.Label>{t("loginPage.email")}</Form.Label>
						<InputGroup hasValidation>
							<Form.Control name="email" value={email} onChange={onChangeEmail} isInvalid={invalid} />
							{invalidEmail && <Feedback type="invalid">{invalidEmail}</Feedback>}
						</InputGroup>
					</Form.Group>
					<Form.Group className="login-page__form-group">
						<Form.Label>{t("loginPage.password")}</Form.Label>
						<InputGroup hasValidation>
							<PasswordInput value={password} onInput={handleChangePassword} isInvalid={invalid} />
							{invalidPassword && <Feedback type="invalid">{invalidPassword}</Feedback>}
						</InputGroup>
					</Form.Group>
					<ButtonsBlock block wide>
						<Button variant="primary" disabled={disabled} onClick={onSubmit}>
							{t("loginPage.login")}
						</Button>
					</ButtonsBlock>
				</Card.Body>
			</Card>
		</Page>
	);
});
