import { RawdataSyncer } from "@bleu-analytics/editor";
import { ContentsState, ProgressState } from "@lu/request";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { AggregateJob } from "../../../../../../server/repositories/aggregate-job/model";
import Page from "../../../parts/Page";

type Props = {
	aggregateJob: ContentsState<AggregateJob>;
	progressState: ProgressState;
	onCancel: () => void;
	onSync: () => void;
};

export const SyncRawdataPage: FunctionComponent<Props> = React.memo(({ aggregateJob, ...props }) => {
	const [t] = useTranslation();

	return (
		<Page className="sync-rawdata-page">
			<Page.Header title={t("pageTitle.syncRawdata")} />
			<Page.Body>
				<RawdataSyncer
					variants={{
						controlPrimary: "primary",
						controlSecondary: "outline-primary",
					}}
					{...props}
				/>
			</Page.Body>
		</Page>
	);
});
