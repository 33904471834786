import { Account } from "../../../../../../server/repositories/account/model";

import { UpdateAction } from "./updateAction";

export const update = (account: Partial<Account>, action: UpdateAction): Partial<Account> => {
	switch (action.type) {
		case "password":
			return { ...account, password: action.payload };
	}
};
