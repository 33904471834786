import React, { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useAccount } from "../../../lib/customHooks/useAccount";
import { PasswordContainer } from "../../accounts/Password";

export const MyPasswordContainer: FunctionComponent = React.memo(() => {
	const [t] = useTranslation();

	const { _id } = useAccount();

	useEffect(() => {
		document.title = `${t("pageTitle.editPassword")} - ${t("pageTitle.siteName")}`;
	}, []);

	return <PasswordContainer accountId={_id.toString()} />;
});
