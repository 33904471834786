import i18n from "i18next";
import "react-app-polyfill/ie11";
import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { initReactI18next } from "react-i18next";

import jaJson from "../locales/ja.json";

import { App } from "./routes";

import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-tomorrow";

// i18nextの初期化
void i18n.use(initReactI18next).init({
	resources: {
		ja: { translation: jaJson },
	},
	lng: "ja",
	fallbackLng: "ja",
	interpolation: { escapeValue: false },
});

ReactDOM.render(<App />, document.getElementById("myApp"));
