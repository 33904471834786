import React, { FunctionComponent, MouseEvent, Ref, useCallback } from "react";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { AccountWithoutPassword } from "../../../../../server/repositories/account/model";
import { apiEndpoint } from "../../../../../server/router/api/endpoint";
import { post } from "../../../lib/request";
import { endpoint } from "../../../routes/endpoints";

type Props = {
	account: AccountWithoutPassword;
	variant: string;
};

export const AccountDropdown: FunctionComponent<Props> = React.memo(({ account, variant }) => {
	const [t] = useTranslation();

	const handleLogout = useCallback(() => {
		post(apiEndpoint.logout, {})
			.then(() => {
				location.href = endpoint.logout;
			})
			.catch((error) => {
				console.error(error);
			});
	}, []);

	return (
		<Dropdown className="nav-var__account-dropdown">
			<Dropdown.Toggle variant={variant} as={Toggle}>
				<i className="bi-person-circle" />
				<span>{account.name}</span>
			</Dropdown.Toggle>
			<Dropdown.Menu className="nav-bar__account-dropdown__menu" align="end">
				<Dropdown.Item
					className="nav-bar__account-dropdown__menu__item"
					as={Link}
					to={`${endpoint.accounts}/${account._id}`}
				>
					<div className="nav-bar__account-dropdown__menu__item__name">{account.name}</div>
					<div className="nav-bar__account-dropdown__menu__item__email">{account.email}</div>
				</Dropdown.Item>
				<Dropdown.Divider />
				<Dropdown.Item className="nav-bar__account-dropdown__menu__item" onClick={handleLogout}>
					<i className="bi-box-arrow-right" />
					<span>{t("navBar.logout")}</span>
				</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	);
});

const Toggle: FunctionComponent<{
	ref?: Ref<HTMLButtonElement>;
	variant?: string;
	onClick: (event: MouseEvent<HTMLButtonElement>) => void;
}> = React.memo(
	React.forwardRef(({ children, variant, onClick }, ref) => {
		return (
			<Button className="nav-bar__account-dropdown__button" ref={ref} variant={variant} onClick={onClick}>
				{children}
			</Button>
		);
	})
);
