import React, { FunctionComponent, useContext } from "react";

import { AccountWithoutPassword } from "../../../../server/repositories/account/model";

const AccountContext = React.createContext<AccountWithoutPassword>(null as unknown as AccountWithoutPassword);

export const useAccount = (): AccountWithoutPassword => useContext(AccountContext);

type Props = {
	account: AccountWithoutPassword;
};

export const AccountProvider: FunctionComponent<Props> = React.memo(({ children, account }) => {
	return <AccountContext.Provider value={account}>{children}</AccountContext.Provider>;
});
