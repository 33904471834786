import React, { FunctionComponent } from "react";

import { Tip } from "../Tip";

type Props = {
	items: Array<{ label: string; to: string }>;
};

export const TipsContainer: FunctionComponent<Props> = React.memo(({ items }) => {
	return (
		<div className="tips-container">
			{items.map(({ label, to }) => (
				<Tip key={label} to={to}>
					{label}
				</Tip>
			))}
		</div>
	);
});
