import { ContentsState, Error, ProgressState } from "@lu/request";

import { Job } from "../../../../../server/repositories/job/model";
import { AggregateTargetQuestion } from "../../../../../server/types/request/aggregate";
import { EditableAggregateJob } from "../../../types/aggregateJob";

export type State = {
	aggregateJob: ContentsState<EditableAggregateJob>;
	error?: Error;
	job: ContentsState<Job>;
	progressState: ProgressState;
	questions: ContentsState<AggregateTargetQuestion[]>;
};

export const initState: State = {
	aggregateJob: { state: "loading" },
	job: { state: "loading" },
	progressState: "none",
	questions: { state: "loading" },
};
