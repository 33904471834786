import { ContentsState } from "@lu/request";

import { Organization } from "../../../../../server/repositories/organization/model";
import { GetAccountListResponse, SearchAccountRequest } from "../../../../../server/types/request/account";
import { SearchParams } from "../../../components/parts/SearchField";

export type State = {
	accounts: ContentsState<GetAccountListResponse>;
	organizations: ContentsState<Organization[]>;
	searchParams: SearchAccountRequest;
};

export const initState = (searchParams: SearchParams): State => ({
	accounts: { state: "loading" },
	organizations: { state: "loading" },
	searchParams,
});
