import { Key } from "@lu/repository";

import { Job } from "../types/job";

export const createJob = (accountId: Key): Job => {
	return {
		enqueteStatus: "debug",
		createAccountId: accountId,
		memberAccountIds: [accountId],
	};
};
