import { Key } from "@lu/repository";
import { ContentsState, Error, ProgressState } from "@lu/request";

import { Account } from "../../../../../server/repositories/account/model";
import { Organization } from "../../../../../server/repositories/organization/model";
import { EditorType } from "../../../types/editorTypes";
import { initAccount } from "../../../utils/initializers";

export type State = {
	account: ContentsState<Partial<Account>>;
	error?: Error;
	organizations: ContentsState<Organization[]>;
	progressState: ProgressState;
};

export const initState = ({ organizationId, type }: { organizationId: Key; type: EditorType }): State => ({
	account: type === "create" ? { state: "loaded", value: initAccount(organizationId) } : { state: "loading" },
	organizations: { state: "loading" },
	progressState: "none",
});
