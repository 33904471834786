import { Quota } from "@lu/muscat-library";
import { ContentsState } from "@lu/request";

import { JobWithEnquete } from "../../../../../server/repositories/job/model";

export type State = {
	job: ContentsState<JobWithEnquete>;
	quotas: ContentsState<Quota[]>;
};

export const initState: State = {
	job: { state: "loading" },
	quotas: { state: "loading" },
};
