import { ErrorObject } from "@lu/validator";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import * as json2csv from "json2csv";


const meta = document.querySelector('meta[name="csrf-token"]');
if (meta != null) {
	axios.defaults.headers.common = {
		"X-Requested-With": "XMLHttpRequest",
		"X-CSRF-TOKEN": meta.getAttribute("content"),
	};
}

export const array2CsvBlob = (input: Array<Array<string | number | boolean>>): Blob => {
	const json2csvParser = new json2csv.Parser({ header: false });
	const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
	const blob = new Blob([bom, json2csvParser.parse(input)], {
		type: "text/csv",
	});
	return blob;
};

export const downloadData = (blob: Blob, filename: string): void => {
	const link = document.createElement("a");
	link.href = window.URL.createObjectURL(blob);
	link.setAttribute("download", filename);
	document.body.appendChild(link);
	link.click();
	link.remove();
};

export const getErrorText = (errors: ErrorObject | undefined, name: string): string | undefined => {
	if (errors == null) return undefined;
	if (!(name in errors)) return undefined;
	const err = errors[name];
	if (typeof err === "object") {
		return Object.entries(err)
			.map(([, message]) => (typeof message === "string" ? message : ""))
			.join("\n");
	}
	return err;
};

export const makeQueies = (params: { [key: string]: any }): string => {
	return Object.entries(params)
		.map(([key, value]) => `${key}=${value}`)
		.join("&");
};
export const get = async <T>(
	url: string,
	params?: { [key: string]: any },
	options?: AxiosRequestConfig
): Promise<AxiosResponse<T>> => {
	// IE対応。getだとcacheされるので適当なrandを入れる。
	const queries = "?" + makeQueies({ ...params, c: Math.random() });
	return await axios.get<T>(`${url}${queries}`, options);
};

export const put = async <T>(url: string, data?: unknown, options?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
	return await axios.put<T>(url, data, options);
};

export const remove = async <T>(url: string, options?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
	return await axios.delete<T>(url, options);
};

export const post = async <T>(url: string, data?: unknown, options?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
	return await axios.post<T>(url, data, options);
};

export const postFormData = async <T>(
	url: string,
	data: FormData,
	options?: AxiosRequestConfig
): Promise<AxiosResponse<T>> => {
	const content = meta?.getAttribute("content");
	content != null && data.append("_csrf", content);
	return await axios.post<T>(url, data, {
		...(options ?? {}),
		headers: {
			"content-type": "multipart/form-data",
		},
	});
};

export const search = async <T, S>(
	url: string,
	params?: { [key: string]: any },
	data?: T,
	options?: AxiosRequestConfig
): Promise<AxiosResponse<S>> => {
	const queries = params != null ? "?" + makeQueies(params) : "";
	return await axios.post<S>(`${url}${queries}`, data, options);
};

export const fileDownload = async (url: string, filename: string): Promise<void> => {
	const response = await axios.get(url, { responseType: "blob" });
	downloadData(new Blob([response.data]), filename);
};

const getFileName = (contentDisposition?: string): string | undefined => {
	const matches = contentDisposition?.match(/attachment; filename=(.+\..+)$/);
	if (matches != null) {
		return matches[1];
	}
	return undefined;
};

export const fileDownloadStream = async (url: string, defaultName = "noname"): Promise<void> => {
	const response = await axios.get(url, { responseType: "blob" });
	const blob = new Blob([response.data], {
		type: response.data.type,
	});
	const filename = getFileName(response.headers["content-disposition"]) ?? defaultName;
	downloadData(blob, filename);
};

export const pick = <T>(data: Partial<T>, names: Array<keyof T>): Partial<T> => {
	return names.reduce((a, b) => {
		if (b in data) {
			return { ...a, [b]: data[b] };
		}
		return a;
	}, {}) as Partial<T>;
};
