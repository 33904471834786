import { JobData } from "@bleu/core";
import { Key } from "@lu/repository";
import { ContentsState, ProgressState } from "@lu/request";

import { AccountWithoutPassword } from "../../../../../server/repositories/account/model";
import { EditorType } from "../../../types/editorTypes";
import { Job } from "../../../types/job";
import { createJob } from "../../../utils/job";

export type State = {
	accounts: ContentsState<AccountWithoutPassword[]>;
	jobData: ContentsState<JobData<Job>>;
	progressState: ProgressState;
};

export const initState = ({ accountId, type }: { accountId: Key; type: EditorType }): State => ({
	accounts: { state: "loading" },
	jobData: type === "create" ? { state: "loaded", value: { job: createJob(accountId) } } : { state: "loading" },
	progressState: "none",
});
