import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";

type Props = {
	to: string;
};

export const Tip: FunctionComponent<Props> = React.memo(({ children, to }) => {
	return (
		<Link className="tip" to={to}>
			{children}
		</Link>
	);
});
