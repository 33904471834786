import { EnqueteStatus } from "@bleu/core";
import { ButtonsBlock, TitledBlock } from "@bleu/utility-components";
import { Quota } from "@lu/muscat-library/dist/models/quota";
import React, { FunctionComponent, ReactNode, useCallback, useMemo, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { JobWithEnquete } from "../../../../../../server/repositories/job/model";
import { apiEndpoint } from "../../../../../../server/router/api/endpoint";
import { aggregatesEndpoint, endpoint, jobsEndpoint } from "../../../../routes/endpoints";
import { ConfirmModal } from "../../../parts/ConfirmModal";
import { QuotasTable } from "../../../parts/QuotasTable";
import { Tip } from "../../../parts/Tip";
import { TipsContainer } from "../../../parts/TipsContainer";

type Props = {
	job: JobWithEnquete;
	quotas: Quota[];
	onUpdateStatus: (status: EnqueteStatus) => void;
};

export const JobDetailsBody: FunctionComponent<Props> = React.memo(({ job, quotas, onUpdateStatus }) => {
	const {
		createAccount,
		duplicatedAnswrer = false,
		enqueteStatus,
		enqueteId,
		enqueteTitle,
		finDate,
		memberAccounts,
		startDate,
		surveyType,
		targetAny = false,
	} = job;

	const [t] = useTranslation();

	const [showModal, setShowModal] = useState<EnqueteStatus | undefined>(undefined);

	const memberAccountTipItems = useMemo(
		() =>
			memberAccounts.map((account) => ({
				label: account?.name ?? "...",
				to: `${endpoint.accounts}/${account?._id}`,
			})),
		[memberAccounts]
	);

	const handleClickChangeStatusComplete = useCallback(() => setShowModal("complete"), []);

	const handleClickChangeStatusStopping = useCallback(() => setShowModal("stopping"), []);

	const handleCancelChangeStatus = useCallback(() => setShowModal(undefined), []);

	const handleOkChangeStatus = useCallback(() => {
		if (showModal != null) onUpdateStatus(showModal);
		setShowModal(undefined);
	}, [showModal]);

	return (
		<>
			<div className="job-details-page__body">
				<TitledBlock title={t("job.createAccount")}>
					<Tip to={`${endpoint.accounts}/${createAccount._id}`}>{createAccount.name}</Tip>
				</TitledBlock>
				<TitledBlock title={t("job.memberAccounts")}>
					<TipsContainer items={memberAccountTipItems} />
				</TitledBlock>
				<TitledBlock title={t("jobDetailsPage.enqueteInfo")}>
					<FormGroup label={t("job.enqueteTitle")}>{enqueteTitle}</FormGroup>
					<FormGroup label={t("job.surveyType")}>{surveyType != null && t(`surveyType.${surveyType}`)}</FormGroup>
					<FormGroup label={t("enquete.status")}>
						<ValueWrapper>{t(`enqueteStatus.${enqueteStatus}`)}</ValueWrapper>
						{enqueteStatus !== "debug" && (
							<ButtonsBlock wide>
								<Button disabled={enqueteStatus !== "progress"} onClick={handleClickChangeStatusComplete}>
									{t("jobDetailsPage.changeToComplete")}
								</Button>
								<Button disabled={enqueteStatus !== "progress"} onClick={handleClickChangeStatusStopping}>
									{t("jobDetailsPage.changeToStopping")}
								</Button>
							</ButtonsBlock>
						)}
					</FormGroup>
				</TitledBlock>
				<TitledBlock title={t("jobDetailsPage.deliveryInfo")}>
					<FormGroup label={t("job.startDate")}>
						{startDate == null ? "" : new Date(startDate).toLocaleString()}
					</FormGroup>
					<FormGroup label={t("job.finDate")}>{finDate == null ? "" : new Date(finDate).toLocaleString()}</FormGroup>
					<FormGroup label={t("job.targetAny")}>{t(`jobDetailsPage.${targetAny ? "allow" : "disallow"}`)}</FormGroup>
					<FormGroup label={t("job.duplicatedAnswer")}>
						{t(`jobDetailsPage.${duplicatedAnswrer ? "allow" : "disallow"}`)}
					</FormGroup>
				</TitledBlock>
				<TitledBlock title={t("jobDetailsPage.quotas")}>
					<QuotasTable quotas={quotas} />
				</TitledBlock>
				<TitledBlock title={t("jobDetailsPage.download")}>
					<ButtonsBlock wide>
						<Dropdown>
							<Dropdown.Toggle>
								<i className="bi-download" />
								<span>{t("jobDetailsPage.rawData")}</span>
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item as="a" download href={`${apiEndpoint.downloadRawData}/${enqueteId}?type=code`}>
									{t("jobDetailsPage.rawDataCode")}
								</Dropdown.Item>
								<Dropdown.Item as="a" download href={`${apiEndpoint.downloadRawData}/${enqueteId}?type=decode`}>
									{t("jobDetailsPage.rawDataDecode")}
								</Dropdown.Item>
								<Dropdown.Item as="a" download href={`${apiEndpoint.downloadRawData}/${enqueteId}?type=flag`}>
									{t("jobDetailsPage.rawDataFlag")}
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
						<Button as="a" download href={`${apiEndpoint.downloadQuestionSheet}/${enqueteId}`}>
							<i className="bi-download" />
							<span>{t("jobDetailsPage.questionSheet")}</span>
						</Button>
					</ButtonsBlock>
				</TitledBlock>
				<TitledBlock title={t("jobDetailsPage.aggregates")}>
					<ButtonsBlock wide>
						<Button as={Link as any} to={`${endpoint.jobs}/${job._id}/${jobsEndpoint.aggregates}`}>
							{t("jobDetailsPage.aggregatesList")}
						</Button>
						<Button
							as={Link as any}
							to={`${endpoint.jobs}/${job._id}/${jobsEndpoint.aggregates}/${aggregatesEndpoint.create}`}
						>
							<i className="bi-plus-lg" />
							<span>{t("jobDetailsPage.createAggregate")}</span>
						</Button>
					</ButtonsBlock>
				</TitledBlock>
			</div>
			<ConfirmModal
				approveLabel={t("jobDetailsPage.changeStatusApproveLabel")}
				show={showModal != null}
				onCancel={handleCancelChangeStatus}
				onOk={handleOkChangeStatus}
			>
				{`${t("jobDetailsPage.changeStatusMessagePrefix")}${t(`enqueteStatus.${showModal}`)}${t(
					"jobDetailsPage.changeStatusMessageSuffix"
				)}`}
			</ConfirmModal>
		</>
	);
});

const FormGroup: FunctionComponent<{ label: ReactNode }> = React.memo(({ children, label }) => {
	return (
		<Form.Group className="job-details-page__form-group">
			<Form.Label>{label}</Form.Label>
			<div className="job-details-page__form-group__value">{children}</div>
		</Form.Group>
	);
});

const ValueWrapper: FunctionComponent = React.memo(({ children }) => {
	return <div className="job-details-page__value-wrapper">{children}</div>;
});
