import { mobile } from "../utils/fluid";

import { Action } from "./action";
import { State } from "./state";

export const reducer = (state: State, action: Action): State => {
	switch (action.type) {
		case "account":
			return { ...state, account: action.payload };
		case "drawerOpen":
			return { ...state, drawerOpen: action.payload };
		case "fluid":
			return {
				...state,
				drawerOpen: !mobile(action.payload),
				fluid: action.payload,
			};
	}
};
