import classNames from "classnames";
import React, { FunctionComponent, useCallback, useEffect, useMemo, useReducer } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

import { Role } from "../../../../../server/lib/permission/role";
import { AccountWithoutPassword } from "../../../../../server/repositories/account/model";
import { endpoint } from "../../../routes/endpoints";
import { Fluid } from "../../../types/fluid";
import { mobile } from "../../../utils/fluid";

import { reducer } from "./reducer";
import { initState } from "./state";

type DrawerItem = {
	active?: boolean;
	icon: JSX.Element;
	label: string;
	url: string;
};

type Props = {
	fluid: Fluid;
	loginAccount: AccountWithoutPassword;
	open: boolean;
	onOpen: (open: boolean) => void;
};

export const Drawer: FunctionComponent<Props> = React.memo(({ fluid, loginAccount, open = false, onOpen }) => {
	const [t] = useTranslation();

	const location = useLocation();

	const [state, dispatch] = useReducer(reducer, initState);

	useEffect(() => {
		state.initialized && window.requestAnimationFrame(() => dispatch({ type: "initialized", payload: false }));
	}, [open, state.initialized]);

	const openButtonClickHandler = useCallback(() => onOpen(!open), [open, onOpen]);

	const drawerItems = useMemo(() => {
		const items: DrawerItem[] = [
			{
				active: location.pathname === endpoint.top,
				icon: <i className="bi-house-door-fill" />,
				label: t("drawer.items.home"),
				url: endpoint.jobs,
			},
		];
		if (Role.isAdmin(loginAccount))
			items.push({
				icon: <i className="bi-building" />,
				label: t("drawer.items.organizations"),
				url: endpoint.organizations,
			});

		items.push({
			icon: <i className="bi-people-fill" />,
			label: t("drawer.items.accounts"),
			url: endpoint.accounts,
		});
		return items;
	}, [location.pathname, loginAccount]);

	const isMobile = useMemo(() => mobile(fluid), [fluid]);

	const handleClose = useCallback(() => onOpen(false), [onOpen]);

	return (
		<>
			<div
				className={classNames("drawer-scrim", {
					"drawer-scrim--invisible": !open || !isMobile,
					"drawer-scrim--initialized": state.initialized,
					"drawer-scrim--visible": open && isMobile,
				})}
				onClick={handleClose}
			/>
			<div
				className={classNames("drawer", {
					"drawer--mobile": isMobile,
					"drawer--initialized": state.initialized,
					"drawer--open": open,
				})}
			>
				<Nav className="drawer__nav" as="nav" variant="pills">
					{drawerItems.map((drawerItem) => (
						<DrawerItem
							key={drawerItem.url}
							active={drawerItem.active || drawerItem.url === `/${location.pathname.split("/")[1]}`}
							drawerItem={drawerItem}
							onClick={isMobile ? handleClose : undefined}
							open={open}
						/>
					))}
				</Nav>
				<OpenButton onClick={openButtonClickHandler} />
			</div>
		</>
	);
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
const DrawerItem: FunctionComponent<{
	active: boolean;
	drawerItem: DrawerItem;
	onClick?: () => void;
	open: boolean;
}> = React.memo(({ active, drawerItem, onClick, open = false }) => {
	return (
		<OverlayTrigger
			placement="right"
			overlay={
				<Tooltip id={`tooltip`}>
					<span>{drawerItem.label}</span>
				</Tooltip>
			}
		>
			{({ ref, ...triggerHandler }) => (
				<Nav.Item
					className={classNames("drawer__nav__item", {
						"drawer__nav__item--active": active,
					})}
					ref={ref}
					{...(!open && triggerHandler)}
					onClick={onClick}
				>
					<Nav.Link active={active} as={Link} href={drawerItem.url} to={drawerItem.url}>
						{drawerItem.icon}
						<span className="drawer__nav__item__label">{drawerItem.label}</span>
					</Nav.Link>
				</Nav.Item>
			)}
		</OverlayTrigger>
	);
});

const OpenButton: FunctionComponent<{ onClick: () => void }> = React.memo(({ onClick }) => {
	return (
		<Button className="drawer__open-button" onClick={onClick}>
			<i className={classNames("drawer__open-button__icon", "bi-chevron-double-right")} />
		</Button>
	);
});
