import { Action } from "./action";
import { State } from "./state";

export const reducer = (state: State, action: Action): State => {
	switch (action.type) {
		case "account":
			return {
				...state,
				account: action.payload,
			};
		case "createJobs":
			return {
				...state,
				createJobs: action.payload,
			};
		case "memberJobs":
			return {
				...state,
				memberJobs: action.payload,
			};
		case "organization":
			return {
				...state,
				organization: action.payload,
			};
	}
};
